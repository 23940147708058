import React, { useState } from "react";
import { Container, Button, CustomInput } from "reactstrap";
import IndicadoresMonitoreo from "../../components/customer/potreros/monitoreoPotreros/IndicadoresMonitoreo";
import Movimientos from "../../components/customer/potreros/monitoreoPotreros/Movimientos";
import EstadosSituacion from "../../components/customer/potreros/monitoreoPotreros/EstadosSituacion";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import InformationModal from "../../components/InformationModal";
import { useEstablecimientos } from "../../providers/establecimientosProvider";
import InformacionPotreroModal from "../../components/customer/potreros/monitoreoPotreros/InformacionPotreroModal";
import {
  initialState,
  useInformacionPotreroModal,
} from "../../providers/informacionPotreroModalProvider";
import AlimentosExtra from "../../components/customer/potreros/monitoreoPotreros/AlimentosExtra";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../providers/authProvider";
import { Redirect } from "react-router-dom";

const INFORMACION_POTREROS = "1";
const ESTADOS_SITUACION = "2";
const MOVIMIENTOS = "3";
const ALIMENTOS_EXTRA = "4";

const ESTADO_ABIERTO = "1";

const MonitoreoPotreros = () => {
  const [authContext] = useAuth();
  const [establecimientosContext] = useEstablecimientos();
  const [informacionPotreroModalContext, setInformacionPotreroModalContext] =
    useInformacionPotreroModal();

  const [viewMode, setViewMode] = useState(INFORMACION_POTREROS);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onInformacionModal = () => {
    if (!establecimientosContext.selected) {
      return setInformationModal({
        isOpen: true,
        title: "Agregar Información de Potreros",
        body: `
        <p>No hay ningún establecimiento seleccionado.</p>
        <span>Selecciona un establecimiento desde la barra lateral para administrarlo.</span>
      `,
      });
    }
    const selected = establecimientosContext.allEstablecimientos.find(
      (e) => e.id === establecimientosContext.selected
    );
    if (selected.estado !== ESTADO_ABIERTO) {
      return setInformationModal({
        isOpen: true,
        title: "Importar Potreros",
        body: `
        <p>El establecimiento esta en estado cerrado, por lo que no se puede agregar información.</p>
        <span>Edita el estado del establecimiento desde la sección de establecimientos para realizar cambios.</span>
      `,
      });
    }
    setInformacionPotreroModalContext({ ...initialState, isOpen: true });
  };

  const selectedEstablecimiento = () =>
    (establecimientosContext.allEstablecimientos || []).find(
      (e) => e.id === establecimientosContext.selected
    )?.nombre || "";

  const getViewMode = () => {
    switch (viewMode) {
      case INFORMACION_POTREROS:
        return "Estadísticas";
      case ESTADOS_SITUACION:
        return "Registro de situación del potrero";
      case MOVIMIENTOS:
        return "Registro de movimientos de animales";
      case ALIMENTOS_EXTRA:
        return "Registro de alimentos extra";
      default:
        return "";
    }
  };

  const getViewModeComponent = () => {
    switch (viewMode) {
      case INFORMACION_POTREROS:
        return <IndicadoresMonitoreo />;
      case ESTADOS_SITUACION:
        return <EstadosSituacion />;
      case MOVIMIENTOS:
        return <Movimientos />;
      case ALIMENTOS_EXTRA:
        return <AlimentosExtra />;
      default:
        return null;
    }
  };

  return authContext.currentUser?.enabledMonitoreo ? (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Header className="mb-3">
        <HeaderTitle className="d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-column col-12 px-0 col-lg-6 align-items-start">
            <span className="col-12 px-0 text-dark">
              Monitoreo de Potreros {selectedEstablecimiento()}
            </span>
            <small className="col-12 px-0 text-muted font-size-75">
              {getViewMode()}
            </small>
          </div>
          <div className="d-flex justify-content-end mt-2 mt-lg-0 col-12 col-lg-6 px-0 align-items-center">
            <Button
              type="button"
              size="sm"
              className="view-selector btn-light font-weight-bold text-primary p-0 border-0"
            >
              <CustomInput
                size="sm"
                required={true}
                id="viewTypeSelect"
                className="cursor-pointer text-truncate viewTypeSelect"
                type="select"
                name="viewTypeSelect"
                onChange={(event) => setViewMode(event.currentTarget.value)}
                value={viewMode}
              >
                <option value={INFORMACION_POTREROS}>Estadísticas</option>
                <option value={ESTADOS_SITUACION}>
                  Registro de situación del potrero
                </option>
                <option value={MOVIMIENTOS}>
                  Registro de movimientos de animales
                </option>
                <option value={ALIMENTOS_EXTRA}>Alimentos Extra</option>
              </CustomInput>
            </Button>
            <Button
              size="sm"
              color="warning"
              className="rounded ml-2 btn-success font-weight-bold text-white text-uppercase py-1"
              onClick={onInformacionModal}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Ingresar Monitoreo
            </Button>
          </div>
        </HeaderTitle>
      </Header>
      <>{getViewModeComponent()}</>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {informacionPotreroModalContext.isOpen ? (
        <InformacionPotreroModal
          onClose={() => setInformacionPotreroModalContext(initialState)}
        />
      ) : null}
    </Container>
  ) : (
    <Redirect to={`/error/404`} />
  );
};

export default MonitoreoPotreros;
