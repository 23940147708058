import React, { useEffect, useState } from "react";

import { Card, CardBody } from "reactstrap";

import { commonApi } from "../../services/commonServices";
import TCMensualTotalChart from "../../components/customer/seguimientoForrajero/TCMensualTotalChart";
import InformationModal from "../../components/InformationModal";
import IndicadoresCards from "../../components/customer/seguimientoForrajero/IndicadoresCards";
import SeguimientoForrajeroFilters from "./SeguimientoForrajeroFilters";

const ZONA_GEO = "ZONA_GEO";

const SeguimientoForrajeroPublic = () => {
  const [loading, setLoading] = useState();

  const [filtrosMode, setFiltrosMode] = useState(ZONA_GEO);

  const [indicadores, setIndicadores] = useState([]);
  const [tipoUsoSuelosId, setTipoUsoSueloId] = useState([]);
  const [departamentosId, setDepartamentosId] = useState([]);
  const [seccionesPolicialesId, setSeccionesPolicialesId] = useState([]);
  const [zonasId, setZonasId] = useState([]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    setLoading(true);
    commonApi
      .getIndicadoresSeguimientoForrajero({
        seccionPolicialId: seccionesPolicialesId.toString(),
        departamentoId: departamentosId.toString(),
        tipoUsoSueloId: tipoUsoSuelosId.toString(),
        zonaId: zonasId.toString(),
      })
      .then((result) => {
        setIndicadores(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [zonasId, tipoUsoSuelosId, departamentosId, seccionesPolicialesId]);

  const onApplyFilters = (data) => {
    setTipoUsoSueloId(data.localTipoUsoSuelosId);
    setDepartamentosId(data.localDepartamentosId);
    setSeccionesPolicialesId(data.localSeccionesPolicialesId);
    setZonasId(data.localZonasId);
    setFiltrosMode(data.localFiltrosMode);
  };

  return (
    <div className="d-flex">
      <div className="d-flex flex-column col-9 px-0">
        <h3 className="text-center mb-3">Visor de Seguimiento Forrajero</h3>
        <IndicadoresCards indicadores={indicadores} loading={loading} />
        <Card className="border">
          <CardBody>
            <TCMensualTotalChart
              isPublic={true}
              zonas={zonasId}
              tipoUsoSuelos={tipoUsoSuelosId}
              departamentos={departamentosId}
              seccionesPoliciales={seccionesPolicialesId}
            />
          </CardBody>
        </Card>
        {informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            rawBody={informationModal.rawBody}
            onClose={() =>
              setInformationModal({ isOpen: false, title: "", body: "" })
            }
          />
        ) : null}
      </div>
      <SeguimientoForrajeroFilters
        tipoUsoSuelosId={tipoUsoSuelosId}
        departamentosId={departamentosId}
        seccionesPolicialesId={seccionesPolicialesId}
        zonasId={zonasId}
        filtrosMode={filtrosMode}
        onApply={onApplyFilters}
      />
    </div>
  );
};

export default SeguimientoForrajeroPublic;
