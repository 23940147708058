import React, { useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  InputGroup,
  Col,
  Row,
} from "reactstrap";

import Loader from "../../../../components/Loader";
import PotrerosCard from "./PotreroCard";

import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import Pagination from "react-js-pagination";
import { RefreshCw } from "react-feather";
import { useMonitoreoPotreros } from "../../../../providers/monitoreoPotrerosProvider";
import IndicadoresUsoPotreroModal from "./IndicadoresUsoPotreroModal";

const PoterosCardsView = ({ indicadoresPotreros, loading }) => {
  const [establecimientosContext] = useEstablecimientos();
  const [monitoreoPotrerosContext, setMonitoreoPotrerosContext] =
    useMonitoreoPotreros();
  const [indicadoresUsoPotreroModal, setIndicadoresUsoPotreroModal] =
    useState();

  return (
    <div className="d-md-flex d-lg-none">
      {!establecimientosContext.selected ? (
        <Card className="col-12">
          <CardHeader>
            <CardTitle tag="h5" className="text-center my-1">
              Ningún establecimiento seleccionado
            </CardTitle>
          </CardHeader>
        </Card>
      ) : (
        <Col className="px-0">
          <Card className="col-12 px-0 row">
            <CardBody className="py-2">
              <div className="card-actions d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3 col-4 px-0" size="m">
                  <Input
                    maxLength="50"
                    value={monitoreoPotrerosContext.search}
                    onChange={(evt) =>
                      setMonitoreoPotrerosContext({
                        search: evt.target.value,
                        page: 1,
                      })
                    }
                    className="border-0"
                    placeholder="Buscar.."
                  />
                </InputGroup>
                <span className="mr-1 d-flex">
                  <span className="cursor-pointer mx-2 d-flex align-items-center">
                    <RefreshCw
                      hidden
                      onClick={() =>
                        setMonitoreoPotrerosContext({
                          refresh: !establecimientosContext.refresh,
                        })
                      }
                    />
                  </span>
                  <small className="text-truncate text-muted d-flex align-items-center">
                    Mostrando {indicadoresPotreros?.count || 0} potreros
                  </small>
                </span>
              </div>
            </CardBody>
          </Card>
          {loading ? (
            <Loader />
          ) : !indicadoresPotreros.count ? (
            <Card className="col-12">
              <CardHeader>
                <CardTitle tag="h5" className="text-center my-1">
                  No hay resultados
                </CardTitle>
              </CardHeader>
            </Card>
          ) : (
            <Col className="flex-wrap px-0">
              <Row>
                {(indicadoresPotreros.data || []).map(
                  (indicadorPotrero, index) => (
                    <PotrerosCard
                      key={index}
                      indicadorPotrero={indicadorPotrero}
                      onIndicadoresUso={(id) =>
                        setIndicadoresUsoPotreroModal(id)
                      }
                    ></PotrerosCard>
                  )
                )}
              </Row>
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={monitoreoPotrerosContext.page}
                itemsCountPerPage={monitoreoPotrerosContext.sizePerPage}
                totalItemsCount={indicadoresPotreros.count || 0}
                pageRangeDisplayed={5}
                onChange={(page) => setMonitoreoPotrerosContext({ page })}
              />
            </Col>
          )}
        </Col>
      )}
      {indicadoresUsoPotreroModal ? (
        <IndicadoresUsoPotreroModal
          potreroId={indicadoresUsoPotreroModal}
          onClose={() => setIndicadoresUsoPotreroModal()}
        />
      ) : null}
    </div>
  );
};

export default PoterosCardsView;
