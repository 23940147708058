import moment from "moment";
moment.locale("es-es");

const TIPO_SALIDA_ENTRADA = 1;
const TIPO_SALIDA = 2;

const ENV_PRODUCTION = "production";

const meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Setiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const utils = {
  meses,
  isProd: () => process.env.REACT_APP_ENV !== ENV_PRODUCTION,
  formatDecimal: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("es-ES", { maximumFractionDigits }).format(number),
  formatPercent: (progress, total) =>
    `${total ? ((progress / total) * 100).toFixed(2) : 0}%`,
  formatDate: (date, format = "YYYY-MM-DD") =>
    (date ? moment(date) : moment()).format(format),
  nameInitials: (name) => {
    if (!name) {
      return "";
    }
    if (!/(.+)( )+(.+)/.test(name)) {
      return `${name[0]}${name[1] || ""}`;
    }
    const [, , , lastname] = name.match(/(.+)( )+(.+)/);
    return `${name[0]}${lastname[0]}`;
  },
  ordinalSuffix: (number) => {
    const last = number.toString().slice(-1);
    let ord = "";
    switch (last) {
      case "1":
        ord = "ra";
        break;
      case "2":
        ord = "da";
        break;
      case "3":
        ord = "ra";
        break;
      case "4":
        ord = "ta";
        break;
      case "5":
        ord = "ta";
        break;
      case "6":
        ord = "ta";
        break;
      case "7":
        ord = "ma";
        break;
      case "8":
        ord = "va";
        break;
      case "9":
        ord = "na";
        break;
      default:
        ord = "ma";
        break;
    }
    return `${number.toString()}${ord}`;
  },
  tipoMovimientoPotrero: (tipo) => {
    switch (parseInt(tipo)) {
      case TIPO_SALIDA_ENTRADA:
        return "Salida de potrero y entrada a potrero nuevo";
      case TIPO_SALIDA:
        return "Salida de potrero y venta";
      default:
        return "Compra y entrada a potrero";
    }
  },
  sanitizeString: (text) =>
    text
      .trim()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(",", "")
      .replace(")", "")
      .replace("(", ""),
  capitalizeFirstLetter: (string) =>
    `${string.charAt(0).toUpperCase()}${string.slice(1)}`,
};
