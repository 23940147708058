import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sizePerPage: 10,
  page: 1,
  search: "",
  sortBy: "nombre",
  direction: "asc",
  usuarios: {},
};

const UsuariosContext = createContext(initialState);

const UsuariosProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <UsuariosContext.Provider value={stateAndDispatch}>
      {children}
    </UsuariosContext.Provider>
  );
};

export const useUsuarios = () => useContext(UsuariosContext);

export { UsuariosContext, UsuariosProvider };
