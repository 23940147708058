import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sizePerPage: 5,
  resuelto: true,
  page: 1,
  search: "",
  sortBy: "createdAt",
  direction: "asc",
  notificaciones: {},
};

const NotificacionesContext = createContext(initialState);

const NotificacionesProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <NotificacionesContext.Provider value={stateAndDispatch}>
      {children}
    </NotificacionesContext.Provider>
  );
};

export const useNotificaciones = () => useContext(NotificacionesContext);

export { NotificacionesContext, NotificacionesProvider };
