import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  Input,
  Button,
  CustomInput,
  InputGroupText,
} from "reactstrap";
import { useNotificaciones } from "../../providers/notificacionesProvider";
import { notificacionesApi } from "../../services/notificacionesServices";
import Pagination from "react-js-pagination";
import { RefreshCw } from "react-feather";
import Loader from "../../components/Loader";
import {
  faCheckCircle,
  faExclamationTriangle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Notificaciones = () => {
  const [notificacionesContext, setNotificacionesContext] = useNotificaciones();
  const [notificacionesLoading, setNotificacionesLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const setNotificacionesContextCB = useCallback(
    (data) => {
      setNotificacionesContext(data);
    },
    [setNotificacionesContext]
  );

  useEffect(() => {
    setLoading(true);
    notificacionesApi
      .getNotificaciones({
        search: notificacionesContext.search,
        noResuelto: notificacionesContext.noResuelto,
        page: notificacionesContext.page - 1,
        pageSize: notificacionesContext.sizePerPage,
      })
      .then((result) => {
        setNotificacionesContextCB({ notificaciones: result });
        setLoading(false);
      });
  }, [
    notificacionesContext.noResuelto,
    notificacionesContext.search,
    notificacionesContext.refresh,
    notificacionesContext.page,
    notificacionesContext.sizePerPage,
    setNotificacionesContextCB,
  ]);

  const onResolver = (notificacion, resuelto) => {
    setNotificacionesLoading({ ...loading, [notificacion.id]: true });
    notificacionesApi
      .updateNotificacion({ id: notificacion.id, resuelto })
      .then(() => {
        notificacion.resuelto = resuelto;
        setNotificacionesLoading({ ...loading, [notificacion.id]: false });
        setNotificacionesContext({ refresh: !notificacionesContext.refresh });
      });
  };

  return (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-start flex-column">
            <div className="d-flex align-items-center">
              <h3 className="mb-0 ">Notificaciones</h3>
              <small className="text-muted ml-2 pt-1">
                ({notificacionesContext.notificaciones?.count})
              </small>
            </div>
            <small className="text-warning font-weight-bold">
              Las notificaciones se publican una vez se cierre el
              establecimiento.
            </small>
          </div>
          <div className="card-actions d-flex justify-content-between">
            <InputGroup size="m" className="mr-3">
              <Input
                className="rounded-left rounded-right-0"
                maxLength="50"
                placeholder="Buscar.."
                value={notificacionesContext.search}
                onChange={(evt) =>
                  setNotificacionesContext({
                    search: evt.target.value,
                    page: 1,
                  })
                }
              />
              <InputGroupText className="rounded-right rounded-left-0 search-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <Button
              type="button"
              size="sm"
              className="rounded view-selector btn-light font-weight-bold text-primary flex-shrink-0 border mr-3"
            >
              <CustomInput
                defaultChecked={notificacionesContext.noResuelto}
                onClick={(event) =>
                  setNotificacionesContext({
                    noResuelto: event.currentTarget.checked,
                  })
                }
                type="switch"
                id="viewModeHome"
                name="viewModeHome"
                label={"Solo sin resolver"}
                className="cursor-pointer d-flex align-items-center font-weight-bold text-primary"
              />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh"
              color="primary"
              onClick={() =>
                setNotificacionesContext({
                  refresh: !notificacionesContext.refresh,
                })
              }
            >
              <RefreshCw />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          {loading ? (
            <Loader size="sm" />
          ) : !notificacionesContext.notificaciones.count ? (
            <div tag="h5" className="text-center my-1">
              No hay notificaciones
            </div>
          ) : (
            <Col className="flex-wrap">
              <Row className="d-flex justify-content-center align-items-center col-10 offset-1">
                {(notificacionesContext.notificaciones.data || []).map(
                  (notificacion, index) => (
                    <div
                      className={`col-12 p-4 border my-2 ${
                        notificacion.resuelto
                          ? "border-primary text-primary"
                          : "text-warning border-warning"
                      } rounded d-flex justify-content-between align-items-center`}
                      key={index}
                    >
                      <h2 className="mb-0 col-1">
                        <FontAwesomeIcon
                          icon={
                            !notificacion.resuelto
                              ? faExclamationTriangle
                              : faCheckCircle
                          }
                          className={`${
                            notificacion.resuelto
                              ? "text-primary"
                              : "text-warning"
                          } align-middle`}
                        />
                      </h2>
                      <div
                        className="col-9"
                        dangerouslySetInnerHTML={{
                          __html: notificacion.mensaje,
                        }}
                      ></div>
                      <h4 className="mb-0 col-2">
                        {notificacionesLoading[notificacion.id] ? (
                          <Loader size="sm" />
                        ) : notificacion.resuelto ? (
                          <Button
                            onClick={() => onResolver(notificacion, false)}
                            color="success"
                            size="sm"
                            className="border border-success rounded"
                          >
                            Marcar como no resuelto
                          </Button>
                        ) : (
                          <Button
                            onClick={() => onResolver(notificacion, true)}
                            color="warning"
                            size="sm"
                            className="border border-warning rounded"
                          >
                            Marcar como resuelto
                          </Button>
                        )}
                      </h4>
                    </div>
                  )
                )}
              </Row>
              <div className="mt-4">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={notificacionesContext.page}
                  itemsCountPerPage={notificacionesContext.sizePerPage}
                  totalItemsCount={
                    notificacionesContext.notificaciones?.count || 0
                  }
                  pageRangeDisplayed={5}
                  onChange={(page) => setNotificacionesContext({ page })}
                />
              </div>
            </Col>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default Notificaciones;
