import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Label,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  FormText,
  CustomInput,
} from "reactstrap";
import Loader from "../Loader";
import InformationModal from "../InformationModal";
import { importacionesApi } from "../../services/importacionesServices";

const extensionRegExp = /(.+)\.csv/;

const CreateImportacionModal = ({ onSubmit, onClose }) => {
  const [loading, setLoading] = useState();
  const [files, setFiles] = useState("");
  const [force, setForce] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onCreate = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await Promise.all(
        files.map((file) =>
          importacionesApi.createImportacion({
            file,
            force,
            filename: file.name,
          })
        )
      );
      setLoading(false);
      onSubmit(force, files.length === 1);
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Error",
        rawBody: true,
        body: `<p>${err.response?.data?.error}</p><span>Importación abortada.</span>`,
      });
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal size="md" isOpen={true} onClosed={onClose}>
      <ModalHeader>Nueva Importación</ModalHeader>
      <Form onSubmit={onCreate}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <Col>
              <FormGroup row>
                <Label sm={4} className="text-left">
                  Archivos CSV
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    type="file"
                    multiple={false}
                    accept=".csv"
                    className="mb-1 text-truncate"
                    onChange={(event) => {
                      const files = [...event.currentTarget.files];
                      if (
                        files.find((file) => !extensionRegExp.test(file.name))
                      ) {
                        return setInformationModal({
                          isOpen: true,
                          title: "Error",
                          body: "Extensión de archivo inválida para uno o mas archivos, debe ser CSV.",
                          onClose: () =>
                            setInformationModal({ isOpen: false, body: "" }),
                        });
                      }
                      setFiles(files);
                    }}
                  />
                  <FormText className="text-muted text-left">
                    Selecciona un archivo
                  </FormText>
                </Col>
              </FormGroup>
              <FormGroup row className="mb-0">
                <Col sm={12}>
                  <CustomInput
                    type="checkbox"
                    id={`execute-checkbox`}
                    label="Ejecutar inmediatamente"
                    checked={force}
                    onChange={(event) => setForce(event.currentTarget.checked)}
                    className="cursor-pointer text-left"
                  />
                  <FormText className="text-muted text-left">
                    El servidor puede tardar en responder mientras se ejecuta la
                    importación y puede afectar la usabilidad del sitio.
                  </FormText>
                </Col>
              </FormGroup>
            </Col>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cerrar
              </Button>
              <Button type="submit" color={"success"}>
                Importar
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateImportacionModal;
