import React, { useEffect, useState } from "react";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MoreVertical, RefreshCw } from "react-feather";
import Loader from "../../../Loader";
import { establecimientosApi } from "../../../../services/establecimientosServices";

import InformationModal from "../../../InformationModal";
import { useAlimentosExtra } from "../../../../providers/alimentosExtraProvider";
import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import ConfirmationModal from "../../../ConfirmationModal";
import { utils } from "../../../../utils/utils";

const AlimentosExtra = ({ loading: defaultLoading }) => {
  const [establecimientosContext] = useEstablecimientos();
  const [alimentosExtraContext, setAlimentosExtraContext] = useAlimentosExtra();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [alimentosExtra, setAlimentosExtra] = useState({});
  const [loading, setLoading] = useState(defaultLoading);
  const [entryLoading, setEntryLoading] = useState({});

  useEffect(() => {
    if (establecimientosContext.selected) {
      setLoading(true);
      establecimientosApi
        .getAlimentosExtra({
          establecimientoId: establecimientosContext.selected,
          page: alimentosExtraContext.page - 1,
          pageSize: alimentosExtraContext.sizePerPage,
        })
        .then((result) => {
          setAlimentosExtra(result);
          setLoading(false);
        });
    }
  }, [
    alimentosExtraContext.refresh,
    alimentosExtraContext.page,
    alimentosExtraContext.sizePerPage,
    establecimientosContext.selected,
  ]);

  const onDelete = (alimentoExtra) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setEntryLoading({ [alimentoExtra.id]: true });
        establecimientosApi
          .deleteAlimentosExtra({
            establecimientoId: establecimientosContext.selected,
            id: alimentoExtra.id,
          })
          .then(() => {
            setConfirmationModal(initConfirmationModal);
            setAlimentosExtraContext({
              refresh: !alimentosExtraContext.refresh,
            });
            setEntryLoading({ [alimentoExtra.id]: false });
          });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: `Borrar alimento extra`,
      body: `Confirmas que quieres borrar el registro seleccionado?`,
    });
  };

  const actions = (alimentoExtra, entryLoading) => {
    return entryLoading[alimentoExtra.id] ? (
      <Loader size="sm" align="end" />
    ) : (
      <UncontrolledDropdown className="d-inline-block">
        <DropdownToggle tag="a">
          <MoreVertical className="text-primary" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onDelete(alimentoExtra)}>
            Borrar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <>
      {!establecimientosContext.selected ? (
        <Card className="col-12">
          <CardHeader className="text-center">
            Ningún establecimiento seleccionado
          </CardHeader>
        </Card>
      ) : (
        <Card className="col-12 d-none d-xl-flex">
          <CardHeader className="text-center">
            <div className="card-actions d-flex align-items-center justify-content-between">
              <span className="mr-1 d-flex">
                <span className="cursor-pointer mx-2 d-flex align-items-center">
                  <RefreshCw
                    hidden
                    onClick={() =>
                      setAlimentosExtraContext({
                        refresh: !alimentosExtraContext.refresh,
                      })
                    }
                  />
                </span>
                <small className="text-muted d-flex align-items-center">
                  Mostrando {alimentosExtra?.count || 0} registros
                </small>
              </span>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Loader size="sm" />
            ) : alimentosExtra?.count ? (
              <>
                <BootstrapTable
                  bootstrap4
                  remote
                  bordered={false}
                  keyField="id"
                  data={alimentosExtra.data}
                  columns={[
                    {
                      dataField: "fecha",
                      text: "Fecha",
                      headerClasses: "text-muted font-size-75 align-middle",
                      formatter: (cell) => utils.formatDate(cell, "DD-MM-YYYY"),
                    },
                    {
                      dataField: "racion",
                      text: "Ración",
                      headerClasses: "text-muted font-size-75 align-middle",
                      formatter: (cell, data) => utils.formatDecimal(cell, 2),
                    },
                    {
                      dataField: "ensilaje",
                      text: "Ensilaje",
                      headerClasses: "text-muted font-size-75 align-middle",
                      formatter: (cell, data) => utils.formatDecimal(cell, 2),
                    },
                    {
                      dataField: "fardo",
                      text: "Fardo",
                      headerClasses: "text-muted font-size-75 align-middle",
                      formatter: (cell, data) => utils.formatDecimal(cell, 2),
                    },
                    {
                      dataField: "",
                      text: "",
                      classes: "text-right",
                      formatter: (cell, alimentoExtra) =>
                        actions(alimentoExtra, entryLoading),
                      formatExtraData: { ...entryLoading },
                    },
                  ]}
                  defaultSorted={[
                    {
                      dataField: alimentosExtraContext.sortBy,
                      order: alimentosExtraContext.direction,
                    },
                  ]}
                  onTableChange={() => {}}
                  pagination={paginationFactory({
                    page: alimentosExtraContext.page,
                    totalSize: alimentosExtra?.count,
                    sizePerPage: alimentosExtraContext.sizePerPage,
                    sizePerPageList: [
                      5,
                      10,
                      25,
                      50,
                      { text: "Todos", value: alimentosExtra?.count },
                    ],
                    onPageChange: (page) => setAlimentosExtraContext({ page }),
                    onSizePerPageChange: (sizePerPage) =>
                      setAlimentosExtraContext({ page: 1, sizePerPage }),
                  })}
                />
              </>
            ) : (
              <div className="text-center font-weight-bold">
                No hay resultados
              </div>
            )}
          </CardBody>
        </Card>
      )}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={true}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

export default AlimentosExtra;
