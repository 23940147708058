import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sortBy: "nombre",
  direction: "asc",
  sizePerPage: 10,
  page: 1,
  search: "",
};

const MonitoreoPotrerosContext = createContext(initialState);

const MonitoreoPotrerosProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <MonitoreoPotrerosContext.Provider value={stateAndDispatch}>
      {children}
    </MonitoreoPotrerosContext.Provider>
  );
};

export const useMonitoreoPotreros = () => useContext(MonitoreoPotrerosContext);

export { MonitoreoPotrerosContext, MonitoreoPotrerosProvider };
