import React, { useEffect, useState } from "react";
import { GoogleMap, Polygon, InfoBox } from "@react-google-maps/api";

import { Card, CardBody, CardHeader } from "reactstrap";
import { useEstablecimientos } from "../../../providers/establecimientosProvider";

import { usePotreros } from "../../../providers/potrerosProvider";
import PotreroCard from "./PotreroCard";
import { potrerosApi } from "../../../services/potrerosServices";
import Loader from "../../Loader";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const URUGUAY_COORDINATES = {
  lat: -32.522779,
  lng: -55.765835,
};

const INITIAL_ZOOM = 7;

const mapToPaths = (data) =>
  (data || []).map((c) => {
    return { lat: c[1], lng: c[0] };
  });

const PoterosMapView = () => {
  const [establecimientosContext] = useEstablecimientos();
  const [potrerosContext, setPotrerosContext] = usePotreros();
  const [loading, setLoading] = useState(false);
  const [tooltips, setTooltips] = useState({});
  const [potreros, setPotreros] = useState([]);

  const [map, setMap] = useState(null);

  useEffect(() => {
    if (establecimientosContext.selected) {
      setLoading(true);
      potrerosApi
        .getPotreros(establecimientosContext.selected, {
          pageSize: 999,
          includeGeoInfo: true,
        })
        .then((result) => {
          setPotreros(result.data);
          setLoading(false);
        });
    } else {
      setPotreros([]);
    }
  }, [establecimientosContext.selected, potrerosContext.refresh]);

  const tooltip = (item) => (
    <PotreroCard
      id={`tooltip-${item.id}`}
      className={`${item.nombre ? "" : "d-none"} potrero-tooltip`}
      potrero={item}
    />
  );

  const onMapLoad = (map) => {
    let bounds = new window.google.maps.LatLngBounds();
    let shouldFit = false;
    potreros.forEach((potrero) => {
      if (potrero.infoGeo?.coordinates?.length) {
        potrero.infoGeo.coordinates[0].forEach((coordinate) => {
          bounds.extend(
            new window.google.maps.LatLng(coordinate[1], coordinate[0])
          );
        });
        shouldFit = true;
      }
    });
    if (shouldFit) {
      map.fitBounds(bounds);
    }
    setMap(map);
  };

  const getMapCenter = () => {
    if (map?.center) {
      const { lat, lng } = map.center;
      return {
        lat: lat(),
        lng: lng(),
      };
    }
    return URUGUAY_COORDINATES;
  };

  return (
    <>
      {!establecimientosContext.selected ? (
        <Card className="col-12">
          <CardHeader className="text-center">
            Ningún establecimiento seleccionado
          </CardHeader>
        </Card>
      ) : (
        <Card className="d-flex flex-column flex-grow-1">
          <CardBody className="d-flex flex-column flex-grow-1">
            {loading ? (
              <Loader />
            ) : !potreros.length ? (
              <div className="text-center col-12">
                No hay potreros para el establecimiento seleccionado
              </div>
            ) : (
              <div className="rounded border d-flex flex-column flex-grow-1">
                <div className="text-center my-2 text-warning">
                  <small className="font-weight-bold">
                    Haz click en el potrero para ver detalles.
                  </small>
                </div>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  onLoad={onMapLoad}
                  options={{
                    mapTypeId: map?.mapTypeId || "hybrid",
                    zoom: map?.zoom || INITIAL_ZOOM,
                    streetViewControl: false,
                    center: getMapCenter(),
                  }}
                >
                  {map &&
                    potreros.map((potrero, i) => {
                      if (!potrero.infoGeo) {
                        return null;
                      }
                      const path = mapToPaths(potrero.infoGeo?.coordinates[0]);
                      return (
                        <div key={i}>
                          <Polygon
                            key={i}
                            onClick={() =>
                              setPotrerosContext({ selectedPotrero: potrero })
                            }
                            onMouseOver={() =>
                              setTooltips({ ...tooltips, [potrero.id]: true })
                            }
                            onMouseOut={() =>
                              setTooltips({ ...tooltips, [potrero.id]: false })
                            }
                            path={path}
                          />
                          <InfoBox
                            options={{
                              closeBoxURL: "",
                              enableEventPropagation: false,
                              disableAutoPan: false,
                              alignBottom: false,
                              zIndex: null,
                              boxStyle: {
                                opacity: tooltips[potrero.id] ? 1 : 0,
                                width: "500px",
                                fontSize: "14px",
                              },
                            }}
                            position={{
                              lat: parseFloat(potrero.centerLat),
                              lng: parseFloat(potrero.centerLng),
                            }}
                          >
                            {tooltip(potrero)}
                          </InfoBox>
                        </div>
                      );
                    })}
                </GoogleMap>
              </div>
            )}
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default PoterosMapView;
