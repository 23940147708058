import React, { useState } from "react";

import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
  InputGroup,
  Input,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MoreVertical, RefreshCw } from "react-feather";
import Loader from "../../Loader";
import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import { establecimientosApi } from "../../../services/establecimientosServices";
import EstablecimientoModal from "./EstablecimientoModal";
import EstablecimientosCardsView from "./EstablecimientosCardsView";
import ConfirmationModalWithInput from "../../ConfirmationModalWithInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useSidebar } from "../../../providers/navbarProvider";
import { usePotreros } from "../../../providers/potrerosProvider";
import { useAuth } from "../../../providers/authProvider";
import InformationModal from "../../InformationModal";

const ESTADO_ABIERTO = 1;

const EstablecimientosListView = ({ loading }) => {
  const [establecimientosContext, setEstablecimientosContext] =
    useEstablecimientos();
  const [authContext, setAuthContext] = useAuth();
  const [, setSidebarContext] = useSidebar();
  const [, setPotrerosContext] = usePotreros();

  const [establecimientosLoading, setEstablecimientosLoading] = useState({});
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
    confirmText: null,
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [establecimientoModal, setEstablecimientoModal] = useState({});

  const onSort = (sortBy, direction) => {
    if (
      establecimientosContext.sortBy === sortBy &&
      establecimientosContext.direction === direction
    ) {
      return;
    }
    setEstablecimientosContext({ sortBy, direction, page: 1 });
  };

  const onConfirmDelete = (establecimiento) => {
    setEstablecimientosLoading({ [establecimiento.id]: true });
    establecimientosApi
      .deleteEstablecimiento({ id: establecimiento.id })
      .then(() => {
        setEstablecimientosContext({
          refreshSelector: true,
          refresh: !establecimientosContext.refresh,
          selected:
            establecimientosContext.selected === establecimiento.id
              ? null
              : establecimientosContext.selected,
        });
        setEstablecimientosLoading({ [establecimiento.id]: true });
        setConfirmationModal(initConfirmationModal);
      })
      .catch((err) => {
        setEstablecimientosLoading({ [establecimiento.id]: false });
        setInformationModal({
          isOpen: true,
          title: "Error",
          rawBody: true,
          body: (
            <span>
              $
              {err.response?.data?.message ||
                err.response?.data[0]?.msg ||
                "Ha habido un error con tu solicitud."}
            </span>
          ),
        });
      });
  };

  const onDelete = (establecimiento) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => onConfirmDelete(establecimiento),
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Borrar ${establecimiento.nombre}`,
      confirmText: establecimiento.nombre,
      confirmColor: "danger",
      confirmTextPlaceholder: "Nombre..",
      body: `Para confirmar que quieres borrar el establecimiento seleccionado escribe su nombre en el campo debajo y presiona confirmar.`,
    });
  };

  const onSelectedChange = (establecimientoId) => {
    setSidebarContext({ open: false });
    setAuthContext({
      currentUser: {
        ...authContext.currentUser,
        defaultEstablecimiento: establecimientoId,
      },
    });
    setEstablecimientosContext({ selected: establecimientoId });
    if (establecimientoId) {
      establecimientosApi.selectEstablecimiento({ id: establecimientoId });
    }
    setPotrerosContext({ potreros: {} });
  };

  const actions = (establecimiento, establecimientosLoading) => {
    return establecimientosLoading[establecimiento.id] ? (
      <div className="min-width-50">
        <Loader size="sm" align="end" />
      </div>
    ) : (
      <>
        {establecimientosContext.selected === establecimiento.id ? (
          <Badge color={"secondary"} size={18} pill className="mr-4">
            Seleccionado
          </Badge>
        ) : null}
        {authContext.currentUser.ingresoDatosHabilitado ? (
          <UncontrolledDropdown className="d-inline-block">
            <DropdownToggle tag="a">
              <MoreVertical className="text-primary" />
            </DropdownToggle>
            <DropdownMenu right>
              {establecimientosContext.selected !== establecimiento.id ? (
                <DropdownItem
                  onClick={() => onSelectedChange(establecimiento.id)}
                >
                  Seleccionar
                </DropdownItem>
              ) : null}
              <DropdownItem
                onClick={() =>
                  setEstablecimientoModal({ open: true, establecimiento })
                }
              >
                Editar
              </DropdownItem>
              <DropdownItem onClick={() => onDelete(establecimiento)}>
                Borrar
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Card className="d-none d-lg-flex">
        <CardHeader>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup className="mr-3 col-4 px-0" size="m">
              <Input
                maxLength="50"
                value={establecimientosContext.search}
                onChange={(evt) =>
                  setEstablecimientosContext({
                    search: evt.target.value,
                    page: 1,
                  })
                }
                className="border-0"
                placeholder="Buscar.."
              />
            </InputGroup>
            <span className="mr-1 d-flex">
              <span className="cursor-pointer mx-2 d-flex align-items-center">
                <RefreshCw
                  hidden
                  onClick={() =>
                    setEstablecimientosContext({
                      refresh: !establecimientosContext.refresh,
                    })
                  }
                />
              </span>
              <small className="text-muted d-flex align-items-center">
                Mostrando {establecimientosContext.establecimientos?.count || 0}{" "}
                establecimientos
              </small>
            </span>
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader size="sm" />
          ) : establecimientosContext.establecimientos.count ? (
            <>
              <BootstrapTable
                bootstrap4
                remote
                bordered={false}
                keyField="id"
                data={establecimientosContext.establecimientos.data}
                columns={[
                  {
                    dataField: "id",
                    text: "Id",
                    headerClasses: "text-truncate text-muted font-size-75",
                    fixedWidth: true,
                    width: 100,
                    classes: "text-truncate",
                    sort: true,
                    onSort,
                  },
                  {
                    dataField: "nombre",
                    text: "Nombre",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    sort: true,
                    onSort,
                  },
                  {
                    dataField: "departamento.nombre",
                    text: "Departamento",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    sort: false,
                  },
                  {
                    dataField: "potreros",
                    text: "#Potreros",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    sort: false,
                    formatter: (cell, establecimiento) =>
                      establecimiento.potreros?.length || 0,
                  },
                  {
                    dataField: "estado",
                    text: "Estado",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    sort: false,
                    formatter: (cell, establecimiento) => (
                      <div className="d-flex align-items-center">
                        <span className="text-right">
                          {parseInt(cell) === ESTADO_ABIERTO
                            ? "Abierto"
                            : "Cerrado"}
                        </span>
                        <FontAwesomeIcon
                          id={`estadoInfo-${establecimiento.id}`}
                          icon={faInfoCircle}
                          fixedWidth
                          className="ml-2 text-info cursor-pointer"
                        />
                        <UncontrolledTooltip
                          placement={"bottom"}
                          target={`estadoInfo-${establecimiento.id}`}
                        >
                          {parseInt(cell) === ESTADO_ABIERTO
                            ? "Ingreso de datos habilitado para el establecimiento"
                            : "Ingreso de datos deshabilitado para el establecimiento"}
                        </UncontrolledTooltip>
                      </div>
                    ),
                  },
                  {
                    dataField: "",
                    text: "",
                    sort: false,
                    classes: "text-right",
                    formatter: (cell, establecimiento) =>
                      actions(establecimiento, establecimientosLoading),
                    formatExtraData: {
                      ...establecimientosLoading,
                      selected: establecimientosContext.selected,
                    },
                  },
                ]}
                defaultSorted={[
                  {
                    dataField: establecimientosContext.sortBy,
                    order: establecimientosContext.direction,
                  },
                ]}
                onTableChange={() => {}}
                pagination={paginationFactory({
                  page: establecimientosContext.page,
                  totalSize: establecimientosContext.establecimientos.count,
                  sizePerPage: establecimientosContext.sizePerPage,
                  sizePerPageList: [
                    5,
                    10,
                    25,
                    50,
                    {
                      text: "Todos",
                      value: establecimientosContext.establecimientos.count,
                    },
                  ],
                  onPageChange: (page) => setEstablecimientosContext({ page }),
                  onSizePerPageChange: (sizePerPage) =>
                    setEstablecimientosContext({ page: 1, sizePerPage }),
                })}
              />
            </>
          ) : (
            <div className="text-center">No hay resultados</div>
          )}
          {}
          {establecimientoModal.open ? (
            <EstablecimientoModal
              establecimiento={establecimientoModal.establecimiento}
              onClose={() =>
                setEstablecimientoModal({ open: false, establecimiento: null })
              }
            />
          ) : null}
        </CardBody>
      </Card>
      <EstablecimientosCardsView
        loading={loading}
        establecimientosLoading={establecimientosLoading}
        onDelete={onDelete}
        onEdit={(establecimiento) =>
          setEstablecimientoModal({ open: true, establecimiento })
        }
      />
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModalWithInput {...confirmationModal} />
      ) : null}
    </>
  );
};

export default EstablecimientosListView;
