import React, { useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
  Badge,
} from "reactstrap";
import { importacionesApi } from "../../services/importacionesServices";
import InformationModal from "../InformationModal";
import Loader from "../Loader";

const DetalleImportacionModal = ({ id, onClose }) => {
  const [crecimientosPotrero, setCrecimientosPotrero] = useState();
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [loading, setLoading] = useState(true);

  const [sortBy, setSortBy] = useState("potrero.nombre");
  const [direction, setDirection] = useState("asc");

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSort = (newSortBy, newDirection) => {
    if (sortBy === newSortBy && direction === newDirection) {
      return;
    }
    setSortBy(newSortBy);
    setDirection(newDirection);
  };

  useEffect(() => {
    setLoading(true);
    importacionesApi
      .getCrecimientosPotrero(id, {
        page: page - 1,
        sizePerPage,
        sortBy,
        direction,
      })
      .then((crecimientosPotrero) => {
        setCrecimientosPotrero(crecimientosPotrero);
        setLoading(false);
      });
  }, [id, page, sizePerPage, sortBy, direction]);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal size="xl" isOpen={true} onClosed={onClose}>
      <ModalHeader>Detalle de Importación</ModalHeader>
      <ModalBody className="mb-n3">
        {loading ? (
          <Loader size="sm" />
        ) : crecimientosPotrero.count ? (
          <BootstrapTable
            bootstrap4
            remote
            bordered={false}
            keyField="id"
            bodyClasses="bg-lighter"
            data={crecimientosPotrero.data}
            columns={[
              {
                dataField: "potrero.nombre",
                text: "Potrero",
                headerClasses: "text-truncate text-muted font-size-75",
                classes: "text-truncate",
                sort: true,
                onSort,
              },
              {
                dataField: "tipoUsoSuelo.nombre",
                text: "Uso",
                headerClasses: "text-truncate text-muted font-size-75",
                classes: "text-truncate",
                sort: true,
                onSort,
              },
              {
                dataField: "anio",
                text: "Año",
                headerClasses: "text-truncate text-muted font-size-75",
                classes: "text-truncate",
                sort: true,
                onSort,
              },
              {
                dataField: "pixeles",
                text: "Pixeles",
                headerClasses: "text-truncate text-muted font-size-75",
                classes: "text-truncate",
                sort: true,
                onSort,
              },
              {
                dataField: "eviq",
                text: "Eviq",
                headerClasses: "text-truncate text-muted font-size-75",
                classes: "text-truncate",
                sort: true,
                onSort,
              },
              {
                dataField: "frfa",
                text: "FRFA",
                headerClasses: "text-truncate text-muted font-size-75",
                classes: "text-truncate",
                sort: true,
                onSort,
              },
              {
                dataField: "ordenada_eur",
                text: "Ordenada",
                headerClasses: "text-truncate text-muted font-size-75",
                classes: "text-truncate",
                sort: true,
                onSort,
              },
              {
                dataField: "pendiente_eur",
                text: "Pendiente",
                headerClasses: "text-truncate text-muted font-size-75",
                classes: "text-truncate",
                sort: true,
                onSort,
              },
              {
                dataField: "ppna__kg_ha_dia",
                text: "PPNA/Dia",
                headerClasses: "text-truncate text-muted font-size-75",
                classes: "text-truncate",
                sort: true,
                onSort,
              },
              {
                dataField: "ppna__kg_ha_mes",
                text: "PPNA/Mes",
                headerClasses: "text-truncate text-muted font-size-75",
                classes: "text-truncate",
                sort: true,
                onSort,
              },
              {
                dataField: "id",
                text: "",
                formatter: (cell, crecimientoPotrero) => (
                  <Badge
                    color={crecimientoPotrero.current ? "success" : "danger"}
                    size={18}
                    pill
                    className="mr-4"
                  >
                    {crecimientoPotrero.current ? "Actual" : "Obsoleto"}
                  </Badge>
                ),
              },
            ]}
            classes="border rounded"
            defaultSorted={[{ dataField: sortBy, order: direction }]}
            onTableChange={() => {}}
            pagination={paginationFactory({
              page,
              totalSize: crecimientosPotrero.count,
              sizePerPage,
              sizePerPageList: [
                5,
                10,
                25,
                50,
                { text: "Todos", value: crecimientosPotrero.count },
              ],
              onPageChange: (page) => setPage(page),
              onSizePerPageChange: (sizePerPage) => {
                setSizePerPage(sizePerPage);
                setPage(1);
              },
            })}
          />
        ) : (
          <div className="text-center mb-3">No hay resultados</div>
        )}
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color={"tertiary"} onClick={onClose}>
              Cerrar
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default DetalleImportacionModal;
