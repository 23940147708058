// Admin
import Notificaciones from "../pages/admin/Notificaciones";
import Shapefiles from "../pages/admin/Shapefiles";
import ImportacionesCrecimientos from "../pages/admin/ImportacionesCrecimientos";
import Departamentos from "../pages/admin/Departamentos";
import SeccionesPoliciales from "../pages/admin/SeccionesPoliciales";
import ZonasGeomorfologicas from "../pages/admin/ZonasGeomorfologicas";

//Customer

// Auth
import SignIn from "../pages/auth/signIn/SignIn";
import EstablecimientosAdmin from "../pages/admin/Establecimientos";
import EstablecimientosCustomer from "../pages/customer/Establecimientos";
import Potreros from "../pages/customer/Potreros";
import SeguimientoForrajero from "../pages/customer/SeguimientoForrajero";
import MonitoreoPotreros from "../pages/customer/MonitoreoPotreros";
import Configuracion from "../pages/customer/Configuracion";
import {
  faChartLine,
  faCogs,
  faDrawPolygon,
  faHome,
  faSatellite,
} from "@fortawesome/free-solid-svg-icons";
import TipoUsosSuelo from "../pages/admin/TipoUsosSuelo";
import Usuarios from "../pages/admin/Usuarios";

// Routes
export const adminRoutes = [
  {
    path: "/back/usuarios",
    name: "Usuarios",
    navbar: true,
    component: Usuarios,
  },
  {
    path: "/back/notificaciones",
    name: "Notificaciones",
    navbar: true,
    component: Notificaciones,
  },
  {
    path: "/back/tipo-usos-suelo",
    name: "Tipo Usos Suelo",
    navbar: true,
    component: TipoUsosSuelo,
  },
  {
    path: "/back/establecimientos",
    name: "Establecimientos",
    navbar: true,
    component: EstablecimientosAdmin,
  },
  {
    path: "/back/descarga-shapefiles",
    name: "Descarga de Shapefiles",
    navbar: true,
    component: Shapefiles,
  },
  {
    path: "/back/importar-datos-tc",
    name: "Importaciones de Crecimientos",
    navbar: true,
    component: ImportacionesCrecimientos,
  },
  {
    path: "/back/departamentos",
    name: "Departamentos",
    navbar: false,
    component: Departamentos,
  },
  {
    path: "/back/secciones-policiales",
    name: "Secciones Policiales",
    navbar: false,
    component: SeccionesPoliciales,
  },
  {
    path: "/back/zonas-geomorfologicas",
    name: "Zonas Geomorfológicas",
    navbar: false,
    component: ZonasGeomorfologicas,
  },
];

export const customerRoutes = [
  {
    path: "/portal/establecimientos",
    name: "Establecimientos",
    component: EstablecimientosCustomer,
    icon: faHome,
    sidebar: true,
  },
  {
    path: "/portal/potreros",
    name: "Potreros",
    component: Potreros,
    icon: faDrawPolygon,
    sidebar: true,
  },
  {
    path: "/portal/seguimiento",
    name: "Seguimiento Forrajero",
    component: SeguimientoForrajero,
    icon: faSatellite,
    sidebar: true,
    condition: "enabledSF",
  },
  {
    path: "/portal/monitoreo",
    name: "Monitoreo de Potreros",
    component: MonitoreoPotreros,
    icon: faChartLine,
    sidebar: true,
    condition: "enabledMonitoreo",
  },
  {
    path: "/portal/configuracion",
    name: "Configuración",
    component: Configuracion,
    icon: faCogs,
    sidebar: false,
  },
];

export const authRoutes = [
  {
    path: "/auth/sign-in",
    name: "Ingresar",
    component: SignIn,
  },
];
