import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroupItem,
  Col,
  Row,
  ListGroup,
  Table,
  CustomInput,
} from "reactstrap";
import Loader from "../../../Loader";
import { utils } from "../../../../utils/utils";
import moment from "moment";
import { establecimientosApi } from "../../../../services/establecimientosServices";
import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import { useMonitoreoPotreros } from "../../../../providers/monitoreoPotrerosProvider";

const mesActual = moment().get("month") + 1;
const anioActual = moment().get("year");

const LIMIT_YEAR = 2000;

const anios = [];
let anio = moment().get("year");

while (anio >= LIMIT_YEAR) {
  anios.push(anio);
  anio = moment().subtract(anios.length, "year").get("year");
}

const IndicadoresProyeccionModal = ({
  indicadoresEstablecimiento,
  onClose,
}) => {
  const [establecimientosContext] = useEstablecimientos();
  const [monitoreoPotrerosContext] = useMonitoreoPotreros();

  const [loading, setLoading] = useState(true);
  const [cargaPosibleReal, setCargaPosibleReal] = useState(0);
  const [mesCargaSegura, setMesCargaSegura] = useState(mesActual);
  const [anioCargaSegura, setAnioCargaSegura] = useState(anioActual);

  useEffect(() => {
    if (establecimientosContext.selected) {
      setLoading(true);
      establecimientosApi
        .getCargaPosibleRealEstablecimiento({
          id: establecimientosContext.selected,
          mesCargaSegura,
          anioCargaSegura,
        })
        .then((cargaPosibleRealMesAnio) => {
          setCargaPosibleReal(cargaPosibleRealMesAnio);
          setLoading(false);
        });
    } else {
      setCargaPosibleReal({});
    }
  }, [
    monitoreoPotrerosContext.refresh,
    establecimientosContext.selected,
    anioCargaSegura,
    mesCargaSegura,
  ]);

  return (
    <Modal size="md" isOpen={true}>
      <ModalHeader>Indicadores de Uso de Potrero</ModalHeader>
      <ModalBody className="text-center">
        <ListGroup>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <span className="text-left text-muted">
                Posibilidad de pastoreo en el corto plazo
              </span>
              <small className="text-left text-muted">
                Porcentaje del área mayor o igual a 5 cm.
              </small>
            </div>
            <span className="text-right">
              {Math.round(
                indicadoresEstablecimiento.posibilidadPastoreoCortoPlazo * 100
              )}{" "}
              %
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <span className="text-left text-muted">
                Posibilidad de pastoreo en el mediano plazo
              </span>
              <small className="text-left text-muted">
                Porcentaje del área mayor o igual a 2.5 cm.
              </small>
            </div>
            <span className="text-right">
              {Math.round(
                indicadoresEstablecimiento.posibilidadPastoreoMedianoPlazo * 100
              )}{" "}
              %
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex flex-column text-center">
            <span className="text-left text-muted text-center">
              Carga Posible Real
            </span>
            <Table size="sm" className="small my-0 bg-lighter border mt-2">
              <thead className="text-muted">
                <tr>
                  <th className="border-0">Mes</th>
                  <th className="border-0">Año</th>
                  <th className="border-0">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <CustomInput
                      id="mesSelect"
                      className="text-truncate bg-lightgreen max-width-150"
                      type="select"
                      name="mesSelect"
                      onChange={(event) =>
                        setMesCargaSegura(parseInt(event.currentTarget.value))
                      }
                      defaultValue={mesCargaSegura}
                    >
                      {utils.meses.map((mes, index) => (
                        <option key={index} value={index + 1}>
                          {mes}
                        </option>
                      ))}
                    </CustomInput>
                  </td>
                  <td>
                    <CustomInput
                      id="anioSelect"
                      className="text-truncate bg-lightgreen max-width-150"
                      type="select"
                      name="anioSelect"
                      onChange={(event) =>
                        setAnioCargaSegura(parseInt(event.currentTarget.value))
                      }
                      defaultValue={anioCargaSegura}
                    >
                      {anios.map((anio, index) => (
                        <option key={index} value={anio}>
                          {anio}
                        </option>
                      ))}
                    </CustomInput>
                  </td>
                  <td>
                    {loading ? (
                      <Loader size="sm" align="center" />
                    ) : (
                      `${utils.formatDecimal(Math.max(0, cargaPosibleReal))}`
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </ListGroupItem>
        </ListGroup>
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color={"secondary"} onClick={() => onClose()}>
              Cerrar
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default IndicadoresProyeccionModal;
