import React, { useEffect, useState } from "react";
import {
  faInfoCircle,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  Container,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  Input,
  Button,
  InputGroupText,
} from "reactstrap";

import { useUsuarios } from "../../providers/usuariosProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import InformationModal from "../../components/InformationModal";
import { usuariosApi } from "../../services/usuariosServices";
import { RefreshCw } from "react-feather";
import CustomCheckbox from "../../components/CustomCheckbox";
import CreateUsuarioModal from "../../components/admin/CreateUsuarioModal";
import TooltipItem from "../../components/TooltipItem";
import ConfirmationModal from "../../components/ConfirmationModal";

const Usuarios = () => {
  const [usuariosContext, setUsuariosContext] = useUsuarios();

  const [loading, setLoading] = useState(false);
  const [usuarioModal, setUsuarioModal] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  useEffect(() => {
    setLoading(true);
    usuariosApi
      .getUsuarios({
        search: usuariosContext.search,
        page: usuariosContext.page - 1,
        pageSize: usuariosContext.sizePerPage,
        sortBy: usuariosContext.sortBy,
        direction: usuariosContext.direction,
      })
      .then((usuarios) => {
        setUsuariosContext({ usuarios });
        setLoading(false);
      });
  }, [
    usuariosContext.search,
    usuariosContext.page,
    usuariosContext.sizePerPage,
    usuariosContext.sortBy,
    usuariosContext.direction,
    usuariosContext.refresh,
    setUsuariosContext,
  ]);

  const onToggle = async (usuario, item) => {
    setLoading(true);
    await usuariosApi.updateUsuario({
      ...usuario,
      [item]: !usuario[item],
    });
    setUsuariosContext({
      refresh: !usuariosContext.refresh,
    });
  };

  const onDelete = (usuario) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        setLoading(true);
        usuariosApi
          .deleteUsuario({
            id: usuario.id,
          })
          .then(() => {
            setLoading(false);
            setConfirmationModal(initConfirmationModal);
            setUsuariosContext({
              refresh: !usuariosContext.refresh,
            });
          })
          .catch((err) => {
            setLoading(false);
            setConfirmationModal(initConfirmationModal);
            if (err.response?.data[0]?.msg) {
              setInformationModal({
                isOpen: true,
                title: "Error",
                rawBody: true,
                body: <span>{err.response?.data[0]?.msg}</span>,
              });
            }
          });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: `Borrar tipo de uso de suelo`,
      body: `¿Confirmas que quieres borrar el usuario ${
        usuario.nombre || ""
      } y todos sus datos asociados? Esta acción es irreversible.`,
    });
  };

  return (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Card className="d-flex">
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Usuarios</h3>
            <small className="text-muted ml-2 pt-1">
              ({usuariosContext.usuarios.count})
            </small>
          </div>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup size="m" className="mr-3">
              <Input
                className="rounded-left rounded-right-0"
                maxLength="50"
                placeholder="Buscar.."
                value={usuariosContext.search}
                onChange={(evt) =>
                  setUsuariosContext({ search: evt.target.value, page: 1 })
                }
              />
              <InputGroupText className="rounded-right rounded-left-0 search-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="mr-3 rounded-circle d-flex custom-rounded-button-plus"
              color="primary"
              onClick={() => setUsuarioModal(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh"
              color="primary"
              onClick={() =>
                setUsuariosContext({ refresh: !usuariosContext.refresh })
              }
            >
              <RefreshCw />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader size="sm" />
          ) : usuariosContext.usuarios.count ? (
            <BootstrapTable
              bootstrap4
              remote
              bordered={false}
              headerClasses="text-muted small"
              keyField="id"
              data={usuariosContext.usuarios.data}
              columns={[
                {
                  dataField: "cedula",
                  text: "Cedula",
                  headerClasses: "text-muted",
                  classes: "text-truncate",
                  formatter: (value, cell) => value,
                },
                {
                  dataField: "ingresoDatosHabilitado",
                  text: (
                    <div className="d-flex justify-content-center align-items-center">
                      <span>Ingreso Información Habilitado</span>
                      <TooltipItem
                        id="ingreso-info-tooltip"
                        title="Para Establecimientos, Potreros y Usos de suelo"
                      >
                        <FontAwesomeIcon
                          size="lg"
                          icon={faInfoCircle}
                          className="ml-2"
                          style={{ paddingBottom: "1px" }}
                        />
                      </TooltipItem>
                    </div>
                  ),
                  formatter: (value, usuario) => (
                    <div className="d-flex justify-content-center">
                      <CustomCheckbox
                        checked={value}
                        onClick={() =>
                          onToggle(usuario, "ingresoDatosHabilitado")
                        }
                      />
                    </div>
                  ),
                  headerClasses: "text-center text-muted",
                  classes: "text-center",
                },
                {
                  dataField: "enabledMonitoreo",
                  text: "Módulo Monitoreo Habilitado",
                  formatter: (value, usuario) => (
                    <div className="d-flex justify-content-center">
                      <CustomCheckbox
                        checked={value}
                        onClick={() => onToggle(usuario, "enabledMonitoreo")}
                      />
                    </div>
                  ),
                  headerClasses: "text-center text-muted",
                  classes: "text-center",
                },
                {
                  dataField: "enabledSF",
                  text: "Módulo Seguimiento Forrajero Habilitado",
                  formatter: (value, usuario) => (
                    <div className="d-flex justify-content-center">
                      <CustomCheckbox
                        checked={value}
                        onClick={() => onToggle(usuario, "enabledSF")}
                      />
                    </div>
                  ),
                  headerClasses: "text-center text-muted",
                  classes: "text-center",
                },
                {
                  dataField: "id",
                  text: "Acciones",
                  formatter: (id, cell) => (
                    <Button
                      size="sm"
                      color="danger"
                      type="button"
                      className="rounded view-selector font-weight-bold"
                      onClick={() => onDelete(cell)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  ),
                  headerClasses: "text-center text-muted",
                  classes: "text-center",
                },
              ]}
              defaultSorted={[
                {
                  dataField: usuariosContext.sortBy,
                  order: usuariosContext.direction,
                },
              ]}
              onTableChange={() => {}}
              pagination={paginationFactory({
                page: usuariosContext.page,
                totalSize: usuariosContext.usuarios.count,
                sizePerPage: usuariosContext.sizePerPage,
                sizePerPageList: [
                  5,
                  10,
                  25,
                  50,
                  { text: "Todos", value: usuariosContext.usuarios.count },
                ],
                onPageChange: (page) => setUsuariosContext({ page }),
                onSizePerPageChange: (sizePerPage) =>
                  setUsuariosContext({ page: 1, sizePerPage }),
              })}
            />
          ) : (
            <div className="text-center">No hay resultados</div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : usuarioModal ? (
        <CreateUsuarioModal
          onSubmit={() => {
            setUsuarioModal(false);
            setUsuariosContext({
              refresh: !usuariosContext.refresh,
            });
          }}
          onClose={() => setUsuarioModal(false)}
        />
      ) : null}
    </Container>
  );
};

export default Usuarios;
