import React from "react";
import Routes from "./routes/Routes";
import { AuthProvider } from "./providers/authProvider";
import { SidebarProvider } from "./providers/navbarProvider";
import { EstablecimientosProvider } from "./providers/establecimientosProvider";
import { PotrerosProvider } from "./providers/potrerosProvider";
import { NotificacionesProvider } from "./providers/notificacionesProvider";
import { LoadScript } from "@react-google-maps/api";
import { ImportacionesProvider } from "./providers/importacionesProvider";
import { MonitoreoPotrerosProvider } from "./providers/monitoreoPotrerosProvider";
import { UsosPotrerosProvider } from "./providers/usosPotrerosProvider";
import { EstadosSituacionProvider } from "./providers/estadosSituacionProvider";
import { MovimientoProvider } from "./providers/movimientosProvider";
import { InformacionPotreroModalProvider } from "./providers/informacionPotreroModalProvider";
import { AlimentosExtraProvider } from "./providers/alimentosExtraProvider";
import { UsuariosProvider } from "./providers/usuariosProvider";

const libraries = ["drawing", "places"];

class App extends React.Component {
  componentDidMount() {
    // Remove `active` className from `.splash` element in `public/index.html`
    !document.querySelector(".splash") ||
      document.querySelector(".splash").classList.remove("active");
  }

  render() {
    return (
      <AuthProvider>
        <SidebarProvider>
          <EstablecimientosProvider>
            <PotrerosProvider>
              <NotificacionesProvider>
                <ImportacionesProvider>
                  <MonitoreoPotrerosProvider>
                    <UsosPotrerosProvider>
                      <MovimientoProvider>
                        <EstadosSituacionProvider>
                          <InformacionPotreroModalProvider>
                            <LoadScript
                              libraries={libraries}
                              googleMapsApiKey="AIzaSyDF2hC8ArOi4Vr1D6d4kdDqxz63rkxt3bQ"
                            >
                              <AlimentosExtraProvider>
                                <UsuariosProvider>
                                  <Routes />
                                </UsuariosProvider>
                              </AlimentosExtraProvider>
                            </LoadScript>
                          </InformacionPotreroModalProvider>
                        </EstadosSituacionProvider>
                      </MovimientoProvider>
                    </UsosPotrerosProvider>
                  </MonitoreoPotrerosProvider>
                </ImportacionesProvider>
              </NotificacionesProvider>
            </PotrerosProvider>
          </EstablecimientosProvider>
        </SidebarProvider>
      </AuthProvider>
    );
  }
}

export default App;
