import React from "react";
import { Redirect } from "react-router-dom";

import { useAuth } from "../../../providers/authProvider";
import { LoginForm } from "./LoginForm";

const ROLE_ADMIN = "1";

const redirectTo = (location, role) => {
  const root = role === ROLE_ADMIN ? "back" : "portal";
  if (location.search && location.search.indexOf(`/${root}/`) > -1) {
    const params = new URLSearchParams(location.search);
    return <Redirect to={params.get("returnURL")} />;
  }
  return (
    <Redirect
      to={
        role === ROLE_ADMIN ? `/${root}/usuarios` : `/${root}/establecimientos`
      }
    />
  );
};

const SignIn = ({ location }) => {
  const [authContext] = useAuth();

  if (!authContext.currentUser) {
    return <LoginForm />;
  }

  return redirectTo(location, authContext.currentUser.rol);
};

export default SignIn;
