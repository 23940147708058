import React, { useEffect, useState } from "react";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  Input,
  Badge,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MoreVertical, RefreshCw } from "react-feather";
import Loader from "../../../Loader";
import { establecimientosApi } from "../../../../services/establecimientosServices";

import InformationModal from "../../../InformationModal";
import { useEstadosSituacion } from "../../../../providers/estadosSituacionProvider";
import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import ConfirmationModal from "../../../ConfirmationModal";
import { potrerosApi } from "../../../../services/potrerosServices";
import { utils } from "../../../../utils/utils";
import {
  useInformacionPotreroModal,
  initialState,
} from "../../../../providers/informacionPotreroModalProvider";

const TYPE_SITUACION = "1";

const EstadosSituacion = ({ loading: defaultLoading }) => {
  const [establecimientosContext] = useEstablecimientos();
  const [estadosSituacionContext, setEstadosSituacionContext] =
    useEstadosSituacion();
  const [, setInformacionPotreroModalContext] = useInformacionPotreroModal();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [estadosSituacion, setEstadosSituacion] = useState({});
  const [loading, setLoading] = useState(defaultLoading);
  const [situacionesLoading, setSituacionesLoading] = useState({});

  const onSort = (sortBy, direction) => {
    if (
      estadosSituacionContext.sortBy === sortBy &&
      estadosSituacionContext.direction === direction
    ) {
      return;
    }
    setEstadosSituacionContext({ sortBy, direction, page: 1 });
  };

  useEffect(() => {
    if (establecimientosContext.selected) {
      setLoading(true);
      establecimientosApi
        .getSituacionesPotreros({
          establecimientoId: establecimientosContext.selected,
          search: estadosSituacionContext.search,
          page: estadosSituacionContext.page - 1,
          pageSize: estadosSituacionContext.sizePerPage,
          sortBy: estadosSituacionContext.sortBy,
          direction: estadosSituacionContext.direction,
        })
        .then((result) => {
          setEstadosSituacion(result);
          setLoading(false);
        });
    }
  }, [
    estadosSituacionContext.refresh,
    estadosSituacionContext.selected,
    estadosSituacionContext.search,
    estadosSituacionContext.page,
    estadosSituacionContext.sizePerPage,
    estadosSituacionContext.sortBy,
    estadosSituacionContext.direction,
    establecimientosContext.selected,
  ]);

  const onDetalleSituacionPotrero = (situacion) => {
    setInformacionPotreroModalContext({
      ...initialState,
      id: situacion.id,
      isLast: situacion.isLast,
      disabled: situacion.movimientoPotreroId,
      isOpen: true,
      potrero: situacion.potreroId,
      mode: TYPE_SITUACION,
      fecha: situacion.fecha,
      calidadPasto: situacion.calidadPasto,
      alturaPastoOrigen: situacion.alturaPasto,
      efectivoPastoreoOrigen: situacion.efectivoPastoreo,
      tipoUsoSueloOrigen: { id: situacion.tipoUsoSueloId },
      observaciones: situacion.observaciones,
      animales: situacion.animales,
    });
  };

  const onDeleteSituacionPotrero = (situacion) => {
    if (!situacion.isLast) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        rawBody: true,
        body: (
          <>
            <p>
              Existen estados de situación posteriores al seleccionado, por lo
              que borrarlo generaría inconsistencias.
            </p>
            <span>
              Borra todos los posteriores para poder borrar el estado de
              situación seleccionado.
            </span>
          </>
        ),
      });
    }
    if (situacion.movimientoPotreroId) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        rawBody: true,
        body: (
          <>
            <p>
              El estado de situación seleccionado deriva de un movimiento
              realizado.
            </p>
            <span>
              Borra el movimiento asociado para borrar el estado de situación
              seleccionado.
            </span>
          </>
        ),
      });
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setSituacionesLoading({ [situacion.id]: true });
        potrerosApi
          .deleteSituacionPotrero({
            establecimientoId: establecimientosContext.selected,
            potreroId: situacion.potreroId,
            id: situacion.id,
          })
          .then(() => {
            setConfirmationModal(initConfirmationModal);
            setEstadosSituacionContext({
              refresh: !estadosSituacionContext.refresh,
            });
            setSituacionesLoading({ [situacion.id]: false });
          });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: `Borrar situación de potrero`,
      body: `Confirmas que quieres borrar la situación del potrero seleccionada?`,
    });
  };

  const actions = (situacion, situacionesLoading) => {
    return situacionesLoading[situacion.id] ? (
      <Loader size="sm" align="end" />
    ) : (
      <UncontrolledDropdown className="d-inline-block">
        <DropdownToggle tag="a">
          <MoreVertical className="text-primary" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onDetalleSituacionPotrero(situacion)}>
            Ver más
          </DropdownItem>
          <DropdownItem onClick={() => onDeleteSituacionPotrero(situacion)}>
            Borrar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <>
      {!establecimientosContext.selected ? (
        <Card className="col-12">
          <CardHeader className="text-center">
            Ningún establecimiento seleccionado
          </CardHeader>
        </Card>
      ) : (
        <Card className="col-12 d-none d-xl-flex">
          <CardHeader className="text-center">
            <div className="card-actions d-flex align-items-center justify-content-between">
              <InputGroup className="mr-3 col-4 px-0" size="m">
                <Input
                  maxLength="50"
                  value={estadosSituacionContext.search}
                  onChange={(evt) =>
                    setEstadosSituacionContext({
                      search: evt.target.value,
                      page: 1,
                    })
                  }
                  className="border-0"
                  placeholder="Buscar por potrero.."
                />
              </InputGroup>
              <span className="mr-1 d-flex">
                <span className="cursor-pointer mx-2 d-flex align-items-center">
                  <RefreshCw
                    hidden
                    onClick={() =>
                      setEstadosSituacionContext({
                        refresh: !estadosSituacionContext.refresh,
                      })
                    }
                  />
                </span>
                <small className="text-muted d-flex align-items-center">
                  Mostrando {estadosSituacion.count || 0} estados de situación
                </small>
              </span>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Loader size="sm" />
            ) : estadosSituacion.count ? (
              <>
                <BootstrapTable
                  bootstrap4
                  remote
                  bordered={false}
                  keyField="id"
                  data={estadosSituacion.data}
                  columns={[
                    {
                      dataField: "potrero.nombre",
                      text: "Potrero",
                      headerClasses: "text-muted font-size-75 align-middle",
                      onSort,
                    },
                    {
                      dataField: "fecha",
                      text: "Fecha",
                      headerClasses: "text-muted font-size-75 align-middle",
                      formatter: (cell) => utils.formatDate(cell, "DD-MM-YYYY"),
                      onSort,
                    },
                    {
                      dataField: "isLast",
                      text: "",
                      headerClasses: "text-muted font-size-75 align-middle",
                      formatter: (cell, data) =>
                        data.isLast ? <Badge>Último Estado</Badge> : null,
                      onSort,
                    },
                    {
                      dataField: "",
                      text: "",
                      classes: "text-right",
                      formatter: (cell, situacion) =>
                        actions(situacion, situacionesLoading),
                      formatExtraData: { ...situacionesLoading },
                    },
                  ]}
                  defaultSorted={[
                    {
                      dataField: estadosSituacionContext.sortBy,
                      order: estadosSituacionContext.direction,
                    },
                  ]}
                  onTableChange={() => {}}
                  pagination={paginationFactory({
                    page: estadosSituacionContext.page,
                    totalSize: estadosSituacion.count,
                    sizePerPage: estadosSituacionContext.sizePerPage,
                    sizePerPageList: [
                      5,
                      10,
                      25,
                      50,
                      { text: "Todos", value: estadosSituacion.count },
                    ],
                    onPageChange: (page) =>
                      setEstadosSituacionContext({ page }),
                    onSizePerPageChange: (sizePerPage) =>
                      setEstadosSituacionContext({ page: 1, sizePerPage }),
                  })}
                />
              </>
            ) : (
              <div className="text-center font-weight-bold">
                No hay resultados
              </div>
            )}
          </CardBody>
        </Card>
      )}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={true}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

export default EstadosSituacion;
