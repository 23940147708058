import React, { useState } from "react";

import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Row,
  Input,
} from "reactstrap";

import { establecimientosApi } from "../../../../services/establecimientosServices";
import {
  initialState,
  useInformacionPotreroModal,
} from "../../../../providers/informacionPotreroModalProvider";
import { useAlimentosExtra } from "../../../../providers/alimentosExtraProvider";
import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import { useMonitoreoPotreros } from "../../../../providers/monitoreoPotrerosProvider";

import InformationModal from "../../../InformationModal";
import Loader from "../../../Loader";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "moment/locale/es";

const AlimentosExtraModal = ({ onClose }) => {
  const [monitoreoPotrerosContext, setMonitoreoPotrerosContext] =
    useMonitoreoPotreros();
  const [establecimientosContext] = useEstablecimientos();
  const [informacionPotreroModalContext, setInformacionPotreroModalContext] =
    useInformacionPotreroModal();
  const [alimentosExtraContext, setAlimentosExtraContext] = useAlimentosExtra();

  const [loading, setLoading] = useState("");
  const [fecha, setFecha] = useState(moment());
  const [racion, setRacion] = useState("");
  const [ensilaje, setEnsilaje] = useState("");
  const [fardo, setFardo] = useState("");

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onCreateSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    establecimientosApi
      .createAlimentosExtra({
        establecimientoId: establecimientosContext.selected,
        fecha: fecha.format("YYYY-MM-DD"),
        racion,
        ensilaje,
        fardo,
      })
      .then(() => {
        setLoading(false);
        setInformationModal({
          rawBody: true,
          isOpen: true,
          title: "Crear Alimentos Extra",
          body: <span>Alimentos extra creado correctamente.</span>,
          onClose,
        });
        setMonitoreoPotrerosContext({
          refresh: !monitoreoPotrerosContext.refresh,
        });
        setAlimentosExtraContext({ refresh: !alimentosExtraContext.refresh });
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          rawBody: true,
          isOpen: true,
          title: "Error al crear los alimentos extra.",
          body: (
            <span>{err?.response?.data?.message || "Intenta nuevamente."}</span>
          ),
        });
      });
  };

  return informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            })
      }
    />
  ) : (
    <Modal size="md" isOpen={true}>
      <ModalHeader>Agregar Alimentos Extra</ModalHeader>
      <Form onSubmit={onCreateSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup row>
                <Col sm={5} className="text-left d-flex flex-column">
                  <div>
                    <span>Fecha</span>
                    <small className="ml-1 text-danger">*</small>
                  </div>
                  <span className="font-size-75 text-secondary">
                    selecciona o escribe una fecha
                  </span>
                </Col>
                <Col
                  sm={7}
                  className="d-flex align-items-center justify-content-between"
                >
                  <Datetime
                    renderInput={(props) => (
                      <input
                        {...props}
                        required={true}
                        placeholder="Selecciona o escribe una fecha"
                      />
                    )}
                    closeOnSelect={true}
                    locale="es"
                    timeFormat={false}
                    className="flex-grow-1"
                    utc={true}
                    value={fecha}
                    onChange={(fecha) => setFecha(moment(fecha))}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} className="text-left">
                  Ración <small>(Kg en Base fresca "Tal cual ofrecido")</small>
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={7}>
                  <Input
                    type="number"
                    placeholder="Escribe un valor.."
                    required={true}
                    max={999999}
                    min={0}
                    value={racion}
                    onChange={(evt) => setRacion(evt.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} className="text-left">
                  Ensilaje{" "}
                  <small>(Kg en Base fresca "Tal cual ofrecido")</small>
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={7}>
                  <Input
                    type="number"
                    placeholder="Escribe un valor.."
                    required={true}
                    max={999999}
                    min={0}
                    value={ensilaje}
                    onChange={(evt) => setEnsilaje(evt.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-0">
                <Label sm={5} className="text-left">
                  Fardo <small>(Kg en Base fresca "Tal cual ofrecido")</small>
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={7}>
                  <Input
                    type="number"
                    placeholder="Escribe un valor.."
                    required={true}
                    max={999999}
                    min={0}
                    value={fardo}
                    onChange={(evt) => setFardo(evt.target.value)}
                  />
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button
                color={"secondary"}
                onClick={() =>
                  setInformacionPotreroModalContext({
                    ...initialState,
                    potreros: informacionPotreroModalContext.potreros,
                    motivosMovimiento:
                      informacionPotreroModalContext.motivosMovimiento,
                    tiposMovimiento:
                      informacionPotreroModalContext.tiposMovimiento,
                    tipoUsosSuelo: informacionPotreroModalContext.tipoUsosSuelo,
                    categoriasAnimal:
                      informacionPotreroModalContext.categoriasAnimal,
                    isOpen: !informacionPotreroModalContext.id,
                  })
                }
              >
                {"Cancelar"}
              </Button>
              <Button color={"primary"} type="submit">
                {"Confirmar"}
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AlimentosExtraModal;
