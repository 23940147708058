import React, { useState, useEffect, useCallback } from "react";

import {
  UncontrolledTooltip,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  CustomInput,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import Loader from "../../../Loader";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import {
  initialState,
  useInformacionPotreroModal,
} from "../../../../providers/informacionPotreroModalProvider";
import { potrerosApi } from "../../../../services/potrerosServices";
import { utils } from "../../../../utils/utils";
import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import InformationModal from "../../../InformationModal";
import AnimalesList from "./AnimalesList";
import moment from "moment";
import { useMovimiento } from "../../../../providers/movimientosProvider";
import { useEstadosSituacion } from "../../../../providers/estadosSituacionProvider";
import { useMonitoreoPotreros } from "../../../../providers/monitoreoPotrerosProvider";

const TIPO_MOVIMIENTO_ENTRE_POTREROS = "1";

const MovimientoPotrerosModal = ({ onClose }) => {
  const [establecimientosContext] = useEstablecimientos();
  const [monitoreoPotrerosContext, setMonitoreoPotrerosContext] =
    useMonitoreoPotreros();
  const [informacionPotreroModalContext, setInformacionPotreroModalContext] =
    useInformacionPotreroModal();
  const [movimientosContext, setMovimientosContext] = useMovimiento();
  const [estadosSituacionContext, setEstadosSituacionContext] =
    useEstadosSituacion();

  const [loading, setLoading] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const setInformacionPotreroModalContextCb = useCallback(
    (data) => {
      setInformacionPotreroModalContext(data);
    },
    [setInformacionPotreroModalContext]
  );

  useEffect(() => {
    if (
      informacionPotreroModalContext.fecha &&
      informacionPotreroModalContext.potrero
    ) {
      setLoading(true);
      potrerosApi
        .getSituacionPotrero({
          establecimientoId: establecimientosContext.selected,
          fecha: utils.formatDate(informacionPotreroModalContext.fecha),
          potreroId: informacionPotreroModalContext.potrero,
        })
        .then((situacionPotreroExistente) => {
          if (
            !informacionPotreroModalContext.id &&
            situacionPotreroExistente?.length
          ) {
            return setInformationModal({
              rawBody: true,
              isOpen: true,
              title: "Crear Movimiento de Potrero",
              body: (
                <span>
                  Existe estado de situación para la fecha y potrero
                  seleccionado.
                </span>
              ),
              onClose: () => {
                setLoading(false);
                setInformacionPotreroModalContextCb({ fecha: null });
                setInformationModal({
                  rawBody: false,
                  isOpen: false,
                  title: "",
                  body: "",
                });
              },
            });
          }
          setLoading(false);
          const ultimaSituacionPotreroOrigen =
            informacionPotreroModalContext.potreros.find(
              (p) =>
                p.id === parseInt(informacionPotreroModalContext.potrero || 0)
            )?.ultimaSituacion;
          if (
            ultimaSituacionPotreroOrigen &&
            !informacionPotreroModalContext.tipoUsoSueloOrigen.id
          ) {
            return setInformacionPotreroModalContextCb({
              tipoUsoSueloOrigen: {
                id: ultimaSituacionPotreroOrigen.tipoUsoSueloId,
                disabled: false,
                message: "Obtenido del último estado de situación",
              },
              alturaPastoOrigen: ultimaSituacionPotreroOrigen.alturaPasto || "",
              efectivoPastoreoOrigen:
                ultimaSituacionPotreroOrigen.efectivoPastoreo,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Error",
            rawBody: true,
            body: (
              <span>
                ${err?.response?.data?.message || "Ha habido un error"}
              </span>
            ),
          });
        });
    }
  }, [
    informacionPotreroModalContext.id,
    informacionPotreroModalContext.potreros,
    establecimientosContext.selected,
    informacionPotreroModalContext.fecha,
    informacionPotreroModalContext.potrero,
    informacionPotreroModalContext.tipoUsoSueloOrigen.id,
    setInformacionPotreroModalContextCb,
  ]);

  useEffect(() => {
    if (
      informacionPotreroModalContext.fecha &&
      informacionPotreroModalContext.potreroDestino
    ) {
      setLoading(true);
      potrerosApi
        .getSituacionPotrero({
          establecimientoId: establecimientosContext.selected,
          fecha: utils.formatDate(informacionPotreroModalContext.fecha),
          potreroId: informacionPotreroModalContext.potreroDestino,
        })
        .then((situacionPotreroExistente) => {
          if (
            !informacionPotreroModalContext.id &&
            situacionPotreroExistente?.length
          ) {
            return setInformationModal({
              rawBody: true,
              isOpen: true,
              title: "Crear Movimiento de Potrero",
              body: (
                <span>
                  Existe estado de situación para la fecha y potrero destino
                  seleccionado.
                </span>
              ),
              onClose: () => {
                setLoading(false);
                setInformacionPotreroModalContextCb({ fecha: null });
                setInformationModal({
                  rawBody: false,
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                });
              },
            });
          }
          setLoading(false);
          const ultimaSituacionPotreroDestino =
            informacionPotreroModalContext.potreros.find(
              (p) =>
                p.id ===
                parseInt(informacionPotreroModalContext.potreroDestino || 0)
            )?.ultimaSituacion;
          if (
            ultimaSituacionPotreroDestino &&
            !informacionPotreroModalContext.tipoUsoSueloDestino.id
          ) {
            return setInformacionPotreroModalContextCb({
              tipoUsoSueloDestino: {
                id: ultimaSituacionPotreroDestino.tipoUsoSueloId,
                disabled: false,
                message: "Obtenido del último estado de situación",
              },
              alturaPastoDestino:
                ultimaSituacionPotreroDestino.alturaPasto || "",
              efectivoPastoreoDestino:
                ultimaSituacionPotreroDestino.efectivoPastoreo,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Error",
            rawBody: true,
            body: (
              <span>
                ${err?.response?.data?.message || "Ha habido un error"}
              </span>
            ),
          });
        });
    }
  }, [
    informacionPotreroModalContext.id,
    informacionPotreroModalContext.potreros,
    establecimientosContext.selected,
    informacionPotreroModalContext.fecha,
    informacionPotreroModalContext.potreroDestino,
    informacionPotreroModalContext.tipoUsoSueloDestino.id,
    setInformacionPotreroModalContextCb,
  ]);

  useEffect(() => {
    if (
      informacionPotreroModalContext.fecha &&
      informacionPotreroModalContext.potrero &&
      informacionPotreroModalContext.tipoMovimiento &&
      (parseInt(informacionPotreroModalContext.tipoMovimiento) ===
      parseInt(TIPO_MOVIMIENTO_ENTRE_POTREROS)
        ? informacionPotreroModalContext.potDestinoId
        : true)
    ) {
      setLoading(true);
      potrerosApi
        .getMovimientoPotrero({
          establecimientoId: establecimientosContext.selected,
          fecha: utils.formatDate(informacionPotreroModalContext.fecha),
          tipoMovId: informacionPotreroModalContext.tipoMovimiento,
          potreroId: informacionPotreroModalContext.potrero,
          potDestinoId:
            parseInt(informacionPotreroModalContext.tipoMovimiento) ===
            parseInt(TIPO_MOVIMIENTO_ENTRE_POTREROS)
              ? informacionPotreroModalContext.potDestinoId
              : null,
        })
        .then((movimientoPotreroExistente) => {
          setLoading(false);
          if (
            !informacionPotreroModalContext.id &&
            movimientoPotreroExistente?.length
          ) {
            return setInformationModal({
              rawBody: true,
              isOpen: true,
              title: "Crear Movimiento de Potrero",
              body: (
                <span>
                  Existe movimiento para la fecha, tipo y potrero(s)
                  seleccionados.
                </span>
              ),
              onClose: () => {
                setLoading(false);
                setInformacionPotreroModalContextCb({ fecha: null });
                setInformationModal({
                  rawBody: false,
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                });
              },
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Error",
            rawBody: true,
            body: (
              <span>
                ${err?.response?.data?.message || "Ha habido un error"}
              </span>
            ),
          });
        });
    }
  }, [
    establecimientosContext.selected,
    informacionPotreroModalContext.fecha,
    informacionPotreroModalContext.potrero,
    informacionPotreroModalContext.tipoMovimiento,
    informacionPotreroModalContext.id,
    informacionPotreroModalContext.potDestinoId,
    setInformacionPotreroModalContextCb,
  ]);

  const onCreateSubmit = (event) => {
    event.preventDefault();
    if (!informacionPotreroModalContext.animales.length) {
      return setInformationModal({
        rawBody: true,
        isOpen: true,
        title: "Crear Movimiento de Potrero",
        body: <span>Debes agregar animales.</span>,
        onClose: null,
      });
    }
    setLoading(true);
    potrerosApi
      .createMovimientoPotrero({
        fecha: moment(informacionPotreroModalContext.fecha).format(
          "YYYY-MM-DD"
        ),
        establecimientoId: establecimientosContext.selected,
        tipoMovId: informacionPotreroModalContext.tipoMovimiento,
        potreroId: informacionPotreroModalContext.potrero,
        potDestinoId: informacionPotreroModalContext.potreroDestino,
        calidadPasto: informacionPotreroModalContext.calidadPasto,
        alturaPastoOrigen: informacionPotreroModalContext.alturaPastoOrigen,
        alturaPastoDestino: informacionPotreroModalContext.alturaPastoDestino,
        efectivoPastoreoOrigen:
          informacionPotreroModalContext.efectivoPastoreoOrigen,
        efectivoPastoreoDestino:
          informacionPotreroModalContext.efectivoPastoreoDestino,
        tipoUsoSueloOrigen:
          informacionPotreroModalContext.tipoUsoSueloOrigen.id,
        tipoUsoSueloDestino:
          informacionPotreroModalContext.tipoUsoSueloDestino.id,
        motivoMovimientoId: informacionPotreroModalContext.motivoMovimiento,
        animales: informacionPotreroModalContext.animales,
      })
      .then(() => {
        setLoading(false);
        setInformationModal({
          rawBody: true,
          isOpen: true,
          title: "Crear Movimiento de Potrero",
          body: <span>Movimiento de potrero creado correctamente.</span>,
          onClose,
        });
        setMonitoreoPotrerosContext({
          refresh: !monitoreoPotrerosContext.refresh,
        });
        setMovimientosContext({ refresh: !movimientosContext.refresh });
        setEstadosSituacionContext({
          refresh: !estadosSituacionContext.refresh,
        });
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          rawBody: true,
          isOpen: true,
          title: "Error al crear el movimiento del potrero",
          body: <span>{err?.response?.data?.message}</span>,
          onClose: null,
        });
      });
  };

  const onUpdateSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    potrerosApi
      .updateMovimientoPotrero({
        id: informacionPotreroModalContext.id,
        establecimientoId: establecimientosContext.selected,
        potreroId: informacionPotreroModalContext.potrero,
        potDestinoId: informacionPotreroModalContext.potreroDestino,
        calidadPasto: informacionPotreroModalContext.calidadPasto,
        alturaPastoOrigen: informacionPotreroModalContext.alturaPastoOrigen,
        alturaPastoDestino: informacionPotreroModalContext.alturaPastoDestino,
        efectivoPastoreoOrigen:
          informacionPotreroModalContext.efectivoPastoreoOrigen,
        efectivoPastoreoDestino:
          informacionPotreroModalContext.efectivoPastoreoDestino,
        tipoUsoSueloOrigen:
          informacionPotreroModalContext.tipoUsoSueloOrigen.id,
        tipoUsoSueloDestino:
          informacionPotreroModalContext.tipoUsoSueloDestino.id,
        motivoMovimientoId: informacionPotreroModalContext.motivoMovimiento,
        tipoMovId: informacionPotreroModalContext.tipoMovimiento,
      })
      .then(() => {
        setLoading(false);
        setInformationModal({
          rawBody: true,
          isOpen: true,
          title: "Editar Movimiento de Potrero",
          body: <span>Movimiento de potrero actualizado correctamente.</span>,
          onClose,
        });
        setMonitoreoPotrerosContext({
          refresh: !monitoreoPotrerosContext.refresh,
        });
        setMovimientosContext({ refresh: !movimientosContext.refresh });
        setEstadosSituacionContext({
          refresh: !estadosSituacionContext.refresh,
        });
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          rawBody: true,
          isOpen: true,
          title: "Error al actualizar el movimiento del potrero",
          body: <span>{err?.response?.data?.message}</span>,
          onClose: null,
        });
      });
  };

  const ultimaSituacionPotreroDestino =
    informacionPotreroModalContext.potreros.find(
      (p) =>
        p.id === parseInt(informacionPotreroModalContext.potreroDestino || 0)
    )?.ultimaSituacion;

  return informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal size="md" isOpen={true}>
      <ModalHeader>{`${
        informacionPotreroModalContext.id ? "" : "Agregar "
      }Movimiento de Potrero`}</ModalHeader>
      <Form
        onSubmit={
          informacionPotreroModalContext.id ? onUpdateSubmit : onCreateSubmit
        }
      >
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup row>
                <Col sm={4} className="text-left d-flex flex-column">
                  <div>
                    <span>Fecha</span>
                    <small className="ml-1 text-danger">*</small>
                  </div>
                  <span className="font-size-75 text-secondary">
                    selecciona o escribe una fecha
                  </span>
                </Col>
                <Col
                  sm={8}
                  className="d-flex align-items-center justify-content-between"
                >
                  <Datetime
                    renderInput={(props) => (
                      <input
                        {...props}
                        disabled={informacionPotreroModalContext.id}
                        required={true}
                        placeholder="Selecciona o escribe una fecha"
                      />
                    )}
                    closeOnSelect={true}
                    locale="es"
                    timeFormat={false}
                    className="flex-grow-1"
                    utc={true}
                    value={moment(informacionPotreroModalContext.fecha)}
                    onChange={(fecha) =>
                      setInformacionPotreroModalContext({ fecha })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-left">
                  Tipo de Movimiento
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={8}>
                  <CustomInput
                    disabled={informacionPotreroModalContext.id}
                    required={true}
                    id="tipoMovimientoSelect"
                    className="text-truncate tipoMovimientoSelect"
                    type="select"
                    name="tipoMovimientoSelect"
                    onChange={(event) =>
                      setInformacionPotreroModalContext({
                        tipoMovimiento: event.currentTarget.value,
                      })
                    }
                    value={informacionPotreroModalContext.tipoMovimiento || ""}
                  >
                    <option value="">Selecciona un tipo de movimiento</option>
                    {(informacionPotreroModalContext.tiposMovimiento || []).map(
                      (tipoMovimiento, index) => (
                        <option key={index} value={tipoMovimiento.id}>
                          {tipoMovimiento.nombre}
                        </option>
                      )
                    )}
                  </CustomInput>
                </Col>
              </FormGroup>
              {parseInt(informacionPotreroModalContext.tipoMovimiento) ===
              parseInt(TIPO_MOVIMIENTO_ENTRE_POTREROS) ? (
                <FormGroup row className="mt-3">
                  <Label sm={4} className="text-left">
                    Motivo del Movimiento
                    <small className="ml-1 text-danger">*</small>
                  </Label>
                  <Col sm={8}>
                    <Row className="mx-0 flex-nowrap align-items-center">
                      <CustomInput
                        required={true}
                        id="motivoMovimientoSelect"
                        className="text-truncate motivoMovimientoSelect"
                        type="select"
                        name="motivoMovimientoSelect"
                        onChange={(event) =>
                          setInformacionPotreroModalContext({
                            motivoMovimiento: event.currentTarget.value,
                          })
                        }
                        value={
                          informacionPotreroModalContext.motivoMovimiento || ""
                        }
                      >
                        <option value="">
                          Selecciona el motivo del movimiento
                        </option>
                        {(
                          informacionPotreroModalContext.motivosMovimiento || []
                        ).map((motivoMovimiento, index) => (
                          <option key={index} value={motivoMovimiento.id}>
                            {motivoMovimiento.nombre}
                          </option>
                        ))}
                      </CustomInput>
                    </Row>
                  </Col>
                </FormGroup>
              ) : null}
              {informacionPotreroModalContext.tipoMovimiento ? (
                <>
                  <ListGroup
                    flush
                    className="col-12 p-0 text-center border rounded"
                  >
                    <ListGroupItem
                      className="text-underline py-2 border-bottom font-weight-bold text-body list-group-item bg-lighter"
                      tag="div"
                    >
                      Potrero{" "}
                      {parseInt(
                        informacionPotreroModalContext.tipoMovimiento
                      ) === parseInt(TIPO_MOVIMIENTO_ENTRE_POTREROS)
                        ? "Origen"
                        : ""}
                    </ListGroupItem>
                    <ListGroupItem
                      className="mt-2 p-2 d-flex justify-content-between bg-white border-0"
                      tag="div"
                    >
                      <FormGroup row className="mb-0 col-12">
                        <Label sm={4} className="text-left">
                          Nombre
                          <small className="ml-1 text-danger">*</small>
                        </Label>
                        <Col sm={8}>
                          <CustomInput
                            disabled={informacionPotreroModalContext.id}
                            required={true}
                            id="potreroSelect"
                            className="text-truncate potreroSelect"
                            type="select"
                            name="potreroSelect"
                            onChange={(event) =>
                              setInformacionPotreroModalContext({
                                potrero: event.currentTarget.value,
                              })
                            }
                            value={informacionPotreroModalContext.potrero || ""}
                          >
                            <option value="">
                              Selecciona un potrero{" "}
                              {parseInt(
                                informacionPotreroModalContext.tipoMovimiento
                              ) === parseInt(TIPO_MOVIMIENTO_ENTRE_POTREROS)
                                ? "origen"
                                : ""}
                            </option>
                            {(informacionPotreroModalContext.potreros || [])
                              .filter(
                                (p) =>
                                  p.id !==
                                  parseInt(
                                    informacionPotreroModalContext.potreroDestino
                                  )
                              )
                              .map((potrero, index) => (
                                <option key={index} value={potrero.id}>
                                  {potrero.nombre}
                                </option>
                              ))}
                          </CustomInput>
                        </Col>
                      </FormGroup>
                    </ListGroupItem>
                    {informacionPotreroModalContext.fecha &&
                    informacionPotreroModalContext.potrero ? (
                      <>
                        <ListGroupItem
                          className="p-2 d-flex justify-content-between bg-white border-0"
                          tag="div"
                        >
                          <FormGroup row className="mb-0 col-12">
                            <Col
                              sm={4}
                              className="text-left d-flex flex-column"
                            >
                              <div>
                                <span>Uso de Suelo</span>
                                <small className="ml-1 text-danger">*</small>
                              </div>
                              <span className="font-size-75 text-secondary">
                                en la fecha seleccionada
                              </span>
                            </Col>
                            <Col sm={8} className="d-flex align-items-center">
                              <Row className="flex-grow-1 mx-0 flex-nowrap align-items-center">
                                <CustomInput
                                  disabled={
                                    informacionPotreroModalContext
                                      .tipoUsoSueloOrigen?.disabled
                                  }
                                  required={true}
                                  id="tipoUsoSueloSelectOrigen"
                                  className="text-truncate tipoUsoSueloSelectOrigen"
                                  type="select"
                                  name="tipoUsoSueloSelectOrigen"
                                  onChange={(event) =>
                                    setInformacionPotreroModalContext({
                                      tipoUsoSueloOrigen: {
                                        id: parseInt(event.currentTarget.value),
                                        disabled: false,
                                      },
                                    })
                                  }
                                  value={
                                    informacionPotreroModalContext
                                      .tipoUsoSueloOrigen.id || ""
                                  }
                                >
                                  <option value="">
                                    Selecciona un uso de suelo
                                  </option>
                                  {(
                                    informacionPotreroModalContext.tipoUsosSuelo ||
                                    []
                                  ).map((tipoUsoSuelo, index) => (
                                    <option key={index} value={tipoUsoSuelo.id}>
                                      {tipoUsoSuelo.nombre}
                                    </option>
                                  ))}
                                </CustomInput>
                                {informacionPotreroModalContext
                                  .tipoUsoSueloOrigen?.message ? (
                                  <div>
                                    <FontAwesomeIcon
                                      id={"tipoUsoInfo"}
                                      icon={faInfoCircle}
                                      fixedWidth
                                      className="ml-2 text-info cursor-pointer"
                                    />
                                    <UncontrolledTooltip
                                      placement={"bottom"}
                                      target={"tipoUsoInfo"}
                                    >
                                      {
                                        informacionPotreroModalContext
                                          .tipoUsoSueloOrigen?.message
                                      }
                                    </UncontrolledTooltip>
                                  </div>
                                ) : null}
                              </Row>
                            </Col>
                          </FormGroup>
                        </ListGroupItem>
                        <ListGroupItem
                          className="p-2 d-flex justify-content-between bg-white border-0"
                          tag="div"
                        >
                          <FormGroup row className="mb-0 col-12">
                            <Col
                              sm={4}
                              className="text-left d-flex flex-column"
                            >
                              <div>
                                <span>Altura del Pasto</span>
                                <small className="ml-1 text-danger">*</small>
                              </div>
                              <span className="font-size-75 text-secondary">
                                en Cm.
                              </span>
                            </Col>
                            <Col sm={8} className="d-flex align-items-center">
                              <Row className="flex-grow-1 mx-0 flex-nowrap align-items-center">
                                <Input
                                  required={true}
                                  type="number"
                                  max="100"
                                  min="1"
                                  step="0.1"
                                  lang="es"
                                  placeholder="Ingresa la altura del pasto.."
                                  onChange={(event) =>
                                    setInformacionPotreroModalContext({
                                      alturaPastoOrigen:
                                        event.currentTarget.value,
                                    })
                                  }
                                  value={
                                    informacionPotreroModalContext.alturaPastoOrigen ||
                                    ""
                                  }
                                />
                              </Row>
                            </Col>
                          </FormGroup>
                        </ListGroupItem>
                        <ListGroupItem
                          className="p-2 d-flex justify-content-between bg-white border-0"
                          tag="div"
                        >
                          <FormGroup row className="mb-0 col-12">
                            <Col
                              sm={4}
                              className="text-left d-flex flex-column"
                            >
                              <div>
                                <span>Efectivo para Pastoreo</span>
                                <small className="ml-1 text-danger">*</small>
                              </div>
                              <span className="font-size-75 text-secondary">
                                %
                              </span>
                            </Col>
                            <Col sm={8} className="d-flex align-items-center">
                              <Row className="flex-grow-1 mx-0 flex-nowrap align-items-center">
                                <Input
                                  required={true}
                                  type="number"
                                  max="100"
                                  min="0"
                                  step="0.1"
                                  lang="es"
                                  placeholder="Ingresa un porcentaje.."
                                  onChange={(event) =>
                                    setInformacionPotreroModalContext({
                                      efectivoPastoreoOrigen:
                                        event.currentTarget.value,
                                    })
                                  }
                                  value={
                                    informacionPotreroModalContext.efectivoPastoreo
                                  }
                                />
                              </Row>
                            </Col>
                          </FormGroup>
                        </ListGroupItem>
                        {parseInt(
                          informacionPotreroModalContext.tipoMovimiento
                        ) !== parseInt(TIPO_MOVIMIENTO_ENTRE_POTREROS) ? (
                          <ListGroupItem
                            className="p-2 d-flex justify-content-between bg-white border-0"
                            tag="div"
                          >
                            <FormGroup row className="mb-0 col-12">
                              <Label sm={4} className="text-left">
                                Calidad del Pasto
                                <small className="ml-1 text-danger">*</small>
                              </Label>
                              <Col sm={8}>
                                <Row className="mx-0 flex-nowrap align-items-center">
                                  <CustomInput
                                    required={true}
                                    id="calidadPastoSelectOrigen"
                                    className="text-truncate calidadPastoSelectOrigen"
                                    type="select"
                                    name="calidadPastoSelectOrigen"
                                    onChange={(event) =>
                                      setInformacionPotreroModalContext({
                                        calidadPasto: event.currentTarget.value,
                                      })
                                    }
                                    value={
                                      informacionPotreroModalContext.calidadPasto ||
                                      ""
                                    }
                                  >
                                    <option value="">
                                      Selecciona la calidad del pasto
                                    </option>
                                    <option value="Excelente">Excelente</option>
                                    <option value="Buena">Buena</option>
                                    <option value="Mala">Mala</option>
                                  </CustomInput>
                                </Row>
                              </Col>
                            </FormGroup>
                          </ListGroupItem>
                        ) : null}
                      </>
                    ) : null}
                  </ListGroup>
                  {parseInt(informacionPotreroModalContext.tipoMovimiento) ===
                  parseInt(TIPO_MOVIMIENTO_ENTRE_POTREROS) ? (
                    <ListGroup
                      flush
                      className="col-12 p-0 text-center border rounded mt-3"
                    >
                      <ListGroupItem
                        className="text-underline py-2 border-bottom font-weight-bold text-body list-group-item bg-lighter"
                        tag="div"
                      >
                        Potrero Destino
                      </ListGroupItem>
                      <ListGroupItem
                        className="mt-2 p-2 d-flex justify-content-between bg-white border-0"
                        tag="div"
                      >
                        <FormGroup row className="mb-0 col-12">
                          <Label sm={4} className="text-left">
                            Nombre
                            <small className="ml-1 text-danger">*</small>
                          </Label>
                          <Col sm={8}>
                            <CustomInput
                              disabled={informacionPotreroModalContext.id}
                              required={true}
                              id="potreroDestinoSelect"
                              className="text-truncate potreroDestinoSelect"
                              type="select"
                              name="potreroDestinoSelect"
                              onChange={(event) =>
                                setInformacionPotreroModalContext({
                                  potreroDestino: event.currentTarget.value,
                                })
                              }
                              value={
                                informacionPotreroModalContext.potreroDestino ||
                                ""
                              }
                            >
                              <option value="">
                                Selecciona un potrero destino
                              </option>
                              {(informacionPotreroModalContext.potreros || [])
                                .filter(
                                  (p) =>
                                    p.id !==
                                    parseInt(
                                      informacionPotreroModalContext.potrero
                                    )
                                )
                                .map((potrero, index) => (
                                  <option key={index} value={potrero.id}>
                                    {potrero.nombre}
                                  </option>
                                ))}
                            </CustomInput>
                          </Col>
                        </FormGroup>
                      </ListGroupItem>
                      {informacionPotreroModalContext.fecha &&
                      informacionPotreroModalContext.potreroDestino ? (
                        <>
                          <ListGroupItem
                            className="p-2 d-flex justify-content-between bg-white border-0"
                            tag="div"
                          >
                            <FormGroup row className="mb-0 col-12">
                              <Col
                                sm={4}
                                className="text-left d-flex flex-column"
                              >
                                <div>
                                  <span>Uso de Suelo</span>
                                  <small className="ml-1 text-danger">*</small>
                                </div>
                                <span className="font-size-75 text-secondary">
                                  en la fecha seleccionada
                                </span>
                              </Col>
                              <Col sm={8} className="d-flex align-items-center">
                                <Row className="flex-grow-1 mx-0 flex-nowrap align-items-center">
                                  <CustomInput
                                    disabled={
                                      informacionPotreroModalContext
                                        .tipoUsoSueloDestino?.disabled
                                    }
                                    required={true}
                                    id="tipoUsoSueloSelect"
                                    className="text-truncate tipoUsoSueloSelect"
                                    type="select"
                                    name="tipoUsoSueloSelect"
                                    onChange={(event) =>
                                      setInformacionPotreroModalContext({
                                        tipoUsoSueloDestino: {
                                          id: parseInt(
                                            event.currentTarget.value
                                          ),
                                          disabled: false,
                                        },
                                      })
                                    }
                                    value={
                                      informacionPotreroModalContext
                                        .tipoUsoSueloDestino.id ||
                                      ultimaSituacionPotreroDestino?.tipoUsoSueloId ||
                                      ""
                                    }
                                  >
                                    <option value="">
                                      Selecciona un uso de suelo
                                    </option>
                                    {(
                                      informacionPotreroModalContext.tipoUsosSuelo ||
                                      []
                                    ).map((tipoUsoSuelo, index) => (
                                      <option
                                        key={index}
                                        value={tipoUsoSuelo.id}
                                      >
                                        {tipoUsoSuelo.nombre}
                                      </option>
                                    ))}
                                  </CustomInput>
                                  {informacionPotreroModalContext
                                    .tipoUsoSueloDestino?.message ? (
                                    <div>
                                      <FontAwesomeIcon
                                        id={"tipoUsoInfo"}
                                        icon={faInfoCircle}
                                        fixedWidth
                                        className="ml-2 text-info cursor-pointer"
                                      />
                                      <UncontrolledTooltip
                                        placement={"bottom"}
                                        target={"tipoUsoInfo"}
                                      >
                                        {
                                          informacionPotreroModalContext
                                            .tipoUsoSueloDestino?.message
                                        }
                                      </UncontrolledTooltip>
                                    </div>
                                  ) : null}
                                </Row>
                              </Col>
                            </FormGroup>
                          </ListGroupItem>
                          <ListGroupItem
                            className="p-2 d-flex justify-content-between bg-white border-0"
                            tag="div"
                          >
                            <FormGroup row className="mb-0 col-12">
                              <Col
                                sm={4}
                                className="text-left d-flex flex-column"
                              >
                                <div>
                                  <span>Altura del Pasto</span>
                                  <small className="ml-1 text-danger">*</small>
                                </div>
                                <span className="font-size-75 text-secondary">
                                  en Cm.
                                </span>
                              </Col>
                              <Col sm={8} className="d-flex align-items-center">
                                <Row className="flex-grow-1 mx-0 flex-nowrap align-items-center">
                                  <Input
                                    required={true}
                                    type="number"
                                    max="100"
                                    min="1"
                                    step="0.1"
                                    lang="es"
                                    placeholder="Ingresa la altura del pasto.."
                                    onChange={(event) =>
                                      setInformacionPotreroModalContext({
                                        alturaPastoDestino:
                                          event.currentTarget.value,
                                      })
                                    }
                                    value={
                                      informacionPotreroModalContext.alturaPastoDestino ||
                                      ""
                                    }
                                  />
                                </Row>
                              </Col>
                            </FormGroup>
                          </ListGroupItem>
                          <ListGroupItem
                            className="p-2 d-flex justify-content-between bg-white border-0"
                            tag="div"
                          >
                            <FormGroup row className="mb-0 col-12">
                              <Col
                                sm={4}
                                className="text-left d-flex flex-column"
                              >
                                <div>
                                  <span>Efectivo para Pastoreo</span>
                                  <small className="ml-1 text-danger">*</small>
                                </div>
                                <span className="font-size-75 text-secondary">
                                  %
                                </span>
                              </Col>
                              <Col sm={8} className="d-flex align-items-center">
                                <Row className="flex-grow-1 mx-0 flex-nowrap align-items-center">
                                  <Input
                                    required={true}
                                    type="number"
                                    max="100"
                                    min="0"
                                    step="0.1"
                                    lang="es"
                                    placeholder="Ingresa un porcentaje.."
                                    onChange={(event) =>
                                      setInformacionPotreroModalContext({
                                        efectivoPastoreoDestino:
                                          event.currentTarget.value,
                                      })
                                    }
                                    value={
                                      informacionPotreroModalContext.efectivoPastoreo
                                    }
                                  />
                                </Row>
                              </Col>
                            </FormGroup>
                          </ListGroupItem>
                          <ListGroupItem
                            className="p-2 d-flex justify-content-between bg-white border-0"
                            tag="div"
                          >
                            <FormGroup row className="mb-0 col-12">
                              <Label sm={4} className="text-left">
                                Calidad del Pasto
                                <small className="ml-1 text-danger">*</small>
                              </Label>
                              <Col sm={8}>
                                <Row className="mx-0 flex-nowrap align-items-center">
                                  <CustomInput
                                    required={true}
                                    id="calidadPastoSelect"
                                    className="text-truncate calidadPastoSelect"
                                    type="select"
                                    name="calidadPastoSelect"
                                    onChange={(event) =>
                                      setInformacionPotreroModalContext({
                                        calidadPasto: event.currentTarget.value,
                                      })
                                    }
                                    value={
                                      informacionPotreroModalContext.calidadPasto ||
                                      ""
                                    }
                                  >
                                    <option value="">
                                      Selecciona la calidad del pasto
                                    </option>
                                    <option value="Excelente">Excelente</option>
                                    <option value="Buena">Buena</option>
                                    <option value="Mala">Mala</option>
                                  </CustomInput>
                                </Row>
                              </Col>
                            </FormGroup>
                          </ListGroupItem>
                        </>
                      ) : null}
                    </ListGroup>
                  ) : null}
                </>
              ) : null}
              <FormGroup className="col px-0 text-right mb-0 mt-3">
                <div className="border rounded">
                  <AnimalesList />
                </div>
                <Button
                  hidden={informacionPotreroModalContext.id}
                  className="mt-2 rounded"
                  size="sm"
                  onClick={() =>
                    setInformacionPotreroModalContext({
                      animalesSelector: true,
                    })
                  }
                >
                  Agregar Animales
                </Button>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button
                color={"secondary"}
                onClick={() =>
                  setInformacionPotreroModalContext({
                    ...initialState,
                    potreros: informacionPotreroModalContext.potreros,
                    motivosMovimiento:
                      informacionPotreroModalContext.motivosMovimiento,
                    tiposMovimiento:
                      informacionPotreroModalContext.tiposMovimiento,
                    tipoUsosSuelo: informacionPotreroModalContext.tipoUsosSuelo,
                    categoriasAnimal:
                      informacionPotreroModalContext.categoriasAnimal,
                    isOpen: !informacionPotreroModalContext.id,
                  })
                }
              >
                {`${informacionPotreroModalContext.id ? "Cerrar" : "Volver"}`}
              </Button>
              <Button color={"primary"} type="submit">
                {informacionPotreroModalContext.id ? "Guardar" : "Confirmar"}
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default MovimientoPotrerosModal;
