import React, { useState } from "react";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
} from "reactstrap";

import { useAuth } from "../../../providers/authProvider";
import { authApi } from "../../../services/authServices";
import Loader from "../../../components/Loader";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import { usePotreros } from "../../../providers/potrerosProvider";

export const LoginForm = () => {
  const [authContext, setAuthContext] = useAuth();
  const [, setEstablecimientosContext] = useEstablecimientos();
  const [, setPotrerosContext] = usePotreros();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onDismiss = () => setError(false);

  const doLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    authApi
      .login({
        cedula: authContext.credentials.cedula,
        password: authContext.credentials.pwd,
      })
      .then((result) => {
        setLoading(false);
        if (!result) {
          return null;
        }
        setAuthContext({ credentials: null, currentUser: result });
        setEstablecimientosContext({
          allEstablecimientos: null,
          establecimientos: {},
          selected: result.defaultEstablecimiento,
        });
        setPotrerosContext({ potreros: {} });
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data && typeof err?.response?.data === "string") {
          setError(err?.response?.data);
        } else {
          setError("Ha habido un error, intenta nuevamente.");
        }
      });
    return false;
  };

  return (
    <React.Fragment>
      <div className="flex-grow-1 align-items-start d-flex flex-column justify-content-center">
        <h2>Bienvenido a iPasto</h2>
        <span>Plataforma para la gestión del pasto</span>
      </div>
      <Card style={{ boxShadow: "none" }} className="flex-grow-1">
        <CardBody className="px-0">
          <div>
            <Form onSubmit={doLogin}>
              <FormGroup>
                <Label>
                  <b>Documento</b>
                </Label>
                <Input
                  maxLength="50"
                  required={true}
                  bsSize="lg"
                  name="cedula"
                  className="mb-1"
                  placeholder="Ingresa tu cédula"
                  onChange={(event) =>
                    setAuthContext({
                      credentials: {
                        ...authContext.credentials,
                        cedula: event.currentTarget.value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label className="d-flex justify-content-between">
                  Contraseña
                </Label>
                <Input
                  required={true}
                  maxLength="50"
                  bsSize="lg"
                  type="password"
                  name="password"
                  placeholder="Ingresa tu contraseña"
                  onChange={(event) =>
                    setAuthContext({
                      credentials: {
                        ...authContext.credentials,
                        pwd: event.currentTarget.value,
                      },
                    })
                  }
                />
                <div className="mt-lg-1 mt-2 d-flex justify-content-between col-12 px-0 flex-wrap">
                  <small className="text-center text-lg-left col-12 col-lg-5 px-0">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.planagropecuario.org.uy/web/resetPassword/create.html"
                    >
                      ¿Olvidaste tu contraseña?
                    </a>
                  </small>
                  <small className="text-center text-lg-right col-12 col-lg-7 px-0">
                    ¿Aún no eres productor asociado?{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.planagropecuario.org.uy/web/registro/productor-asociado.html"
                    >
                      Regístrate!
                    </a>
                  </small>
                </div>
              </FormGroup>
              <UncontrolledAlert
                isOpen={!!error}
                toggle={onDismiss}
                color="warning"
              >
                <div className="alert-icon">
                  <FontAwesomeIcon icon={faBell} fixedWidth />
                </div>
                <div className="alert-message text-left">
                  <span>{error}</span>
                </div>
              </UncontrolledAlert>
              <div className="d-flex justify-content-center align-items-center mt-3">
                {loading ? (
                  <Loader size="sm" />
                ) : (
                  <Button color="primary" type="submit" size="lg">
                    Ingresar
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
