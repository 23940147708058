import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sortBy: "id",
  direction: "asc",
  sizePerPage: 10,
  page: 1,
  search: "",
};

const MovimientoContext = createContext(initialState);

const MovimientoProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <MovimientoContext.Provider value={stateAndDispatch}>
      {children}
    </MovimientoContext.Provider>
  );
};

export const useMovimiento = () => useContext(MovimientoContext);

export { MovimientoContext, MovimientoProvider };
