import axios from "axios";
import config from "../config/config";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}&reason=1`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const establecimientosApi = {
  getEstablecimientos: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      data.id
        ? `/api/establecimientos/${data.id}`
        : `/api/establecimientos?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  selectEstablecimiento: (data) => {
    return axios(`/api/establecimientos/${data.id}/select`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createEstablecimiento: (data) => {
    return axios(`/api/establecimientos`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateEstablecimiento: (data) => {
    return axios(`/api/establecimientos/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteEstablecimiento: (data) => {
    return axios(`/api/establecimientos/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getSeguimientoForrajeroTcMensualTotal: (id, data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/establecimientos/${id}/seguimiento-forrajero-tc-mensual-total?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getIndicadoresSeguimientoForrajero: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/establecimientos/${data.id}/indicadores-seguimiento-forrajero?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getIndicadoresEstablecimiento: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/establecimientos/${data.id}/indicadores-establecimiento?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getCargaPosibleRealEstablecimiento: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/establecimientos/${data.id}/carga-posible-real?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getIndicadoresPotreros: (establecimientoId, data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/establecimientos/${establecimientoId}/indicadores-potreros?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getSituacionesPotreros: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/establecimientos/${data.establecimientoId}/situaciones?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getCrecimientosPotreros: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/establecimientos/${data.establecimientoId}/crecimientos?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getMovimientosPotreros: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/establecimientos/${data.establecimientoId}/movimientos?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getUsosPotreros: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/establecimientos/${data.establecimientoId}/usos-potreros?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createAlimentosExtra: (data) => {
    return axios(
      `/api/establecimientos/${data.establecimientoId}/alimentos-extra`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "post",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getAlimentosExtra: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/establecimientos/${data.establecimientoId}/alimentos-extra?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteAlimentosExtra: (data) => {
    return axios(
      `/api/establecimientos/${data.establecimientoId}/alimentos-extra/${data.id}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "delete",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  importarPotreros: (data) => {
    const form_data = new FormData();
    form_data.append("socketToken", data.socketToken);
    form_data.append("kmlData", JSON.stringify(data.kmlData));
    return axios(
      `/api/establecimientos/${data.establecimientoId}/importar-potreros`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "post",
        data: form_data,
      },
      { timeout: 300000 }
    ) //timout 5 minutes
      .then((result) => result.data)
      .catch((err) => err);
  },
};
