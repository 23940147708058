import React, { useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  InputGroup,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import Loader from "../../Loader";
import { RefreshCw } from "react-feather";
import Pagination from "react-js-pagination";
import UsoPotreroCard from "../potreros/UsosPotreros/UsoPotreroCard";
import { useUsosPotreros } from "../../../providers/usosPotrerosProvider";
import InformationModal from "../../InformationModal";
import Button from "reactstrap/lib/Button";

const UsosPotrerosCardsView = ({ usosPotreros, loading }) => {
  const [usosPotrerosContext, setUsosPotrerosContext] = useUsosPotreros();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSeeMore = (mode) => {
    if (mode === "DEFAULT") {
      setInformationModal({
        isOpen: true,
        title: "Potreros sin usos para el establecimiento",
        rawBody: true,
        body: (
          <ListGroup className="small">
            {usosPotreros.sinUsos.map((potrero) => (
              <ListGroupItem
                key={potrero.id}
                className="d-flex align-items-center"
              >
                {potrero.nombre}
              </ListGroupItem>
            ))}
          </ListGroup>
        ),
      });
    } else {
      setInformationModal({
        isOpen: true,
        title: "Potreros con periodos sin uso",
        rawBody: true,
        body: (
          <ul class="mb-0">
            {usosPotreros.withHoles.map((potrero) => (
              <li>{potrero}</li>
            ))}
          </ul>
        ),
      });
    }
  };

  return (
    <div className="d-md-flex d-lg-none">
      <Col className="px-0">
        <Card className="col-12 px-0">
          <CardBody className="py-2">
            <div className="card-actions d-flex align-items-center justify-content-between">
              <InputGroup className="mr-3 col-4 px-0" size="m">
                <Input
                  maxLength="50"
                  value={usosPotrerosContext.search}
                  onChange={(evt) =>
                    setUsosPotrerosContext({
                      search: evt.target.value,
                      page: 1,
                    })
                  }
                  className="border-0"
                  placeholder="Busca por potrero.."
                />
              </InputGroup>
              <span className="mr-1 d-flex">
                <span className="cursor-pointer mx-2 d-flex align-items-center">
                  <RefreshCw
                    hidden
                    onClick={() =>
                      setUsosPotrerosContext({
                        refresh: !usosPotrerosContext.refresh,
                      })
                    }
                  />
                </span>
                <small className="text-truncate text-muted d-flex align-items-center">
                  Mostrando {usosPotreros?.count || 0} establecimientos
                </small>
              </span>
            </div>
          </CardBody>
        </Card>
        {usosPotreros.sinUsos?.length ? (
          <div className="pt-2 mt-2 justify-content-center text-warning d-flex align-items-center">
            <small>Existen potreros sin uso en el establecimiento.</small>
            <Button
              color="warning"
              size="sm"
              className="ml-4 rounded"
              onClick={() => onSeeMore("DEFAULT")}
            >
              Ver más
            </Button>
          </div>
        ) : usosPotreros.withHoles?.length ? (
          <Card className="col-12 px-0">
            <CardBody className="py-2">
              <div className="justify-content-center text-warning d-flex align-items-center">
                <small>
                  Existen periodos sin uso para algunos potreros, para estos
                  periodos el valor de materia seca es considerado 0 por ha. y
                  esto afecta los resultados.
                </small>
                <Button
                  color="warning"
                  size="sm"
                  className="ml-4 rounded"
                  onClick={onSeeMore}
                >
                  Ver más
                </Button>
              </div>
            </CardBody>
          </Card>
        ) : null}
        {loading ? (
          <Loader />
        ) : !usosPotreros.count ? (
          <Card className="col-12">
            <CardHeader>
              <CardTitle tag="h5" className="text-center my-1">
                No hay resultados
              </CardTitle>
            </CardHeader>
          </Card>
        ) : (
          <Col className="flex-wrap px-0">
            <Row>
              {(usosPotreros.data || []).map((usoPotrero, index) => (
                <UsoPotreroCard
                  key={index}
                  usoPotrero={usoPotrero}
                ></UsoPotreroCard>
              ))}
            </Row>
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={usosPotrerosContext.page}
              itemsCountPerPage={usosPotrerosContext.sizePerPage}
              totalItemsCount={usosPotreros?.count || 0}
              pageRangeDisplayed={5}
              onChange={(page) => setUsosPotrerosContext({ page })}
            />
          </Col>
        )}
      </Col>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </div>
  );
};

export default UsosPotrerosCardsView;
