import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Label,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  FormText,
} from "reactstrap";
import Loader from "../Loader";
import InformationModal from "../InformationModal";
import { tipoUsoSuelosApi } from "../../services/tipoUsoSueloServices";

const CreateTipoUsoSueloModal = ({ onSubmit, onClose }) => {
  const [loading, setLoading] = useState();
  const [nombre, setNombre] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onCreate = (event) => {
    event.preventDefault();
    setLoading(true);
    tipoUsoSuelosApi
      .createTipoUsoSuelos({ nombre })
      .then(() => {
        setLoading(false);
        onSubmit();
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.data[0]?.msg) {
          setInformationModal({
            isOpen: true,
            title: "Error",
            rawBody: true,
            body: <span>{err.response?.data[0]?.msg}</span>,
          });
        }
      });
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal size="md" isOpen={true} onClosed={onClose}>
      <ModalHeader>Agregar Tipo de Uso de Suelo</ModalHeader>
      <Form onSubmit={onCreate}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <Col>
              <FormGroup row className="mb-0">
                <Label sm={4} className="text-left">
                  <span>
                    Nombre<small className="ml-1 text-danger">*</small>
                  </span>
                  <FormText className="text-muted text-left">
                    El nombre debe ser único
                  </FormText>
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    type="text"
                    className="mb-1 text-truncate"
                    onChange={(event) => setNombre(event.currentTarget.value)}
                  />
                </Col>
              </FormGroup>
            </Col>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Descartar
              </Button>
              <Button type="submit" color={"success"}>
                Confirmar
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateTipoUsoSueloModal;
