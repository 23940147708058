import React from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";

const InformationModal = ({
  onClose,
  title,
  body,
  rawBody = false,
  size = "md",
  closeButtonText = "Cerrar",
}) => {
  return (
    <Modal isOpen={true} onClosed={onClose} size={size}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {rawBody ? (
          body
        ) : (
          <div dangerouslySetInnerHTML={{ __html: body }}></div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Button color={"primary"} onClick={onClose}>
          {closeButtonText}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default InformationModal;
