import React, { useEffect, useState } from "react";

import {
  Container,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  Input,
  Button,
  InputGroupText,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { RefreshCw } from "react-feather";
import Loader from "../../components/Loader";
import InformationModal from "../../components/InformationModal";

import { tipoUsoSuelosApi } from "../../services/tipoUsoSueloServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import CreateTipoUsoSueloModal from "../../components/admin/CreateTipoUsoSueloModal";
import ConfirmationModal from "../../components/ConfirmationModal";

const TipoUsosSuelo = () => {
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [refresh, setRefresh] = useState();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [createModal, setCreateModal] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  useEffect(() => {
    setLoading(true);
    tipoUsoSuelosApi
      .getTipoUsoSuelos({
        search,
        sortBy: "nombre",
        page: page - 1,
        pageSize: sizePerPage,
      })
      .then((tipoUsosSuelo) => {
        setData(tipoUsosSuelo);
        setLoading(false);
      });
  }, [search, refresh, page, sizePerPage]);

  const onDelete = (tipoUso) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        setLoading(true);
        tipoUsoSuelosApi
          .deleteTipoUsoSuelos({
            id: tipoUso.id,
          })
          .then(() => {
            setConfirmationModal(initConfirmationModal);
            setRefresh(!refresh);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            setConfirmationModal(initConfirmationModal);
            if (err.response?.data[0]?.msg) {
              setInformationModal({
                isOpen: true,
                title: "Error",
                rawBody: true,
                body: <span>{err.response?.data[0]?.msg}</span>,
              });
            }
          });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: `Borrar tipo de uso de suelo`,
      body: `¿Confirmas que quieres borrar el tipo de uso de suelo ${tipoUso.nombre}?`,
    });
  };

  const onCreate = () => setCreateModal(true);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Card className="d-flex">
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Tipos de Suelo</h3>
            <small className="text-muted ml-2 pt-1">({data?.count})</small>
          </div>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup size="m" className="mr-3">
              <Input
                className="rounded-left rounded-right-0"
                maxLength="50"
                placeholder="Buscar.."
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(1);
                }}
              />
              <InputGroupText className="rounded-right rounded-left-0 search-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="mr-3 rounded-circle d-flex custom-rounded-button-plus"
              color="primary"
              onClick={onCreate}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh"
              color="primary"
              onClick={() => setRefresh(!refresh)}
            >
              <RefreshCw />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader size="sm" />
          ) : data?.count ? (
            <>
              <BootstrapTable
                bootstrap4
                remote
                bordered={false}
                keyField="id"
                data={data.data}
                columns={[
                  {
                    dataField: "nombre",
                    text: "Nombre",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    formatter: (value, row) => value || "-",
                  },
                  {
                    dataField: "id",
                    text: "",
                    classes: "text-right",
                    headerClasses: "text-truncate text-muted font-size-75",
                    formatter: (value, row) => (
                      <Button
                        size="sm"
                        color="danger"
                        type="button"
                        className="rounded view-selector font-weight-bold"
                        onClick={() => onDelete(row)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    ),
                  },
                ]}
                defaultSorted={[{ dataField: "nombre", order: "asc" }]}
                onTableChange={() => {}}
                pagination={paginationFactory({
                  page,
                  totalSize: data.count,
                  sizePerPage,
                  sizePerPageList: [
                    5,
                    10,
                    25,
                    50,
                    { text: "Todos", value: data.count },
                  ],
                  onPageChange: (page) => setPage(page),
                  onSizePerPageChange: (sizePerPage) =>
                    setSizePerPage(sizePerPage),
                })}
              />
            </>
          ) : (
            <div className="text-center">No hay resultados</div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {createModal ? (
        <CreateTipoUsoSueloModal
          onSubmit={() => {
            setCreateModal();
            setRefresh(!refresh);
          }}
          onClose={() => setCreateModal()}
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default TipoUsosSuelo;
