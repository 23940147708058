import React, { useState } from "react";

import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
  InputGroup,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MoreVertical, RefreshCw } from "react-feather";
import Loader from "../../Loader";
import { useUsosPotreros } from "../../../providers/usosPotrerosProvider";
import ConfirmationModal from "../../ConfirmationModal";
import { potrerosApi } from "../../../services/potrerosServices";
import UsoPotreroModal from "../potreros/UsosPotreros/UsoPotreroModal";
import { utils } from "../../../utils/utils";
import UsosPotrerosCardsView from "./UsosPotrerosCardsView";
import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import Button from "reactstrap/lib/Button";
import InformationModal from "../../InformationModal";
import { useAuth } from "../../../providers/authProvider";

const UsosTableView = ({ usosPotreros, defaultLoading }) => {
  const [authContext] = useAuth();
  const [establecimientosContext] = useEstablecimientos();
  const [usosPotrerosContext, setUsosPotrerosContext] = useUsosPotreros();

  const [entriesLoading, setEntriesLoading] = useState({});
  const [usoPotreroModal, setUsoPotreroModal] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
    confirmText: null,
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onSort = (sortBy, direction) => {
    if (
      usosPotrerosContext.sortBy === sortBy &&
      usosPotrerosContext.direction === direction
    ) {
      return;
    }
    setUsosPotrerosContext({ sortBy, direction, page: 1 });
  };

  const onConfirmDelete = (usosPotrero) => {
    setEntriesLoading({ [usosPotrero.id]: true });
    potrerosApi
      .deleteUsoPotrero({
        id: usosPotrero.id,
        potreroId: usosPotrero.potreroId,
        establecimientoId: establecimientosContext.selected,
      })
      .then(() => {
        setEntriesLoading({ [usosPotrero.id]: false });
        setConfirmationModal(initConfirmationModal);
        setUsosPotrerosContext({ refresh: !usosPotrerosContext.refresh });
      });
  };

  const onDelete = (usosPotrero) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => onConfirmDelete(usosPotrero),
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Borrar uso de potrero`,
      body: `Confirmas que quieres borrar el uso del potrero seleccionado?`,
    });
  };

  const actions = (usosPotrero, loading) => {
    return loading[usosPotrero.id] ? (
      <Loader size="sm" align="end" />
    ) : authContext.currentUser.ingresoDatosHabilitado ? (
      <UncontrolledDropdown className="d-inline-block">
        <DropdownToggle tag="a">
          <MoreVertical className="text-primary" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => setUsoPotreroModal(usosPotrero)}>
            Editar
          </DropdownItem>
          <DropdownItem onClick={() => onDelete(usosPotrero)}>
            Borrar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    ) : null;
  };

  const onSeeMore = (mode) => {
    if (mode === "DEFAULT") {
      setInformationModal({
        isOpen: true,
        title: "Potreros sin usos para el establecimiento",
        rawBody: true,
        body: (
          <ListGroup className="small">
            {usosPotreros.sinUsos.map((potrero) => (
              <ListGroupItem
                key={potrero.id}
                className="d-flex align-items-center"
              >
                {potrero.nombre}
              </ListGroupItem>
            ))}
          </ListGroup>
        ),
      });
    } else {
      setInformationModal({
        isOpen: true,
        title: "Potreros con periodos sin uso",
        rawBody: true,
        body: (
          <ul class="mb-0">
            {usosPotreros.withHoles.map((potrero) => (
              <li>{potrero}</li>
            ))}
          </ul>
        ),
      });
    }
  };

  return (
    <>
      <Card className="d-none d-lg-flex">
        <CardHeader>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup className="mr-3 col-4 px-0" size="m">
              <Input
                maxLength="50"
                value={usosPotrerosContext.search}
                onChange={(evt) =>
                  setUsosPotrerosContext({ search: evt.target.value, page: 1 })
                }
                className="border-0"
                placeholder="Busca por potrero.."
              />
            </InputGroup>
            <span className="mr-1 d-flex">
              <span className="cursor-pointer mx-2 d-flex align-items-center">
                <RefreshCw
                  hidden
                  onClick={() =>
                    setUsosPotrerosContext({
                      refresh: !usosPotrerosContext.refresh,
                    })
                  }
                />
              </span>
              <small className="text-muted d-flex align-items-center">
                Mostrando {usosPotreros?.count || 0} usos
              </small>
            </span>
          </div>
          {usosPotreros.sinUsos?.length ? (
            <div className="pt-2 mt-2 justify-content-center text-warning d-flex align-items-center">
              <small>Existen potreros sin uso en el establecimiento.</small>
              <Button
                color="warning"
                size="sm"
                className="ml-4 rounded"
                onClick={() => onSeeMore("DEFAULT")}
              >
                Ver más
              </Button>
            </div>
          ) : usosPotreros.withHoles?.length ? (
            <div className="pt-2 mt-2 justify-content-center text-warning d-flex align-items-center">
              <small>
                Existen periodos sin uso para algunos potreros, para estos
                periodos el valor de materia seca es considerado 0 por ha. y
                esto afecta los resultados.
              </small>
              <Button
                color="warning"
                size="sm"
                className="ml-4 rounded"
                onClick={() => onSeeMore("PERIODOS")}
              >
                Ver más
              </Button>
            </div>
          ) : null}
        </CardHeader>
        <CardBody>
          {defaultLoading ? (
            <Loader size="sm" />
          ) : usosPotreros.count ? (
            <>
              <BootstrapTable
                bootstrap4
                remote
                bordered={false}
                keyField="id"
                data={usosPotreros.data}
                columns={[
                  {
                    dataField: "potrero.nombre",
                    text: "Potrero",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    sort: true,
                    onSort,
                  },
                  {
                    dataField: "fechaComienzo",
                    text: "Fecha Comienzo",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    sort: true,
                    formatter: (cell, usosPotrero) =>
                      utils.formatDate(usosPotrero.fechaComienzo, "DD/MM/YYYY"),
                    onSort,
                  },
                  {
                    dataField: "fechaFin",
                    text: "Fecha Fin",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    sort: true,
                    formatter: (cell, usosPotrero) =>
                      usosPotrero.fechaFin
                        ? utils.formatDate(usosPotrero.fechaFin, "DD/MM/YYYY")
                        : "Actualidad",
                    onSort,
                  },
                  {
                    dataField: "tipoUsoSuelo.nombre",
                    text: "Uso",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                  },
                  {
                    dataField: "",
                    text: "",
                    sort: false,
                    classes: "text-right",
                    formatter: (cell, usosPotrero) =>
                      actions(usosPotrero, entriesLoading),
                    formatExtraData: { ...entriesLoading },
                  },
                ]}
                defaultSorted={[
                  {
                    dataField: usosPotrerosContext.sortBy,
                    order: usosPotrerosContext.direction,
                  },
                ]}
                onTableChange={() => {}}
                pagination={paginationFactory({
                  page: usosPotrerosContext.page,
                  totalSize: usosPotreros.count,
                  sizePerPage: usosPotrerosContext.sizePerPage,
                  sizePerPageList: [
                    5,
                    10,
                    25,
                    50,
                    { text: "Todos", value: usosPotreros.count },
                  ],
                  onPageChange: (page) => setUsosPotrerosContext({ page }),
                  onSizePerPageChange: (sizePerPage) =>
                    setUsosPotrerosContext({ page: 1, sizePerPage }),
                })}
              />
            </>
          ) : (
            <div className="text-center">No hay resultados</div>
          )}
          {confirmationModal.isOpen ? (
            <ConfirmationModal {...confirmationModal} />
          ) : null}
        </CardBody>
      </Card>
      <UsosPotrerosCardsView
        usosPotreros={usosPotreros}
        loading={defaultLoading}
        onDelete={onDelete}
        onEdit={(usosPotrero) => setUsoPotreroModal(usosPotrero)}
      />
      {usoPotreroModal ? (
        <UsoPotreroModal
          selectedUsoPotrero={usoPotreroModal}
          onClose={() => setUsoPotreroModal(false)}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          size={informationModal.size}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

export default UsosTableView;
