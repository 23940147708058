import React, { useEffect, useState } from "react";

import {
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  Row,
  CustomInput,
} from "reactstrap";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import { potrerosApi } from "../../../services/potrerosServices";
import { establecimientosApi } from "../../../services/establecimientosServices";
import Loader from "../../Loader";
import InformationModal from "../../InformationModal";
import IndicadoresCards from "./IndicadoresCards";
import TCMensualTotalChart from "./TCMensualTotalChart";
import { useUsosPotreros } from "../../../providers/usosPotrerosProvider";
import { tipoUsoSuelosApi } from "../../../services/tipoUsoSueloServices";

const MAX_PAGE_SIZE = 999;

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const IndicadoresView = () => {
  const [establecimientosContext] = useEstablecimientos();
  const [usosPotrerosContext, setUsosPotrerosContext] = useUsosPotreros();

  const [loading, setLoading] = useState();
  const [usosSueloLoading, setUsosSueloLoading] = useState();
  const [potrerosLoading, setPotrerosLoading] = useState();

  const [potreros, setPotreros] = useState([]);
  const [tipoUsosSuelo, setTipoUsosSuelo] = useState([]);

  const [indicadores, setIndicadores] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    if ((usosPotrerosContext.tipoUsoSuelo || []).length) {
      setPotrerosLoading(true);
      potrerosApi
        .getPotreros(establecimientosContext.selected, {
          page: 0,
          pageSize: 999,
          sortBy: "nombre",
          tipoUsoSuelo: (usosPotrerosContext.tipoUsoSuelo || []).toString(),
        })
        .then((result) => {
          setPotrerosLoading(false);
          setPotreros(result.data);
          setIndicadores();
        });
    }
  }, [establecimientosContext.selected, usosPotrerosContext.tipoUsoSuelo]);

  useEffect(() => {
    setUsosSueloLoading(true);
    tipoUsoSuelosApi
      .getTipoUsoSuelos({
        pageSize: MAX_PAGE_SIZE,
        establecimientoId: establecimientosContext.selected,
      })
      .then((result) => {
        setUsosSueloLoading(false);
        setTipoUsosSuelo(result.data?.filter((tu) => tu.enabledSF));
      });
  }, [establecimientosContext.selected]);

  useEffect(() => {
    if (
      (usosPotrerosContext.tipoUsoSuelo || []).length &&
      (usosPotrerosContext.potrero || []).length
    ) {
      setLoading(true);
      establecimientosApi
        .getIndicadoresSeguimientoForrajero({
          id: establecimientosContext.selected,
          potreroId: (usosPotrerosContext.potrero || []).toString(),
          tipoUsoSueloId: (usosPotrerosContext.tipoUsoSuelo || []).toString(),
        })
        .then((result) => {
          setIndicadores(result);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [
    establecimientosContext.selected,
    usosPotrerosContext.potrero,
    usosPotrerosContext.tipoUsoSuelo,
  ]);

  return (
    <>
      <Row className="card-actions d-flex justify-content-between">
        <div className="col-6 d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="pb-0">
              <CardTitle
                tag="h5"
                className="mb-0 d-flex align-items-center justify-content-between"
              >
                <span>Usos de Suelo</span>
                <CustomInput
                  type="checkbox"
                  id="todosUsos"
                  label="Todos"
                  checked={
                    (usosPotrerosContext.tipoUsoSuelo || []).length ===
                    (tipoUsosSuelo || []).length
                  }
                  onChange={(event) => {
                    event.target.checked
                      ? setUsosPotrerosContext({
                          tipoUsoSuelo: (tipoUsosSuelo || []).map((t) => t.id),
                          potrero: null,
                        })
                      : setUsosPotrerosContext({
                          tipoUsoSuelo: [],
                          potrero: null,
                        });
                  }}
                  className="cursor-pointer d-flex align-items-center font-weight-bold text-primary"
                />
              </CardTitle>
            </CardHeader>
            <CardBody>
              {usosSueloLoading ? (
                <Loader size="sm" />
              ) : (
                <div className="d-flex flex-wrap justify-content-between pb-2">
                  <Select
                    placeholder={
                      <span className="text-muted">
                        Selecciona los tipos de uso
                      </span>
                    }
                    noOptionsMessage={() => "No hay más tipos de uso de suelo"}
                    styles={style}
                    className="col-12 px-0 border rounded my-2"
                    options={(tipoUsosSuelo || []).map((tipoUsoSuelo) => {
                      return {
                        label: tipoUsoSuelo.nombre,
                        value: tipoUsoSuelo.id,
                      };
                    })}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    value={(usosPotrerosContext.tipoUsoSuelo || []).map(
                      (tipoUsoSuelo) => {
                        return {
                          label:
                            (tipoUsosSuelo || []).find(
                              (usoSuelo) => usoSuelo.id === tipoUsoSuelo
                            )?.nombre || "-",
                          value: tipoUsoSuelo,
                        };
                      }
                    )}
                    isMulti
                    onChange={(selected) =>
                      setUsosPotrerosContext({
                        tipoUsoSuelo: (selected || []).map((t) => t.value),
                        potrero: null,
                      })
                    }
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </div>
        <div className="col-6 d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="pb-0">
              <CardTitle
                tag="h5"
                className="mb-0 d-flex align-items-center justify-content-between"
              >
                <span>Potreros</span>
                <CustomInput
                  type="checkbox"
                  id="potreros"
                  label="Todos"
                  checked={
                    (potreros || []).length &&
                    (usosPotrerosContext.potrero || []).length ===
                      (potreros || []).length
                  }
                  onChange={(event) => {
                    event.target.checked
                      ? setUsosPotrerosContext({
                          potrero: (potreros || []).map((t) => t.id),
                        })
                      : setUsosPotrerosContext({
                          potrero: [],
                        });
                  }}
                  className="cursor-pointer d-flex align-items-center font-weight-bold text-primary"
                />
              </CardTitle>
            </CardHeader>
            <CardBody>
              {potrerosLoading ? (
                <Loader size="sm" />
              ) : !(usosPotrerosContext.tipoUsoSuelo || []).length ? (
                <span>
                  Selecciona un tipo de uso de suelo para ver los potreros
                  disponibles
                </span>
              ) : !(potreros || []).length ? (
                <span>
                  No hay potreros con los tipos de uso de suelo seleccionados
                </span>
              ) : (
                <div className="d-flex flex-wrap justify-content-between pb-2">
                  <Select
                    placeholder={
                      <span className="text-muted">
                        Selecciona los potreros
                      </span>
                    }
                    noOptionsMessage={() => "No hay más potreros"}
                    styles={style}
                    className="col-12 px-0 border rounded my-2"
                    options={(potreros || []).map((potrero) => {
                      return { label: potrero.nombre, value: potrero.id };
                    })}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    value={(usosPotrerosContext.potrero || []).map(
                      (potrero) => {
                        return {
                          label:
                            (potreros || []).find((pot) => pot.id === potrero)
                              ?.nombre || "-",
                          value: potrero,
                        };
                      }
                    )}
                    isMulti
                    onChange={(selected) =>
                      setUsosPotrerosContext({
                        potrero: (selected || []).map((t) => t.value),
                      })
                    }
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </Row>
      <IndicadoresCards indicadores={indicadores} loading={loading} />
      <Card className="d-flex flex-column flex-grow-1">
        <CardBody
          className="d-flex flex-column flex-grow-1"
          style={{ minHeight: 600 }}
        >
          <TCMensualTotalChart
            isPublic={false}
            potreros={usosPotrerosContext.potrero}
            tipoUsoSuelos={usosPotrerosContext.tipoUsoSuelo}
          />
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

export default IndicadoresView;
