import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  InputGroup,
  Col,
  Row,
} from "reactstrap";

import Loader from "../../../components/Loader";
import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import EstablecimientosCard from "./EstablecimientoCard";
import { RefreshCw } from "react-feather";
import Pagination from "react-js-pagination";

const EstablecimientosCardsView = ({
  loading,
  establecimientosLoading,
  onDelete,
  onEdit,
}) => {
  const [establecimientosContext, setEstablecimientosContext] =
    useEstablecimientos();

  return (
    <div className="d-md-flex d-lg-none">
      <Col className="px-0">
        <Card className="col-12 px-0">
          <CardBody className="py-2">
            <div className="card-actions d-flex align-items-center justify-content-between">
              <InputGroup className="mr-3 col-4 px-0" size="m">
                <Input
                  maxLength="50"
                  value={establecimientosContext.search}
                  onChange={(evt) =>
                    setEstablecimientosContext({
                      search: evt.target.value,
                      page: 1,
                    })
                  }
                  className="border-0"
                  placeholder="Buscar.."
                />
              </InputGroup>
              <span className="mr-1 d-flex">
                <span className="cursor-pointer mx-2 d-flex align-items-center">
                  <RefreshCw
                    hidden
                    onClick={() =>
                      setEstablecimientosContext({
                        refresh: !establecimientosContext.refresh,
                      })
                    }
                  />
                </span>
                <small className="text-truncate text-muted d-flex align-items-center">
                  Mostrando{" "}
                  {establecimientosContext.establecimientos?.count || 0}{" "}
                  establecimientos
                </small>
              </span>
            </div>
          </CardBody>
        </Card>
        {loading ? (
          <Loader />
        ) : !establecimientosContext.establecimientos.count ? (
          <Card className="col-12">
            <CardHeader>
              <CardTitle tag="h5" className="text-center my-1">
                No hay resultados
              </CardTitle>
            </CardHeader>
          </Card>
        ) : (
          <Col className="flex-wrap px-0">
            <Row>
              {(establecimientosContext.establecimientos.data || []).map(
                (establecimiento, index) => (
                  <EstablecimientosCard
                    key={index}
                    establecimiento={establecimiento}
                    establecimientosLoading={establecimientosLoading}
                    onDelete={onDelete}
                    onEdit={onEdit}
                  ></EstablecimientosCard>
                )
              )}
            </Row>
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={establecimientosContext.page}
              itemsCountPerPage={establecimientosContext.sizePerPage}
              totalItemsCount={
                establecimientosContext.establecimientos?.count || 0
              }
              pageRangeDisplayed={5}
              onChange={(page) => setEstablecimientosContext({ page })}
            />
          </Col>
        )}
      </Col>
    </div>
  );
};

export default EstablecimientosCardsView;
