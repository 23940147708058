import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sizePerPage: 10,
  page: 1,
  search: "",
  sortBy: "nombre",
  direction: "asc",
  establecimientos: {},
  selected: null,
  allEstablecimientos: null,
};

const EstablecimientosContext = createContext(initialState);

const EstablecimientosProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <EstablecimientosContext.Provider value={stateAndDispatch}>
      {children}
    </EstablecimientosContext.Provider>
  );
};

export const useEstablecimientos = () => useContext(EstablecimientosContext);

export { EstablecimientosContext, EstablecimientosProvider };
