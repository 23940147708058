const api_dev = "https://api.dev.ipasto.planagro.uy/";
const api_prod = "https://api.ipasto.planagro.uy/";
const api_local = "http://localhost:3000/";
let apiURL;

if (process.env.REACT_APP_ENV === "production") {
  apiURL = api_prod;
} else if (process.env.REACT_APP_ENV === "development") {
  apiURL = api_dev;
} else {
  apiURL = api_local;
}

const config = { apiURL };

export default config;
