import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import Loader from "../Loader";
import InformationModal from "../InformationModal";
import CustomCheckbox from "../CustomCheckbox";
import { usuariosApi } from "../../services/usuariosServices";

const CreateUsuarioModal = ({ onSubmit, onClose }) => {
  const [cedula, setCedula] = useState();
  const [loading, setLoading] = useState();
  const [enabledSF, setEnabledSF] = useState(false);
  const [enabledMonitoreo, setEnabledMonitoreo] = useState(false);
  const [ingresoDatosHabilitado, setIngresoDatosHabilitado] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onCreate = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await usuariosApi.createUsuario({
        cedula,
        enabledMonitoreo,
        enabledSF,
        ingresoDatosHabilitado,
      });
      onSubmit();
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Error",
        rawBody: true,
        body: <span>{err.response?.data[0].msg}</span>,
      });
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal size="sm" isOpen={true} onClosed={onClose}>
      <ModalHeader>Agregar Configuración de Módulos para Usuario</ModalHeader>
      <Form onSubmit={onCreate}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <div className="d-flex flex-column">
              <FormGroup row className="mb-0">
                <Label sm={4} className="text-left">
                  <span>
                    Cédula
                    <small className="ml-1 text-danger">*</small>
                  </span>
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    type="text"
                    className="mb-1 text-truncate"
                    value={cedula || ""}
                    onChange={(event) => setCedula(event.currentTarget.value)}
                  />
                </Col>
              </FormGroup>
              <ListGroup className="mt-3">
                <ListGroupItem
                  className="d-flex justify-content-center align-items-center bg-lighter py-1 font-weight-bold"
                  tag="div"
                >
                  Módulos Habilitados
                </ListGroupItem>
                <ListGroupItem
                  className="d-flex flex-column justify-content-center align-items-center py-1"
                  tag="div"
                >
                  <FormGroup row className="mb-0 py-2">
                    <Col sm={12}>
                      <CustomCheckbox
                        label="Ingreso Información"
                        checked={ingresoDatosHabilitado}
                        onClick={() =>
                          setIngresoDatosHabilitado(!ingresoDatosHabilitado)
                        }
                      />
                    </Col>
                  </FormGroup>
                </ListGroupItem>
                <ListGroupItem
                  className="d-flex flex-column justify-content-center align-items-center py-1"
                  tag="div"
                >
                  <FormGroup row className="mb-0 py-2">
                    <Col sm={12}>
                      <CustomCheckbox
                        label="Seguimiento Forrajero"
                        checked={enabledSF}
                        onClick={() => setEnabledSF(!enabledSF)}
                      />
                    </Col>
                  </FormGroup>
                </ListGroupItem>
                <ListGroupItem
                  className="d-flex flex-column justify-content-center align-items-center py-1"
                  tag="div"
                >
                  <FormGroup row className="mb-0 py-2">
                    <Col sm={12}>
                      <CustomCheckbox
                        label="Monitoreo de Potreros"
                        checked={enabledMonitoreo}
                        onClick={() => setEnabledMonitoreo(!enabledMonitoreo)}
                      />
                    </Col>
                  </FormGroup>
                </ListGroupItem>
              </ListGroup>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cerrar
              </Button>
              <Button type="submit" color={"success"}>
                Guardar
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateUsuarioModal;
