import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { Container, Button, Card, CardHeader } from "reactstrap";

import CustomInput from "reactstrap/lib/CustomInput";
import UsoPotreroModal from "../../components/customer/potreros/UsosPotreros/UsoPotreroModal";

import IndicadoresView from "../../components/customer/seguimientoForrajero/IndicadoresView";
import UsosTableView from "../../components/customer/seguimientoForrajero/UsosTableView";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import InformationModal from "../../components/InformationModal";
import { useEstablecimientos } from "../../providers/establecimientosProvider";
import { useUsosPotreros } from "../../providers/usosPotrerosProvider";
import { establecimientosApi } from "../../services/establecimientosServices";
import XLSX from "xlsx";
import Loader from "../../components/Loader";
import { utils } from "../../utils/utils";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../providers/authProvider";

const USOS_MODE = 1;
const DATA_MODE = 2;
const ESTADO_ABIERTO = "1";

const SeguimientoForrajero = () => {
  const [authContext] = useAuth();
  const [establecimientosContext] = useEstablecimientos();
  const [usosPotrerosContext] = useUsosPotreros();
  const [usoPotreroModal, setUsoPotreroModal] = useState(false);

  const [viewMode, setViewMode] = useState(DATA_MODE);
  const [loading, setLoading] = useState();
  const [downloadInformationLoading, setDownloadInformationLoading] =
    useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [usosPotreros, setUsosPotreros] = useState([]);

  useEffect(() => {
    if (establecimientosContext.selected) {
      setLoading(true);
      establecimientosApi
        .getUsosPotreros({
          establecimientoId: establecimientosContext.selected,
          search: usosPotrerosContext.search,
          page: usosPotrerosContext.page - 1,
          pageSize: usosPotrerosContext.sizePerPage,
          sortBy: usosPotrerosContext.sortBy,
          direction: usosPotrerosContext.direction,
        })
        .then((usosPotreros) => {
          setUsosPotreros(usosPotreros);
          setLoading(false);
        });
    }
  }, [
    usosPotrerosContext.refresh,
    usosPotrerosContext.search,
    usosPotrerosContext.page,
    usosPotrerosContext.sizePerPage,
    usosPotrerosContext.sortBy,
    usosPotrerosContext.direction,
    establecimientosContext.selected,
  ]);

  const onCreate = () => {
    if (!establecimientosContext.selected) {
      return setInformationModal({
        isOpen: true,
        title: "Uso de Potreros",
        body: `
          <p>No hay ningún establecimiento seleccionado.</p>
          <span>Selecciona un establecimiento desde la barra lateral para modificar el uso de sus potreros.</span>`,
      });
    }
    const selected = establecimientosContext.allEstablecimientos.find(
      (e) => e.id === establecimientosContext.selected
    );
    if (selected.estado !== ESTADO_ABIERTO) {
      return setInformationModal({
        isOpen: true,
        title: "Importar Potreros",
        body: `
        <p>El establecimiento esta en estado cerrado, por lo que no se puede agregar información.</p>
        <span>Edita el estado del establecimiento desde la sección de establecimientos para realizar cambios.</span>
      `,
      });
    }
    setUsoPotreroModal(true);
  };

  const onDownloadInformation = async () => {
    setDownloadInformationLoading(true);
    const crecimientosPotrero =
      await establecimientosApi.getCrecimientosPotreros({
        establecimientoId: establecimientosContext.selected,
      });
    const worksheet = XLSX.utils.aoa_to_sheet([
      ["potrero", "tipoUsoSuelo", "anio", "mes", "ppna__kg_ha_dia"],
      ...crecimientosPotrero.map((crecimientoPotrero) =>
        Object.values(crecimientoPotrero)
      ),
    ]);
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Base de Datos");
    XLSX.writeFile(
      new_workbook,
      `db-iPasto-${utils.formatDate(null, "DD-MM-YYYY")}-${
        establecimientosContext.selected
      }.xlsx`
    );
    setDownloadInformationLoading(false);
  };

  const selectedEstablecimiento = () =>
    (establecimientosContext.allEstablecimientos || []).find(
      (e) => e.id === establecimientosContext.selected
    )?.nombre || "";

  return authContext.currentUser?.enabledSF ? (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Header className="mb-3">
        <HeaderTitle className="d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-column col-12 px-0 col-lg-4 align-items-start">
            <span className="col-12 px-0 text-dark">
              Seguimiento Forrajero {selectedEstablecimiento()}
            </span>
            <small className="text-muted font-weight-normal font-size-75">
              {viewMode === USOS_MODE ? "Usos de Potreros" : "Indicadores"}
            </small>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-2 mt-lg-0 col-12 col-lg-8 px-0">
            <Button
              type="button"
              size="sm"
              className="rounded view-selector btn-light font-weight-bold text-primary d-flex"
            >
              <CustomInput
                type="checkbox"
                id="viewModeSeguimientoIndicadores"
                label="Ver Indicadores"
                checked={viewMode === DATA_MODE}
                onChange={(event) =>
                  setViewMode(event.target.checked ? DATA_MODE : USOS_MODE)
                }
                className="cursor-pointer d-flex align-items-center font-weight-bold text-primary"
              />
              <CustomInput
                type="checkbox"
                id="viewModeSeguimientoListado"
                label="Ver Listado de Usos"
                checked={viewMode === USOS_MODE}
                onChange={(event) =>
                  setViewMode(event.target.checked ? USOS_MODE : DATA_MODE)
                }
                className="ml-4 cursor-pointer d-flex align-items-center font-weight-bold text-primary"
              />
            </Button>
            {authContext.currentUser.ingresoDatosHabilitado ? (
              <Button
                size="sm"
                color="warning"
                className="rounded ml-2 btn-success font-weight-bold text-white text-uppercase py-1"
                onClick={onCreate}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Nuevo Uso de Potrero
              </Button>
            ) : null}
            {downloadInformationLoading ? (
              <div className="min-width-50">
                <Loader size="sm" />
              </div>
            ) : (
              <Button
                size="sm"
                color="warning"
                className="rounded ml-2 btn-success font-weight-bold text-white text-uppercase py-1"
                onClick={onDownloadInformation}
              >
                <FontAwesomeIcon icon={faDownload} className="mr-2" />
                Descargar Información
              </Button>
            )}
          </div>
        </HeaderTitle>
      </Header>
      {!establecimientosContext.selected ? (
        <Card className="col-12">
          <CardHeader className="text-center">
            Ningún establecimiento seleccionado
          </CardHeader>
        </Card>
      ) : (
        <div className="d-flex flex-column flex-grow-1">
          {viewMode === USOS_MODE ? (
            <UsosTableView loading={loading} usosPotreros={usosPotreros} />
          ) : (
            <IndicadoresView />
          )}
        </div>
      )}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {usoPotreroModal ? (
        <UsoPotreroModal onClose={() => setUsoPotreroModal(false)} />
      ) : null}
    </Container>
  ) : (
    <Redirect to={`/error/404`} />
  );
};

export default SeguimientoForrajero;
