import React from "react";

import {
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";

import { MoreVertical } from "react-feather";
import Loader from "../../Loader";
import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSidebar } from "../../../providers/navbarProvider";
import { usePotreros } from "../../../providers/potrerosProvider";
import { establecimientosApi } from "../../../services/establecimientosServices";
import { useAuth } from "../../../providers/authProvider";

const ESTADO_ABIERTO = 1;

const EstablecimientoCard = ({
  establecimiento,
  establecimientosLoading,
  onDelete,
  onEdit,
}) => {
  const [authContext, setAuthContext] = useAuth();
  const [establecimientosContext, setEstablecimientosContext] =
    useEstablecimientos();

  const [, setSidebarContext] = useSidebar();
  const [, setPotrerosContext] = usePotreros();

  const onSelectedChange = (establecimientoId) => {
    setSidebarContext({ open: false });
    setAuthContext({
      currentUser: {
        ...authContext.currentUser,
        defaultEstablecimiento: establecimientoId,
      },
    });
    setEstablecimientosContext({ selected: establecimientoId });
    if (establecimientoId) {
      establecimientosApi.selectEstablecimiento({ id: establecimientoId });
    }
    setPotrerosContext({ potreros: {} });
  };

  const MoreOptions = () => {
    return establecimientosLoading[establecimiento.id] ? (
      <Loader size="sm" align="end" />
    ) : authContext.currentUser.ingresoDatosHabilitado ? (
      <UncontrolledDropdown className="d-inline-block">
        <DropdownToggle tag="a">
          <MoreVertical className="text-primary" />
        </DropdownToggle>
        <DropdownMenu right>
          {establecimientosContext.selected !== establecimiento.id ? (
            <DropdownItem onClick={() => onSelectedChange(establecimiento.id)}>
              Seleccionar
            </DropdownItem>
          ) : null}
          <DropdownItem onClick={() => onEdit(establecimiento)}>
            Editar
          </DropdownItem>
          <DropdownItem onClick={() => onDelete(establecimiento)}>
            Borrar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    ) : null;
  };

  return (
    <div className="col-12 col-md-6">
      <Card>
        <CardHeader>
          <CardTitle tag="h5" className="mb-0 d-flex justify-content-between">
            <span>{establecimiento.nombre}</span>
            <div>
              {establecimientosContext.selected === establecimiento.id ? (
                <Badge color={"secondary"} size={18} pill className="mr-4">
                  Seleccionado
                </Badge>
              ) : null}
              <MoreOptions />
            </div>
          </CardTitle>
        </CardHeader>
        <ListGroup flush className="small">
          <ListGroupItem className="d-flex justify-content-between mr-2">
            <span className="text-left text-muted">#Potreros:</span>
            <span className="text-right">
              {establecimiento.potreros?.length || 0}
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between mr-2">
            <span className="text-left text-muted">Departamento:</span>
            <span className="text-right">
              {establecimiento?.departamento?.nombre}
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between mr-2">
            <span className="text-left text-muted">Estado:</span>
            <div>
              <span className="text-right">
                {establecimiento?.estado === ESTADO_ABIERTO
                  ? "Abierto"
                  : "Cerrado"}
              </span>
              <FontAwesomeIcon
                id={"estadoInfo"}
                icon={faInfoCircle}
                fixedWidth
                className="ml-2 text-info cursor-pointer"
              />
              <UncontrolledTooltip placement={"bottom"} target={"estadoInfo"}>
                {establecimiento?.estado === ESTADO_ABIERTO
                  ? "Ingreso de datos habilitado para el establecimiento"
                  : "Ingreso de datos deshabilitado para el establecimiento"}
              </UncontrolledTooltip>
            </div>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </div>
  );
};

export default EstablecimientoCard;
