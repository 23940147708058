import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const TooltipItem = (props) => {
  const { position = "top", id } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
        className="d-flex justify-content-center align-items-center"
        id={"tooltip-" + id}
      >
        {props.children}
      </div>
      <Tooltip
        placement={position}
        isOpen={tooltipOpen}
        target={"tooltip-" + id}
        toggle={toggle}
      >
        {props.title}
      </Tooltip>
    </div>
  );
};

export default TooltipItem;
