import React, { useState } from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import Loader from "./Loader";

const ConfirmationModal = ({
  onSubmit,
  onClose,
  title,
  body,
  confirmColor,
  confirmText = "Confirmar",
  cancelText = "Cancelar",
}) => {
  const [loading, setLoading] = useState(false);
  const doSubmit = () => {
    setLoading(true);
    onSubmit();
  };
  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: body }}></div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          {cancelText}
        </Button>{" "}
        <Button
          disabled={loading}
          color={confirmColor || "primary"}
          onClick={doSubmit}
        >
          {confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
