import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Row,
  Form,
  UncontrolledAlert,
} from "reactstrap";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePotreros } from "../../../providers/potrerosProvider";
import { potrerosApi } from "../../../services/potrerosServices";
import Loader from "../../Loader";
import * as turf from "@turf/turf";
import PotreroMap from "./PotreroMap";
import InformationModal from "../../InformationModal";

const mapToPaths = (data) =>
  (data || []).map((c) => {
    return { lat: c[1], lng: c[0] };
  });

const PotreroEditModal = ({ onClose }) => {
  const [potrerosContext, setPotrerosContext] = usePotreros();

  const [loading, setLoading] = useState();
  const [infoGeo, setInfoGeo] = useState(
    potrerosContext.selectedPotrero.infoGeo
      ? mapToPaths(potrerosContext.selectedPotrero.infoGeo.coordinates[0])
      : []
  );
  const [nombre, setNombre] = useState(potrerosContext.selectedPotrero.nombre);
  const [exists, setExists] = useState(false);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onNombreChange = (event) => {
    setExists(
      (potrerosContext.potreros.data || []).find(
        (e) =>
          e.nombre === event.currentTarget.value &&
          (potrerosContext.selectedPotrero
            ? potrerosContext.selectedPotrero.id !== e.id
            : true)
      ) !== undefined
    );
    setNombre(event.currentTarget.value);
  };

  const onDismiss = () => {
    setNombre("");
    setExists(potrerosContext.selectedPotrero?.nombre || "");
  };

  const buildPolygon = () => {
    if (!infoGeo) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Dibuja el potrero en el mapa antes de confirmar.",
      });
    }
    if (infoGeo.length < 3) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Dibuja al menos 3 vertices.",
      });
      return false;
    }
    const coordinates = [];
    for (let point of infoGeo) {
      coordinates.push([point.lng, point.lat]);
    }
    const line = turf.lineString(coordinates);
    return turf.lineToPolygon(line);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }
    const polygon = buildPolygon();
    if (polygon) {
      setLoading(true);
      debugger;
      potrerosApi
        .updatePotrero(potrerosContext.selectedPotrero.establecimientoId, {
          nombre,
          id: potrerosContext.selectedPotrero.id,
          infoGeo: polygon.geometry,
        })
        .then(() => {
          setPotrerosContext({
            refresh: !potrerosContext.refresh,
            selectedPotrero: null,
          });
        })
        .catch((err) => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Error",
            body:
              err.response?.data?.message ||
              err.response?.data[0]?.msg ||
              "Ha habido un error con tu solicitud.",
          });
        });
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal size="xl" isOpen={true} onClosed={onClose}>
      <ModalHeader>Editar Potrero</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup row>
                <Label sm={4} className="text-left">
                  Nombre
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    type="text"
                    maxLength="50"
                    placeholder="Ingresa el nombre.."
                    onChange={onNombreChange}
                    value={nombre}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-0">
                <Col sm={12}>
                  <PotreroMap current={infoGeo} onChange={setInfoGeo} />
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <UncontrolledAlert
              toggle={onDismiss}
              isOpen={exists}
              color="warning"
            >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span>Ya existe otro potrero con este nombre.</span>
              </div>
            </UncontrolledAlert>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={() => onClose()}>
                Cancelar
              </Button>{" "}
              <Button disabled={exists} color={"primary"} type="submit">
                Guardar
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default PotreroEditModal;
