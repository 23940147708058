import React from "react";
import { NavLink, withRouter } from "react-router-dom";

import { Badge } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarItem = withRouter(
  ({
    key,
    name,
    badgeColor,
    badgeText,
    icon,
    to,
    history,
    action,
    classes,
    isActive,
  }) => {
    return (
      <li
        key={key}
        className={"sidebar-item "}
        onClick={() => (action ? action() : history.push(to))}
      >
        <NavLink
          to="#"
          isActive={() => isActive}
          className={`${classes || ""} sidebar-link text-truncate`}
          activeClassName="active"
        >
          {icon ? (
            <React.Fragment>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle mr-2 sidebar-icon"
              />{" "}
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name
          )}{" "}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

export default SidebarItem;
