import React from "react";

import { Table, ListGroup, ListGroupItem } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useInformacionPotreroModal } from "../../../../providers/informacionPotreroModalProvider";
import { utils } from "../../../../utils/utils";

const TIPO_MOVIMIENTO_ENTRE_POTREROS = "1";
const TIPO_MOVIMIENTO_SALIDA = "2";

const AnimalesList = () => {
  const [informacionPotreroModalContext, setInformacionPotreroModalContext] =
    useInformacionPotreroModal();

  const onDeleteAnimal = (catAnimalId) => {
    let animales = [...informacionPotreroModalContext.animales];
    animales.splice(
      animales.findIndex((animal) => animal.catAnimalId === catAnimalId),
      1
    );
    setInformacionPotreroModalContext({ animales });
  };

  const showPesoPromedio =
    informacionPotreroModalContext.tipoMovimiento !==
      TIPO_MOVIMIENTO_ENTRE_POTREROS &&
    informacionPotreroModalContext.tipoMovimiento !== TIPO_MOVIMIENTO_SALIDA;

  return (
    <ListGroup flush className="col-12 p-0 text-center">
      <ListGroupItem
        className="text-underline py-2 border-bottom font-weight-bold text-body list-group-item bg-lighter"
        tag="div"
      >
        Animales stock "Carga"
      </ListGroupItem>
      {!informacionPotreroModalContext.animales.length ? (
        <ListGroupItem
          className="py-2 d-flex justify-content-between"
          tag="div"
        >
          <small className="text-muted">No se han agregado animales</small>
        </ListGroupItem>
      ) : (
        <ListGroupItem
          className="p-2 border-bottom d-flex justify-content-between bg-white"
          tag="div"
        >
          <Table size="sm" className="small my-0 bg-lighter border">
            <thead className="text-muted">
              <tr>
                <th className="border-0">Categoria</th>
                <th className="border-0">Cantidad</th>
                {showPesoPromedio ? (
                  <th className="border-0">Peso Promedio</th>
                ) : null}
                <th className="border-0"></th>
              </tr>
            </thead>
            <tbody>
              {informacionPotreroModalContext.animales.map(
                ({ catAnimalId, cantidad, nombre, pesoPromedio }, index) => (
                  <tr key={index}>
                    <td>{nombre}</td>
                    <td>{cantidad}</td>
                    {showPesoPromedio ? (
                      <td>{utils.formatDecimal(pesoPromedio, 2)} Kgs.</td>
                    ) : null}
                    <td className="table-action">
                      <FontAwesomeIcon
                        hidden={
                          (informacionPotreroModalContext.id &&
                            !informacionPotreroModalContext.isLast) ||
                          informacionPotreroModalContext.disabled
                        }
                        icon={faTrashAlt}
                        fixedWidth
                        className="ml-2 text-danger cursor-pointer"
                        onClick={() => onDeleteAnimal(catAnimalId)}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </ListGroupItem>
      )}
    </ListGroup>
  );
};

export default AnimalesList;
