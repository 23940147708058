import React, { useCallback, useEffect, useState } from "react";

import { Col, Container, Row, Button, CustomInput } from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import InformationModal from "../../components/InformationModal";
import { usePotreros } from "../../providers/potrerosProvider";
import { potrerosApi } from "../../services/potrerosServices";
import PotreroCreationModal from "../../components/customer/potreros/PotreroCreationModal";
import PotrerosListView from "../../components/customer/potreros/PoterosListView";
import PotrerosMapView from "../../components/customer/potreros/PotrerosMapView";
import { useEstablecimientos } from "../../providers/establecimientosProvider";
import PotreroEditModal from "../../components/customer/potreros/PotreroEditModal";
import PotrerosImportModal from "../../components/customer/potreros/PotrerosImportModal";
import { faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../providers/authProvider";

const LIST_MODE = 1;
const MAP_MODE = 2;
const ESTADO_ABIERTO = "1";

const Potreros = () => {
  const [authContext] = useAuth();
  const [establecimientosContext] = useEstablecimientos();
  const [potrerosContext, setPotrerosContext] = usePotreros();

  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(MAP_MODE);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [potreroCreationModal, setPotreroCreationModal] = useState();
  const [potrerosImportModal, setPotrerosImportModal] = useState();

  const onCreate = () => {
    if (!establecimientosContext.selected) {
      return setInformationModal({
        isOpen: true,
        title: "Crear Potrero",
        body: `
        <p>No hay ningún establecimiento seleccionado.</p>
        <span>Selecciona un establecimiento desde la barra lateral para crear un potrero.</span>
      `,
      });
    }
    const selected = establecimientosContext.allEstablecimientos.find(
      (e) => e.id === establecimientosContext.selected
    );
    if (selected.estado !== ESTADO_ABIERTO) {
      return setInformationModal({
        isOpen: true,
        title: "Crear Potrero",
        body: `
        <p>El establecimiento esta en estado cerrado, por lo que no se puede agregar información.</p>
        <span>Edita el estado del establecimiento desde la sección de establecimientos para realizar cambios.</span>
      `,
      });
    }
    setPotreroCreationModal(true);
  };

  const onImportar = () => {
    if (!establecimientosContext.selected) {
      return setInformationModal({
        isOpen: true,
        title: "Importar Potreros",
        body: `
        <p>No hay ningún establecimiento seleccionado.</p>
        <span>Selecciona un establecimiento desde la barra lateral para crear un potrero.</span>
      `,
      });
    }
    const selected = establecimientosContext.allEstablecimientos.find(
      (e) => e.id === establecimientosContext.selected
    );
    if (selected.estado !== ESTADO_ABIERTO) {
      return setInformationModal({
        isOpen: true,
        title: "Importar Potreros",
        body: `
        <p>El establecimiento esta en estado cerrado, por lo que no se puede agregar información.</p>
        <span>Edita el estado del establecimiento desde la sección de establecimientos para realizar cambios.</span>
      `,
      });
    }
    setPotrerosImportModal(true);
  };

  const setPotrerosContextCB = useCallback(
    (data) => {
      setPotrerosContext(data);
    },
    [setPotrerosContext]
  );

  useEffect(() => {
    if (establecimientosContext.selected) {
      setLoading(true);
      potrerosApi
        .getPotreros(establecimientosContext.selected, {
          search: potrerosContext.search,
          page: potrerosContext.page - 1,
          pageSize: potrerosContext.sizePerPage,
          sortBy: potrerosContext.sortBy,
          direction: potrerosContext.direction,
        })
        .then((result) => {
          setPotrerosContextCB({ potreros: result });
          setLoading(false);
        });
    } else {
      setPotrerosContextCB({ page: 1, potreros: { data: [], count: 0 } });
    }
  }, [
    potrerosContext.potreros.length,
    potrerosContext.refresh,
    establecimientosContext.selected,
    potrerosContext.search,
    potrerosContext.page,
    potrerosContext.sizePerPage,
    potrerosContext.sortBy,
    potrerosContext.direction,
    setPotrerosContextCB,
  ]);

  const selectedEstablecimiento = () =>
    (establecimientosContext.allEstablecimientos || []).find(
      (e) => e.id === establecimientosContext.selected
    )?.nombre || "";

  return (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Header className="mb-3">
        <HeaderTitle className="d-flex flex-wrap justify-content-between align-items-center">
          <div className="d-flex flex-column col-12 px-0 col-lg-6 align-items-start">
            <span className="col-12 px-0 col-lg-6 text-dark">
              Potreros {selectedEstablecimiento()}
            </span>
            <small className="col-12 px-0 text-muted font-size-75">
              {viewMode === MAP_MODE ? "Mapa" : "Listado"}
            </small>
          </div>
          <div className="d-flex justify-content-end mt-2 mt-lg-0 col-12 col-lg-6 px-0 align-items-center">
            <Button
              type="button"
              size="sm"
              className="rounded view-selector btn-light font-weight-bold text-primary d-flex"
            >
              <CustomInput
                type="checkbox"
                id="viewModePotrerosMapa"
                label="Ver Mapa"
                checked={viewMode === MAP_MODE}
                onChange={(event) =>
                  setViewMode(event.target.checked ? MAP_MODE : LIST_MODE)
                }
                className="cursor-pointer d-flex align-items-center font-weight-bold text-primary"
              />
              <CustomInput
                type="checkbox"
                id="viewModePotrerosListado"
                label="Ver Listado"
                checked={viewMode === LIST_MODE}
                onChange={(event) =>
                  setViewMode(event.target.checked ? LIST_MODE : MAP_MODE)
                }
                className="ml-4 cursor-pointer d-flex align-items-center font-weight-bold text-primary"
              />
            </Button>
            {authContext.currentUser.ingresoDatosHabilitado ? (
              <>
                <Button
                  size="sm"
                  color="warning"
                  className="rounded ml-2 btn-success font-weight-bold text-white text-uppercase py-1"
                  onClick={onCreate}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Crear Potrero
                </Button>
                <Button
                  size="sm"
                  color="warning"
                  className="rounded ml-2 btn-success font-weight-bold text-white text-uppercase py-1"
                  onClick={onImportar}
                >
                  <FontAwesomeIcon icon={faUpload} className="mr-2" />
                  Importar Potreros
                </Button>
              </>
            ) : null}
          </div>
        </HeaderTitle>
      </Header>
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column flex-grow-1">
          {viewMode === LIST_MODE ? (
            <PotrerosListView loading={loading} />
          ) : (
            <PotrerosMapView />
          )}
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {potreroCreationModal ? (
        <PotreroCreationModal onClose={() => setPotreroCreationModal(false)} />
      ) : null}
      {potrerosContext.selectedPotrero ? (
        <PotreroEditModal
          onClose={() => setPotrerosContext({ selectedPotrero: null })}
        />
      ) : null}
      {potrerosImportModal ? (
        <PotrerosImportModal onClose={() => setPotrerosImportModal(false)} />
      ) : null}
    </Container>
  );
};

export default Potreros;
