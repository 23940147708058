import React, { useCallback, useEffect, useState } from "react";

import { Card, CardHeader, Col, Container, Row, Button } from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import InformationModal from "../../components/InformationModal";
import { useEstablecimientos } from "../../providers/establecimientosProvider";
import { establecimientosApi } from "../../services/establecimientosServices";
import EstablecimientoModal from "../../components/customer/establecimientos/EstablecimientoModal";
import EstablecimientosListView from "../../components/customer/establecimientos/EstablecimientosListView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../providers/authProvider";

const Establecimientos = () => {
  const [authContext] = useAuth();
  const [establecimientosContext, setEstablecimientosContext] =
    useEstablecimientos();

  const [loading, setLoading] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [establecimientoModal, setEstablecimientoModal] = useState();

  const setEstablecimientosContextCB = useCallback(
    (data) => {
      setEstablecimientosContext(data);
    },
    [setEstablecimientosContext]
  );

  useEffect(() => {
    setLoading(true);
    establecimientosApi
      .getEstablecimientos({
        search: establecimientosContext.search,
        page: establecimientosContext.page - 1,
        pageSize: establecimientosContext.sizePerPage,
        sortBy: establecimientosContext.sortBy,
        direction: establecimientosContext.direction,
      })
      .then((result) => {
        setEstablecimientosContextCB({ establecimientos: result });
        setLoading(false);
      });
  }, [
    establecimientosContext.search,
    establecimientosContext.refresh,
    establecimientosContext.page,
    establecimientosContext.sizePerPage,
    establecimientosContext.sortBy,
    establecimientosContext.direction,
    setEstablecimientosContextCB,
  ]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Header className="mb-3">
        <HeaderTitle className="d-flex flex-wrap justify-content-between">
          <span className="col-12 px-0 col-lg-6 text-dark">
            Establecimientos
          </span>
          <div className="d-flex justify-content-end align-items-center mt-2 mt-lg-0 col-12 col-lg-6 px-0">
            {authContext.currentUser.ingresoDatosHabilitado ? (
              <Button
                size="sm"
                color="warning"
                className="rounded ml-2 btn-success font-weight-bold text-white text-uppercase py-1"
                onClick={() => setEstablecimientoModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Crear Establecimiento
              </Button>
            ) : null}
          </div>
        </HeaderTitle>
      </Header>
      <Row>
        <Col>
          {establecimientosContext.allEstablecimientos?.length ? (
            <EstablecimientosListView loading={loading} />
          ) : (
            <Card className="col-12">
              <CardHeader className="text-center">
                Para comenzar a utilizar iPasto crea un establecimiento con sus
                potreros
              </CardHeader>
            </Card>
          )}
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {establecimientoModal ? (
        <EstablecimientoModal onClose={() => setEstablecimientoModal(false)} />
      ) : null}
    </Container>
  );
};

export default Establecimientos;
