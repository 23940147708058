import React, { useState } from "react";

import {
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle,
  CardHeader,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { MoreVertical } from "react-feather";
import { utils } from "../../../../utils/utils";
import { potrerosApi } from "../../../../services/potrerosServices";
import ConfirmationModal from "../../../ConfirmationModal";
import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import UsoPotreroModal from "./UsoPotreroModal";
import Loader from "../../../Loader";
import { useAuth } from "../../../../providers/authProvider";

const UsoPotreroCard = ({ usoPotrero }) => {
  const [authContext] = useAuth();
  const [establecimientosContext] = useEstablecimientos();
  const [usoPotreroModal, setUsoPotreroModal] = useState(false);

  const [loading, setLoading] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
    confirmText: null,
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onConfirmDelete = (usosPotrero) => {
    setLoading({ [usosPotrero.id]: true });
    potrerosApi
      .deleteUsoPotrero({
        id: usosPotrero.id,
        potreroId: usosPotrero.potreroId,
        establecimientoId: establecimientosContext.selected,
      })
      .then(() => {
        setLoading({ [usosPotrero.id]: true });
        setConfirmationModal(initConfirmationModal);
      });
  };

  const onDelete = (usosPotrero) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => onConfirmDelete(usosPotrero),
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Borrar uso de potrero`,
      body: `Confirmas que quieres borrar el uso del potrero seleccionado?`,
    });
  };

  const onEdit = () => {
    setUsoPotreroModal({
      ...usoPotrero,
      establecimientoId: establecimientosContext.selected,
      fechaComienzo: new Date(usoPotrero.fechaComienzo),
      fechaFin: usoPotrero.fechaFin ? new Date(usoPotrero.fechaFin) : null,
    });
  };

  const MoreOptions = () =>
    loading ? (
      <Loader size="sm" align="end" />
    ) : authContext.currentUser.ingresoDatosHabilitado ? (
      <UncontrolledDropdown className="d-inline-block flex-shrink-0">
        <DropdownToggle tag="a">
          <MoreVertical className="text-primary" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={onEdit}>Editar</DropdownItem>
          <DropdownItem onClick={onDelete}>Borrar</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    ) : null;

  return (
    <div className="col-12 col-lg-6">
      <Card className="col-12 px-0 border mb-2">
        <CardHeader>
          <CardTitle tag="h5" className="mb-0 d-flex justify-content-between">
            <span className="flex-shrink-0">{usoPotrero.potrero.nombre}</span>
            <MoreOptions />
          </CardTitle>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className="d-flex justify-content-between">
            <span>Uso:</span>
            <span>{usoPotrero.tipoUsoSuelo.nombre}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between">
            <span>Fecha Comienzo:</span>
            <span>
              {utils.formatDate(usoPotrero.fechaComienzo, "DD/MM/YYYY")}
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between">
            <span>Fecha Fin:</span>
            <span>
              {usoPotrero.fechaFin
                ? utils.formatDate(usoPotrero.fechaFin, "DD/MM/YYYY")
                : "-"}
            </span>
          </ListGroupItem>
        </ListGroup>
      </Card>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {usoPotreroModal ? (
        <UsoPotreroModal
          selectedUsoPotrero={usoPotreroModal}
          onClose={() => setUsoPotreroModal(false)}
        />
      ) : null}
    </div>
  );
};

export default UsoPotreroCard;
