import React, { useState } from "react";

import {
  Card,
  CardTitle,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Button,
  Badge,
} from "reactstrap";
import InformationModal from "../../../InformationModal";

const PotreroCard = ({ onIndicadoresUso, indicadorPotrero }) => {
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  return (
    <div className="col-12 col-md-6 px-0">
      <Card className="col-12 px-0">
        <CardHeader>
          <CardTitle tag="h5" className="mb-0 d-flex justify-content-between">
            <span>{indicadorPotrero.nombre}</span>
          </CardTitle>
        </CardHeader>
        <ListGroup flush className="small">
          <ListGroupItem className="d-flex justify-content-between">
            <span className="text-left text-muted">
              Pasto Disponible (KgMs/ha):
            </span>
            <span className="text-right">
              {Math.round(indicadorPotrero.pastoDisponible)}
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between">
            <span className="text-left text-muted">
              Carga Instantánea (KgPV/ha):
            </span>
            <span className="text-right">
              {Math.round(indicadorPotrero.cargaInstantanea)}
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <span className="text-left text-muted">
              Composición de la carga:
            </span>
            <div className="text-right">
              <div className="d-flex flex-column align-items-end">
                <Badge color={"info"} size={15} pill>
                  {`${Math.round(
                    indicadorPotrero.composicionCarga.vacunos
                  )}% vacunos`}
                </Badge>
                <Badge className="my-1" color={"secondary"} size={15} pill>
                  {`${Math.round(
                    indicadorPotrero.composicionCarga.ovinos
                  )}% ovinos`}
                </Badge>
                <Badge color={"warning"} size={15} pill>
                  {`${Math.round(
                    indicadorPotrero.composicionCarga.equinos
                  )}% equinos`}
                </Badge>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <span className="text-left text-muted">Indicadores de Uso:</span>
            <span className="text-right">
              <Button
                className="rounded"
                size="sm"
                onClick={() => onIndicadoresUso(indicadorPotrero.id)}
              >
                Ver
              </Button>
            </span>
          </ListGroupItem>
        </ListGroup>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={true}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </div>
  );
};
export default PotreroCard;
