import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Row,
  CustomInput,
  Form,
  UncontrolledAlert,
} from "reactstrap";

import { useInformacionPotreroModal } from "../../../../providers/informacionPotreroModalProvider";
import AnimalesList from "./AnimalesList";

const TYPE_SITUACION = "1";
const TIPO_MOVIMIENTO_ENTRE_POTREROS = "1";
const TIPO_MOVIMIENTO_SALIDA = "2";

const AnimalesSelector = ({ availableAnimals }) => {
  const [informacionPotreroModalContext, setInformacionPotreroModalContext] =
    useInformacionPotreroModal();

  const [categoriaAnimal, setCategoriaAnimal] = useState("");
  const [cantidadAnimal, setCantidadAnimal] = useState("");
  const [pesoPromedioAnimal, setPesoPromedioAnimal] = useState("");
  const [error, setError] = useState();

  const showPesoPromedio =
    informacionPotreroModalContext.tipoMovimiento !==
      TIPO_MOVIMIENTO_ENTRE_POTREROS &&
    informacionPotreroModalContext.tipoMovimiento !== TIPO_MOVIMIENTO_SALIDA;

  const onDismiss = () => {
    setCantidadAnimal("");
    setError();
  };

  const onSubmitAnimal = (event) => {
    event.preventDefault();
    const categoria = informacionPotreroModalContext.categoriasAnimal.find(
      (ca) => ca.id === parseInt(categoriaAnimal)
    );
    let animales = informacionPotreroModalContext.animales;
    const existingAnimal = animales.find((a) => a.catAnimalId === categoria.id);
    if (existingAnimal) {
      existingAnimal.pesoPromedio =
        (existingAnimal.pesoPromedio * existingAnimal.cantidad +
          pesoPromedioAnimal * cantidadAnimal) /
        (existingAnimal.cantidad + cantidadAnimal);
      existingAnimal.cantidad += cantidadAnimal;
      animales.splice(
        animales.findIndex((a) => a.catAnimalId === existingAnimal.catAnimalId),
        1,
        existingAnimal
      );
      setInformacionPotreroModalContext({ animales });
    } else {
      setInformacionPotreroModalContext({
        animales: [
          ...animales,
          {
            catAnimalId: categoria.id,
            nombre: categoria.nombre,
            cantidad: cantidadAnimal,
            pesoPromedio: showPesoPromedio ? pesoPromedioAnimal : null,
          },
        ],
      });
    }
    setCategoriaAnimal("");
    setPesoPromedioAnimal("");
    setCantidadAnimal("");
  };

  return (
    <Modal size="md" isOpen={true}>
      <ModalHeader>
        {`Agregar Animales a ${
          informacionPotreroModalContext.mode === TYPE_SITUACION
            ? "Estado Situación"
            : "Movimiento"
        } de Potrero`}
      </ModalHeader>
      <ModalBody className="text-center">
        <Col className="d-flex flex-column align-items-start border rounded px-0">
          <AnimalesList />
          <Form
            onSubmit={onSubmitAnimal}
            className="col-12 mt-3 text-left text-center"
          >
            <span className="font-weight-bold">Agregar Animales</span>
            <FormGroup row className="mt-3">
              <Label sm={4} className="text-left">
                Categoría de animal
              </Label>
              <Col sm={8}>
                <CustomInput
                  required={true}
                  id="categorySelect"
                  type="select"
                  name="categorySelect"
                  onChange={(event) =>
                    setCategoriaAnimal(event.currentTarget.value)
                  }
                  value={categoriaAnimal}
                >
                  <option value="">Selecciona una categoría...</option>
                  {informacionPotreroModalContext.categoriasAnimal
                    .filter((category) =>
                      availableAnimals
                        ? availableAnimals.find(
                            (availableAnimal) =>
                              availableAnimal.catAnimalId === category.id
                          )
                        : true
                    )
                    .filter(
                      (category) =>
                        !informacionPotreroModalContext.animales.find(
                          (a) => a.catAnimalId === category.id
                        )
                    )
                    .map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.nombre}
                      </option>
                    ))}
                </CustomInput>
              </Col>
            </FormGroup>
            {showPesoPromedio ? (
              <FormGroup row className="mt-3">
                <Col sm={4} className="text-left d-flex flex-column">
                  <span>Peso Promedio</span>
                  <span className="font-size-75 text-secondary">en Kg.</span>
                </Col>
                <Col sm={8}>
                  <Input
                    className="flex-grow-1"
                    required={true}
                    type="number"
                    max="9999"
                    min="1"
                    placeholder="Ingresa el peso promedio de los animales.."
                    onChange={(event) =>
                      setPesoPromedioAnimal(event.currentTarget.value)
                    }
                    value={pesoPromedioAnimal}
                  />
                </Col>
              </FormGroup>
            ) : null}
            <FormGroup row className="mt-3">
              <Label sm={4} className="text-left">
                Cantidad
              </Label>
              <Col sm={8}>
                <Input
                  required={true}
                  type="number"
                  max="9999999999"
                  min="1"
                  placeholder="Ingresa la cantidad de animales.."
                  onChange={(event) => {
                    const availableAnimal = availableAnimals
                      ? availableAnimals.find(
                          (availableAnimal) =>
                            availableAnimal.catAnimalId === categoriaAnimal
                        )
                      : false;
                    if (
                      availableAnimal &&
                      availableAnimal.cantidad < event.currentTarget.value
                    ) {
                      setError(
                        `El máximo numero disponible de ${availableAnimal.categoria.nombre} es ${availableAnimal.cantidad}`
                      );
                    }
                    setCantidadAnimal(event.currentTarget.value);
                  }}
                  value={cantidadAnimal}
                />
                <div className="d-flex justify-content-between">
                  <Button
                    disabled={error}
                    color={"tertiary"}
                    className="mt-2 rounded"
                    size={"sm"}
                    type="submit"
                  >
                    Agregar Animal
                  </Button>
                </div>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Col>
          <UncontrolledAlert
            hidden={!error}
            toggle={onDismiss}
            isOpen={error}
            color="warning"
          >
            <div className="alert-icon">
              <FontAwesomeIcon icon={faBell} fixedWidth />
            </div>
            <div className="alert-message text-left">
              <span>{error}</span>
            </div>
          </UncontrolledAlert>
          <Row className="justify-content-between">
            <Button
              color={"secondary"}
              onClick={() =>
                setInformacionPotreroModalContext({ animalesSelector: false })
              }
            >
              Volver
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default AnimalesSelector;
