import React from "react";
import moment from "moment";

import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Loader";
import { utils } from "../../../utils/utils";

const IndicadoresCards = ({ indicadores, loading }) => {
  return (
    <Row className="d-flex justify-content-between">
      <Col className="col-12 col-md-6 col-lg-3 d-flex">
        <Card className="border flex-grow-1">
          <CardBody>
            <div className="d-flex">
              <Col className="mt-0 px-0">
                <CardTitle tag="h5">
                  Tasa de Crecimiento
                  <br />
                  Actual
                  <br />
                  <small className="text-muted">KG de MS/ha/día</small>
                </CardTitle>
              </Col>
              <Col className="col-auto">
                <div className="avatar">
                  <div className="avatar-title rounded-circle bg-primary">
                    <FontAwesomeIcon icon={faLeaf} size="lg" />
                  </div>
                </div>
              </Col>
            </div>
            {loading ? (
              <Loader size="sm" />
            ) : indicadores?.mes && indicadores?.tcActual > 0 ? (
              <>
                <h1 className="display-5 mt-1 mb-3">
                  {utils.formatDecimal(indicadores.tcActual, 2)}
                </h1>
                <small className="mb-0 d-flex align-items-center">
                  Último registro para el mes de
                  {` ${utils.capitalizeFirstLetter(
                    moment(indicadores.mes, "MM").format("MMMM")
                  )} `}
                  de {indicadores.anio}
                </small>
              </>
            ) : (
              <small>Sin Datos</small>
            )}
          </CardBody>
        </Card>
      </Col>
      <Col className="col-12 col-md-6 col-lg-3 d-flex">
        <Card className="border flex-grow-1">
          <CardBody>
            <div className="d-flex">
              <Col className="mt-0 px-0">
                <CardTitle tag="h5">
                  Tasa de Crecimiento
                  <br />
                  Máximo
                  <br />
                  <small className="text-muted">KG de MS/ha/día</small>
                </CardTitle>
              </Col>
              <Col className="col-auto">
                <div className="avatar">
                  <div className="avatar-title rounded-circle bg-primary">
                    <FontAwesomeIcon icon={faLeaf} size="lg" />
                  </div>
                </div>
              </Col>
            </div>
            {loading ? (
              <Loader size="sm" />
            ) : indicadores?.mes && indicadores?.tcMax?.value > 0 ? (
              <>
                <h1 className="display-5 mt-1 mb-3">
                  {utils.formatDecimal(indicadores.tcMax.value, 2)}
                </h1>
                <small className="mb-0 d-flex align-items-center">
                  Histórico para el mes de
                  {` ${utils.capitalizeFirstLetter(
                    moment(indicadores?.mes, "MM").format("MMMM")
                  )}`}
                  , fue registrado en {indicadores.tcMax.anio}
                </small>
              </>
            ) : (
              <small>Sin Datos</small>
            )}
          </CardBody>
        </Card>
      </Col>
      <Col className="col-12 col-md-6 col-lg-3 d-flex">
        <Card className="border flex-grow-1">
          <CardBody>
            <div className="d-flex">
              <Col className="mt-0 px-0">
                <CardTitle tag="h5">
                  Tasa de Crecimiento
                  <br />
                  Mínimo
                  <br />
                  <small className="text-muted">KG de MS/ha/día</small>
                </CardTitle>
              </Col>
              <Col className="col-auto">
                <div className="avatar">
                  <div className="avatar-title rounded-circle bg-primary">
                    <FontAwesomeIcon icon={faLeaf} size="lg" />
                  </div>
                </div>
              </Col>
            </div>
            {loading ? (
              <Loader size="sm" />
            ) : indicadores?.mes && indicadores.tcMin.value > 0 ? (
              <>
                <h1 className="display-5 mt-1 mb-3">
                  {utils.formatDecimal(indicadores.tcMin.value, 2)}
                </h1>
                <small className="mb-0 d-flex align-items-center">
                  Histórico para el mes de
                  {` ${utils.capitalizeFirstLetter(
                    moment(indicadores?.mes, "MM").format("MMMM")
                  )}`}
                  , fue registrado en {indicadores.tcMin.anio}
                </small>
              </>
            ) : (
              <small>Sin Datos</small>
            )}
          </CardBody>
        </Card>
      </Col>
      <Col className="col-12 col-md-6 col-lg-3 d-flex">
        <Card className="border flex-grow-1">
          <CardBody>
            <div className="d-flex">
              <Col className="mt-0 px-0">
                <CardTitle tag="h5">
                  Tasa de Crecimiento
                  <br />
                  Promedio
                  <br />
                  <small className="text-muted">KG de MS/ha/día</small>
                </CardTitle>
              </Col>
              <Col className="col-auto">
                <div className="avatar">
                  <div className="avatar-title rounded-circle bg-primary">
                    <FontAwesomeIcon icon={faLeaf} size="lg" />
                  </div>
                </div>
              </Col>
            </div>
            {loading ? (
              <Loader size="sm" />
            ) : indicadores?.mes && indicadores.tcPromedio > 0 ? (
              <>
                <h1 className="display-5 mt-1 mb-3">
                  {utils.formatDecimal(indicadores.tcPromedio, 2)}
                </h1>
                <small className="mb-0 d-flex align-items-center">
                  Histórico para el mes de
                  {` ${utils.capitalizeFirstLetter(
                    moment(indicadores.mes, "MM").format("MMMM")
                  )}`}
                </small>
              </>
            ) : (
              <small>Sin Datos</small>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default IndicadoresCards;
