import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sizePerPage: 10,
  page: 1,
  search: "",
  sortBy: "potrero.nombre",
  direction: "asc",
  tipoUsoSuelo: "",
  potrero: "",
};

const UsosPotrerosContext = createContext(initialState);

const UsosPotrerosProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <UsosPotrerosContext.Provider value={stateAndDispatch}>
      {children}
    </UsosPotrerosContext.Provider>
  );
};

export const useUsosPotreros = () => useContext(UsosPotrerosContext);

export { UsosPotrerosContext, UsosPotrerosProvider };
