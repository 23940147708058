import React, { useEffect, useState } from "react";

import {
  Col,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  Input,
  Row,
  ListGroup,
  Badge,
  ListGroupItem,
} from "reactstrap";

import { RefreshCw } from "react-feather";
import Loader from "../../../../components/Loader";

import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import { useMonitoreoPotreros } from "../../../../providers/monitoreoPotrerosProvider";
import { establecimientosApi } from "../../../../services/establecimientosServices";

import Button from "reactstrap/lib/Button";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import IndicadoresUsoPotreroModal from "./IndicadoresUsoPotreroModal";
import InformationModal from "../../../InformationModal";
import { utils } from "../../../../utils/utils";
import PotrerosCardsView from "./PotrerosCardsView";
import IndicadoresProyeccionModal from "./IndicadoresProyeccionModal";

const IndicadoresMonitoreo = ({ loading: defaultLoading }) => {
  const [establecimientosContext] = useEstablecimientos();
  const [monitoreoPotrerosContext, setMonitoreoPotrerosContext] =
    useMonitoreoPotreros();

  const [indicadoresUsoPotreroModal, setIndicadoresUsoPotreroModal] =
    useState();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [indicadoresPotreros, setIndicadoresPotreros] = useState({});
  const [indicadoresProyeccionModal, setIndicadoresProyeccionModal] =
    useState();
  const [indicadoresEstablecimiento, setIndicadoresEstablecimiento] = useState(
    {}
  );
  const [loadingPotreros, setLoadingPotreros] = useState(defaultLoading);
  const [loadingEstablecimientos, setLoadingEstablecimientos] =
    useState(defaultLoading);

  const onSort = (sortBy, direction) => {
    if (
      monitoreoPotrerosContext.sortBy === sortBy &&
      monitoreoPotrerosContext.direction === direction
    ) {
      return;
    }
    setMonitoreoPotrerosContext({ sortBy, direction, page: 1 });
  };

  useEffect(() => {
    if (establecimientosContext.selected) {
      setLoadingEstablecimientos(true);
      establecimientosApi
        .getIndicadoresEstablecimiento({
          id: establecimientosContext.selected,
        })
        .then((indicadoresEstablecimiento) => {
          setIndicadoresEstablecimiento(indicadoresEstablecimiento);
          setLoadingEstablecimientos(false);
          if (indicadoresEstablecimiento.potrerosSinSituacion?.length) {
            setInformationModal({
              isOpen: true,
              title: `Tiene potreros sin estado de situación`,
              rawBody: true,
              body: (
                <>
                  <p className="text-center text-underline font-weight-bold">
                    Todos los potreros deben tener un estado ingresado.
                  </p>
                  <ListGroup>
                    <ListGroupItem
                      className="d-flex justify-content-center align-items-center bg-lighter py-1 text-underline font-weight-bold"
                      tag="div"
                    >
                      Potreros sin estado de situación
                    </ListGroupItem>
                    {indicadoresEstablecimiento.potrerosSinSituacion.map(
                      (potrero, index) => (
                        <ListGroupItem
                          key={index}
                          className="d-flex justify-content-center align-items-center py-1"
                        >
                          <span>{potrero}</span>
                        </ListGroupItem>
                      )
                    )}
                  </ListGroup>
                </>
              ),
            });
          }
        })
        .catch(() => {});
    } else {
      setIndicadoresEstablecimiento();
    }
  }, [establecimientosContext.selected]);

  useEffect(() => {
    if (establecimientosContext.selected) {
      setLoadingPotreros(true);
      establecimientosApi
        .getIndicadoresPotreros(establecimientosContext.selected, {
          search: monitoreoPotrerosContext.search,
          page: monitoreoPotrerosContext.page - 1,
          pageSize: monitoreoPotrerosContext.sizePerPage,
          sortBy: monitoreoPotrerosContext.sortBy,
          direction: monitoreoPotrerosContext.direction,
        })
        .then((indicadoresPotreros) => {
          setIndicadoresPotreros(indicadoresPotreros);
          setLoadingPotreros(false);
        });
    } else {
      setIndicadoresPotreros({
        page: 1,
        indicadoresPotreros: { data: [], count: 0 },
      });
    }
  }, [
    indicadoresPotreros.length,
    monitoreoPotrerosContext.refresh,
    establecimientosContext.selected,
    monitoreoPotrerosContext.search,
    monitoreoPotrerosContext.page,
    monitoreoPotrerosContext.sizePerPage,
    monitoreoPotrerosContext.sortBy,
    monitoreoPotrerosContext.direction,
  ]);

  const getPlatoComidaEstablecimiento = () => {
    if (!indicadoresEstablecimiento?.pastoNecesario) {
      return 0;
    }
    return utils.formatDecimal(
      indicadoresEstablecimiento.pastoDisponibleTotal /
        indicadoresEstablecimiento.pastoNecesario,
      1
    );
  };

  const getPlatoComidaColor = () => {
    if (loadingEstablecimientos || !indicadoresEstablecimiento) {
      return "danger";
    }
    const value = getPlatoComidaEstablecimiento();
    if (value < 0.6) {
      return "danger";
    }
    if (value < 0.79) {
      return "yellow";
    }
    if (value < 1.2) {
      return "success";
    }
    return "bordeaux";
  };

  return (
    <>
      {!establecimientosContext.selected ? (
        <Card className="col-12">
          <CardHeader className="text-center">
            Ningún establecimiento seleccionado
          </CardHeader>
        </Card>
      ) : (
        <Col className="px-0">
          <Col className="col-12 d-flex flex-column px-0">
            <Row className="text-center">
              <h4 className="mb-0">Indicadores de Establecimiento</h4>
            </Row>
            {establecimientosContext.selected ? (
              <Col className="px-0">
                <Row className="indicadores-establecimiento col-12 d-flex p-0">
                  <ListGroup
                    flush
                    className="row flex-wrap list-group-horizontal my-2 flex-grow-1 text-center rounded bg-transparent"
                  >
                    <ListGroupItem className="bg-transparent border-top-0 col-12 col-lg-6 col-xl-3 border-0 text-body list-group-item d-flex flex-column">
                      <div className="py-2 bg-light border-bottom text-body d-flex flex-column">
                        <span>
                          Pasto Disponible{" "}
                          <small className="text-muted ml-1">(KgMs/ha)</small>
                        </span>
                      </div>
                      <div className="pb-2 border-0 text-body list-group-item">
                        <h4>
                          {loadingEstablecimientos ||
                          !indicadoresEstablecimiento ? (
                            <Loader size="sm" />
                          ) : isNaN(
                              indicadoresEstablecimiento.pastoDisponible
                            ) ? (
                            "-"
                          ) : (
                            Math.round(
                              indicadoresEstablecimiento.pastoDisponible
                            )
                          )}
                        </h4>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="bg-transparent border-top-0 col-12 col-lg-6 col-xl-3 border-0 text-body list-group-item d-flex flex-column">
                      <div className="py-2 bg-light border-bottom text-body d-flex flex-column">
                        <span>
                          Pasto Disponible{" "}
                          <small className="text-muted ml-1">(cm)</small>
                        </span>
                      </div>
                      <div className="pb-2 border-0 text-body list-group-item">
                        <h4>
                          {loadingEstablecimientos ||
                          !indicadoresEstablecimiento ? (
                            <Loader size="sm" />
                          ) : isNaN(indicadoresEstablecimiento.alturaPasto) ? (
                            "-"
                          ) : (
                            Math.round(indicadoresEstablecimiento.alturaPasto)
                          )}
                        </h4>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="bg-transparent border-top-0 col-12 col-lg-6 col-xl-3 border-0 text-body list-group-item d-flex flex-column">
                      <div className="py-2 bg-light border-bottom text-body d-flex flex-column">
                        <span>
                          Carga Animal{" "}
                          <small className="text-muted ml-1">(KgPV/ha)</small>
                        </span>
                      </div>
                      <div className="pb-2 border-0 text-body list-group-item">
                        <h4>
                          {loadingEstablecimientos ||
                          !indicadoresEstablecimiento ? (
                            <Loader size="sm" />
                          ) : isNaN(
                              indicadoresEstablecimiento.cargaInstantanea
                            ) ? (
                            "-"
                          ) : (
                            Math.round(
                              indicadoresEstablecimiento.cargaInstantanea
                            )
                          )}
                        </h4>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="bg-transparent border-top-0 col-12 col-lg-6 col-xl-3 border-0 text-body list-group-item d-flex flex-column">
                      <div className="py-2 bg-light border-bottom text-body d-flex flex-column">
                        <span>
                          Plato de Comida{" "}
                          <small className="text-muted ml-1">(IsPC)</small>
                        </span>
                      </div>
                      <div className="pb-2 border-0 text-body list-group-item">
                        <h4 className={`text-${getPlatoComidaColor()}`}>
                          {loadingEstablecimientos ||
                          !indicadoresEstablecimiento ? (
                            <Loader size="sm" />
                          ) : (
                            getPlatoComidaEstablecimiento()
                          )}
                        </h4>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="bg-transparent border-top-0 col-12 col-lg-6 border-0 text-body list-group-item d-flex flex-column">
                      <div className="py-2 bg-light border-bottom text-body d-flex flex-column">
                        <span>Motivos de los Movimientos</span>
                      </div>
                      <div className="pb-2 border-0 text-body list-group-item">
                        <Button
                          className="mx-2"
                          color="info"
                          onClick={() =>
                            setInformationModal({
                              isOpen: true,
                              title: `Frecuencia de los motivos de los movimientos`,
                              rawBody: true,
                              body: (
                                <ListGroup>
                                  {indicadoresEstablecimiento.frecuenciaMotivosMovimientos.map(
                                    (motivo, index) => (
                                      <ListGroupItem
                                        key={index}
                                        className="d-flex justify-content-between"
                                      >
                                        <span className="text-left text-muted">
                                          {motivo.nombre}
                                        </span>
                                        <span className="text-right">{`${
                                          motivo.frecuencia.toFixed(2) || 0
                                        }%`}</span>
                                      </ListGroupItem>
                                    )
                                  )}
                                </ListGroup>
                              ),
                            })
                          }
                        >
                          Ver
                        </Button>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="bg-transparent border-top-0 col-12 col-lg-6 border-0 text-body list-group-item d-flex flex-column">
                      <div className="py-2 bg-light border-bottom text-body d-flex flex-column">
                        <span>Indicadores de Proyección </span>
                      </div>
                      <div className="pb-2 border-0 text-body list-group-item">
                        <Button
                          className="mx-2"
                          color="secondary"
                          onClick={() => setIndicadoresProyeccionModal(true)}
                        >
                          Ver
                        </Button>
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                </Row>
              </Col>
            ) : null}
          </Col>
          <Row className="text-center mt-3">
            <h4>Indicadores de Potreros</h4>
          </Row>
          <Card className="col-12 d-none d-lg-flex px-0 row mt-2">
            <CardHeader className="text-center">
              <div className="card-actions d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3 col-4 px-0" size="m">
                  <Input
                    maxLength="50"
                    value={monitoreoPotrerosContext.search}
                    onChange={(evt) =>
                      setMonitoreoPotrerosContext({
                        search: evt.target.value,
                        page: 1,
                      })
                    }
                    className="border-0"
                    placeholder="Buscar.."
                  />
                </InputGroup>
                <span className="mr-1 d-flex">
                  <span className="cursor-pointer mx-2 d-flex align-items-center">
                    <RefreshCw
                      hidden
                      onClick={() =>
                        setMonitoreoPotrerosContext({
                          refresh: !monitoreoPotrerosContext.refresh,
                        })
                      }
                    />
                  </span>
                  <small className="text-muted d-flex align-items-center">
                    Mostrando {indicadoresPotreros?.count || 0} potreros
                  </small>
                </span>
              </div>
            </CardHeader>
            {establecimientosContext.selected ? (
              <CardBody>
                {loadingPotreros ? (
                  <Loader size="sm" />
                ) : indicadoresPotreros.count ? (
                  <>
                    <BootstrapTable
                      bootstrap4
                      remote
                      bordered={false}
                      keyField="id"
                      data={indicadoresPotreros.data}
                      columns={[
                        {
                          dataField: "nombre",
                          text: "Potrero",
                          headerClasses: "text-muted font-size-75 align-middle",
                          sort: true,
                          onSort,
                        },
                        {
                          dataField: "pastoDisponible",
                          text: "Pasto Disponible (KgMs/ha)",
                          headerClasses: "text-muted font-size-75 align-middle",
                          formatter: (value, cell) => Math.round(value),
                        },
                        {
                          dataField: "alturaPasto",
                          text: "Pasto Disponible (cm)",
                          headerClasses: "text-muted font-size-75 align-middle",
                          formatter: (value, cell) => Math.round(value),
                        },
                        {
                          dataField: "cargaInstantanea",
                          text: "Carga Instantánea (KgPV/ha)",
                          headerClasses: "text-muted font-size-75 align-middle",
                          formatter: (value, cell) => Math.round(value),
                        },
                        {
                          dataField: "composicionCarga",
                          text: "Composición de la carga",
                          headerClasses: "text-muted font-size-75 align-middle",
                          formatter: (value, cell) => (
                            <div className="d-flex flex-column align-items-start">
                              <Badge color={"info"} size={15} pill>
                                {`${Math.round(value.vacunos)}% vacunos`}
                              </Badge>
                              <Badge
                                className="my-1"
                                color={"secondary"}
                                size={15}
                                pill
                              >
                                {`${Math.round(value.ovinos)}% ovinos`}
                              </Badge>
                              <Badge color={"warning"} size={15} pill>
                                {`${Math.round(value.equinos)}% equinos`}
                              </Badge>
                            </div>
                          ),
                        },
                        {
                          dataField: "id",
                          text: "Indicadores de Uso",
                          headerClasses: "text-muted font-size-75 align-middle",
                          formatter: (id) => (
                            <Button
                              size="sm"
                              className="rounded"
                              onClick={() => setIndicadoresUsoPotreroModal(id)}
                            >
                              Ver
                            </Button>
                          ),
                        },
                      ]}
                      defaultSorted={[
                        {
                          dataField: monitoreoPotrerosContext.sortBy,
                          order: monitoreoPotrerosContext.direction,
                        },
                      ]}
                      onTableChange={() => {}}
                      pagination={paginationFactory({
                        page: monitoreoPotrerosContext.page,
                        totalSize: indicadoresPotreros.count,
                        sizePerPage: monitoreoPotrerosContext.sizePerPage,
                        sizePerPageList: [
                          5,
                          10,
                          25,
                          50,
                          { text: "Todos", value: indicadoresPotreros.count },
                        ],
                        onPageChange: (page) =>
                          setMonitoreoPotrerosContext({ page }),
                        onSizePerPageChange: (sizePerPage) =>
                          setMonitoreoPotrerosContext({ page: 1, sizePerPage }),
                      })}
                    />
                  </>
                ) : (
                  <div className="text-center font-weight-bold">
                    No hay resultados
                  </div>
                )}
              </CardBody>
            ) : null}
          </Card>
          <PotrerosCardsView
            indicadoresPotreros={indicadoresPotreros}
            loading={loadingPotreros}
          />
        </Col>
      )}
      {indicadoresUsoPotreroModal ? (
        <IndicadoresUsoPotreroModal
          potreroId={indicadoresUsoPotreroModal}
          onClose={() => setIndicadoresUsoPotreroModal()}
        />
      ) : null}
      {indicadoresProyeccionModal ? (
        <IndicadoresProyeccionModal
          indicadoresEstablecimiento={indicadoresEstablecimiento}
          onClose={() => setIndicadoresProyeccionModal(false)}
        />
      ) : null}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={true}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

export default IndicadoresMonitoreo;
