import React from "react";
import { withRouter } from "react-router-dom";

import { Button } from "reactstrap";
import { useAuth } from "../../providers/authProvider";

const ROLE_ADMIN = "1";

const Page500 = ({ history }) => {
  const [authContext] = useAuth();
  return (
    <div className="text-center">
      <h1 className="display-1 font-weight-bold">500</h1>
      <p className="h1">Error de servidor.</p>
      <p className="h2 font-weight-normal mt-3 mb-4">
        El servidor encontró algo inesperado que no le permitió completar la
        solicitud.
      </p>
      <Button
        onClick={() =>
          authContext.currentUser
            ? authContext.currentUser.rol === ROLE_ADMIN
              ? history.push("/back")
              : history.push("/portal/establecimientos")
            : history.push("/auth/sign-in")
        }
        color="primary"
        size="lg"
      >
        Volver al sitio web
      </Button>
    </div>
  );
};

export default withRouter(Page500);
