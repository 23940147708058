import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap";
import Loader from "../../components/Loader";
import { GoogleMap, Polygon, InfoBox } from "@react-google-maps/api";
import { commonApi } from "../../services/commonServices";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const URUGUAY_COORDINATES = {
  lat: -32.522779,
  lng: -55.765835,
};

const INITIAL_ZOOM = 7;

const mapToPaths = (data) =>
  (data || []).map((c) => {
    return { lat: c[1], lng: c[0] };
  });

const Departamentos = () => {
  const [loading, setLoading] = useState(true);
  const [departamentos, setDepartamentos] = useState([]);
  const [tooltips, setTooltips] = useState({});

  const [map, setMap] = useState(null);

  const tooltip = (item) => (
    <div
      id={`tooltip-${item.id}`}
      className={`${
        item.nombre ? "" : "d-none"
      } font-size-1-1 px-2 py-3 border rounded bg-success text-white font-weight-bold text-center`}
    >
      {item.nombre}
    </div>
  );

  useEffect(() => {
    commonApi.getDepartamentos({ includeGeoInfo: true }).then((result) => {
      setDepartamentos(result);
      setLoading(false);
    });
  }, []);

  const onMapLoad = (map) => {
    map.setCenter(URUGUAY_COORDINATES);
    map.setZoom(INITIAL_ZOOM);
    setMap(map);
  };

  return (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0">
                Mapa de Departamentos
              </CardTitle>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader />
              ) : (
                <div className="border rounded">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    onLoad={onMapLoad}
                    options={{
                      mapTypeId: map?.mapTypeId || "hybrid",
                      zoom: map?.zoom || INITIAL_ZOOM,
                      streetViewControl: false,
                      center: map?.center || URUGUAY_COORDINATES,
                    }}
                  >
                    {(departamentos || []).map((departamento, i) =>
                      departamento.infoGeo?.coordinates ? (
                        <div key={i}>
                          <Polygon
                            key={i}
                            onMouseOver={() =>
                              setTooltips({
                                ...tooltips,
                                [departamento.id]: true,
                              })
                            }
                            onMouseOut={() =>
                              setTooltips({
                                ...tooltips,
                                [departamento.id]: false,
                              })
                            }
                            path={mapToPaths(
                              departamento.infoGeo?.coordinates[0]
                            )}
                          />
                          <InfoBox
                            options={{
                              closeBoxURL: "",
                              enableEventPropagation: true,
                              disableAutoPan: false,
                              maxWidth: 150,
                              alignBottom: false,
                              zIndex: null,
                              boxStyle: {
                                opacity: tooltips[departamento.id] ? 0.8 : 0,
                                width: "auto",
                              },
                            }}
                            position={{
                              lat: parseFloat(departamento.centerLat),
                              lng: parseFloat(departamento.centerLng),
                            }}
                          >
                            {tooltip(departamento)}
                          </InfoBox>
                        </div>
                      ) : null
                    )}
                  </GoogleMap>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Departamentos;
