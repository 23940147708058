import React, { useState, useEffect } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Row,
  Form,
  UncontrolledAlert,
  CustomInput,
} from "reactstrap";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import { establecimientosApi } from "../../../services/establecimientosServices";
import Loader from "../../Loader";
import { utils } from "../../../utils/utils";
import { commonApi } from "../../../services/commonServices";
import InformationModal from "../../InformationModal";

const ESTADO_ABIERTO = "1";
const ESTADO_CERRADO = "2";

const EstablecimientoModal = ({ establecimiento, onClose }) => {
  const [loading, setLoading] = useState();
  const [establecimientosContext, setEstablecimientosContext] =
    useEstablecimientos();

  const [nombre, setNombre] = useState(establecimiento?.nombre || "");
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [exists, setExists] = useState(false);
  const [departamentos, setDepartamentos] = useState([]);
  const [departamentoId, setDepartamentoId] = useState(
    establecimiento?.departamentoId
  );
  const [estado, setEstado] = useState(
    establecimiento?.estado || ESTADO_ABIERTO
  );

  useEffect(() => {
    setLoading(true);
    commonApi.getDepartamentosPublic({}).then((result) => {
      setDepartamentos(result);
      setLoading(false);
    });
  }, []);

  const onNombreChange = (event) => {
    const name = utils.sanitizeString(event.currentTarget.value.toLowerCase());
    setExists(
      establecimientosContext.allEstablecimientos.find(
        (e) =>
          e.nombre.toLowerCase() === name &&
          (establecimiento ? establecimiento.id !== e.id : true)
      ) !== undefined
    );
    setNombre(name);
  };

  const onDismiss = () => {
    setNombre("");
    setExists(establecimiento?.nombre || "");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    if (establecimiento) {
      establecimientosApi
        .updateEstablecimiento({
          nombre,
          estado,
          id: establecimiento.id,
        })
        .then(() => {
          setLoading(false);
          setEstablecimientosContext({
            selected: establecimientosContext.selected,
            refreshSelector: true,
            refresh: !establecimientosContext.refresh,
          });
          onClose();
        })
        .catch((err) => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Error",
            rawBody: true,
            body: <span>{err.response?.data[0].msg}</span>,
          });
        });
    } else {
      establecimientosApi
        .createEstablecimiento({
          nombre,
          estado,
          departamentoId,
        })
        .then(() => {
          setLoading(false);
          setEstablecimientosContext({
            refreshSelector: true,
            refresh: !establecimientosContext.refresh,
          });
          onClose();
        })
        .catch((err) => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Error",
            rawBody: true,
            body: <span>{err.response?.data[0].msg}</span>,
          });
        });
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader>
        {establecimiento ? "Editar" : "Nuevo"} Establecimiento
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup row>
                <Label sm={4} className="text-left">
                  Nombre
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    type="text"
                    maxLength="50"
                    placeholder="Ingresa el nombre.."
                    onChange={onNombreChange}
                    value={nombre}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-left">
                  Departamento
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={8}>
                  <CustomInput
                    disabled={establecimiento?.id && departamentoId}
                    required={true}
                    id="departamentoSelect"
                    type="select"
                    name="departamentoSelect"
                    onChange={(event) =>
                      setDepartamentoId(event.currentTarget.value)
                    }
                    value={departamentoId || ""}
                  >
                    <option value="">Selecciona un departamento...</option>
                    {departamentos.map((departamento) => (
                      <option key={departamento.id} value={departamento.id}>
                        {departamento.nombre}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup row className="mb-0">
                <Label sm={4} className="text-left">
                  Estado
                </Label>
                <Col sm={8}>
                  <Button
                    color="light"
                    type="button"
                    size="sm"
                    className="rounded estado-selector font-weight-bold text-primary d-flex"
                  >
                    <CustomInput
                      type="checkbox"
                      id="estadoCheckAbierto"
                      label="Abierto"
                      checked={estado === ESTADO_ABIERTO}
                      onChange={(event) =>
                        setEstado(
                          event.currentTarget.checked
                            ? ESTADO_ABIERTO
                            : ESTADO_CERRADO
                        )
                      }
                      className="cursor-pointer d-flex align-items-center font-weight-bold text-primary"
                    />
                    <CustomInput
                      type="checkbox"
                      id="estadoCheckCerrado"
                      label="Cerrado"
                      checked={estado === ESTADO_CERRADO}
                      onChange={(event) =>
                        setEstado(
                          event.currentTarget.checked
                            ? ESTADO_CERRADO
                            : ESTADO_ABIERTO
                        )
                      }
                      className="ml-4 cursor-pointer d-flex align-items-center font-weight-bold text-primary"
                    />
                  </Button>
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <UncontrolledAlert
              toggle={onDismiss}
              isOpen={exists}
              color="warning"
            >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span>Ya existe otro establecimiento con este nombre.</span>
              </div>
            </UncontrolledAlert>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={() => onClose(false)}>
                Cancelar
              </Button>{" "}
              <Button disabled={exists} color={"primary"} type="submit">
                {establecimiento ? "Guardar" : "Confirmar"}
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EstablecimientoModal;
