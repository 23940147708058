import React from "react";
import { CardBody } from "reactstrap";
import Main from "../components/Main";

import background from "../assets/images/login.jpg";

const Auth = ({ children }) => (
  <>
    <Main className="auth-fluid">
      <div
        className="auth-fluid-right text-center"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="auth-user-testimonial d-none">
          <h1 className="text-white">iPasto</h1>
          <span className="text-white"></span>
        </div>
      </div>
      <div className="auth-fluid-form-box">
        <div className="align-items-center d-flex h-100 flex-column">
          <CardBody className="d-flex flex-column justify-content-between flex-grow-1 col-12 px-0">
            {children}
            <footer className="footer footer-alt pb-0 d-flex justify-content-between px-0">
              <span className="text-muted letter-spacing-1p">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.planagropecuario.org.uy"
                >
                  {new Date().getFullYear()} - Instituto Plan Agropecuario
                </a>
              </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.planagropecuario.org.uy/web/cont%C3%A1ctanos.html"
              >
                Contáctanos
              </a>
            </footer>
          </CardBody>
        </div>
      </div>
    </Main>
  </>
);

export default Auth;
