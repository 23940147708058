import React, { useEffect, useState } from "react";

import {
  Container,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  Input,
  InputGroupText,
  Button,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { RefreshCw } from "react-feather";
import Loader from "../../components/Loader";
import InformationModal from "../../components/InformationModal";

import { establecimientosApi } from "../../services/establecimientosServices";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Establecimientos = () => {
  const [data, setData] = useState({});

  const [sortBy, setSortBy] = useState("grupo");
  const [direction, setDirection] = useState("asc");
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [refresh, setRefresh] = useState();

  const [loading, setLoading] = useState(true);

  const [search, setSearch] = useState("");

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    setLoading(true);
    establecimientosApi
      .getEstablecimientos({
        sortBy,
        direction,
        pageSize: sizePerPage,
        search,
      })
      .then((establecimientos) => {
        setData(establecimientos);
        setLoading(false);
      });
  }, [refresh, sortBy, direction, page, sizePerPage, search]);

  const onSort = (newSortBy, newDirection) => {
    if (sortBy === newSortBy && direction === newDirection) {
      return;
    }
    setDirection(newDirection);
    setSortBy(newSortBy);
    setPage(1);
  };

  return (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Card className="d-flex">
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Establecimientos</h3>
            <small className="text-muted ml-2 pt-1">({data?.count || 0})</small>
          </div>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup size="m" className="mr-3">
              <Input
                className="rounded-left rounded-right-0"
                maxLength="50"
                placeholder="Buscar.."
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(1);
                }}
              />
              <InputGroupText className="rounded-right rounded-left-0 search-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh"
              color="primary"
              onClick={() => setRefresh(!refresh)}
            >
              <RefreshCw />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader size="sm" />
          ) : data?.count ? (
            <>
              <BootstrapTable
                bootstrap4
                remote
                bordered={false}
                keyField="id"
                data={data.data}
                columns={[
                  {
                    dataField: "id",
                    text: "Id",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    sort: true,
                    onSort,
                  },
                  {
                    dataField: "nombre",
                    text: "Nombre",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                  },
                  {
                    dataField: "departamento.nombre",
                    text: "Departamento",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    formatter: (cell, row) => cell || "-",
                  },
                  {
                    dataField: "grupo",
                    text: "Grupo",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                  },
                ]}
                defaultSorted={[{ dataField: sortBy, order: direction }]}
                onTableChange={() => {}}
                pagination={paginationFactory({
                  page,
                  totalSize: data.count,
                  sizePerPage,
                  sizePerPageList: [
                    5,
                    10,
                    25,
                    50,
                    { text: "Todos", value: data.count },
                  ],
                  onPageChange: (page) => setPage(page),
                  onSizePerPageChange: (sizePerPage) =>
                    setSizePerPage(sizePerPage),
                })}
              />
            </>
          ) : (
            <div className="text-center">No hay resultados</div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default Establecimientos;
