import React, { useState } from "react";

import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";

import DateRangePicker from "../../DateRangePicker";
import moment from "moment";

const TCMensualTotalChartFiltersModal = ({
  defaultFechaComienzo,
  defaultFechaFin,
  onSubmit,
  onClose,
}) => {
  const [fechaComienzo, setFechaComienzo] = useState(defaultFechaComienzo);
  const [fechaFin, setFechaFin] = useState(defaultFechaFin);

  return (
    <Modal size="md" isOpen={true} onClosed={() => onClose()}>
      <ModalHeader>
        <div className="d-flex flex-column mb-n2">
          <span>Rango de fechas</span>
          <small className="text-danger font-size-65">
            El rango de fechas no puede ser mayor a un año.
          </small>
        </div>
      </ModalHeader>
      <ModalBody className="text-center mb-n3">
        <DateRangePicker
          dateFormat={"YYYY-MM"}
          enabledOpenEnd={false}
          fechaComienzo={fechaComienzo}
          fechaFin={fechaFin}
          maxRangeInMonths={12}
          onChangeFechaComienzo={(fc) => {
            let nFechaComienzo = fc;
            if (moment().isBefore(nFechaComienzo)) {
              nFechaComienzo = moment();
            }
            const nFechaFin = fechaFin
              ? fechaFin.isBefore(nFechaComienzo)
                ? nFechaComienzo
                : Math.abs(fechaFin.diff(nFechaComienzo, "months")) < 12
                ? fechaFin
                : moment(nFechaComienzo).add(
                    11 * (fechaFin.isBefore(nFechaComienzo, "month") ? -1 : 1),
                    "months"
                  )
              : nFechaComienzo;
            setFechaFin(nFechaFin);
            setFechaComienzo(nFechaComienzo);
          }}
          onChangeFechaFin={(nFechaFin) => setFechaFin(nFechaFin)}
        />
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color={"secondary"} onClick={() => onClose()}>
              Cerrar
            </Button>{" "}
            <Button
              color={"primary"}
              onClick={() => onSubmit({ fechaComienzo, fechaFin })}
            >
              Aplicar
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default TCMensualTotalChartFiltersModal;
