import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";

const CustomCheckbox = ({ isLoading, label, onClick, checked }) => {
  return isLoading ? (
    <div className="min-width-50">
      <Loader size="sm" />
    </div>
  ) : (
    <div
      className="d-flex cursor-pointer"
      onClick={() => (onClick ? onClick() : null)}
    >
      <div className="bg-primary border border-primary rounded px-1">
        {checked ? (
          <FontAwesomeIcon icon={faCheck} className="pt-1 text-white" />
        ) : (
          <FontAwesomeIcon icon={faCheck} className="pt-1 text-primary" />
        )}
      </div>
      {label ? <span className="ml-2">{label}</span> : null}
    </div>
  );
};

export default CustomCheckbox;
