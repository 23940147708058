import React, { useState } from "react";

import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
} from "reactstrap";

import brand from "../../assets/images/logo-small.jpg";

import { adminRoutes } from "../../routes";
import { withRouter } from "react-router-dom";
import {
  faDatabase,
  faMapPin,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../providers/authProvider";
import { authApi } from "../../services/authServices";
import socketIOClient from "socket.io-client";
import config from "../../config/config";
import XLSX from "xlsx";
import Loader from "../Loader";
import { commonApi } from "../../services/commonServices";
import { utils } from "../../utils/utils";
import ConfirmationModalWithInput from "../ConfirmationModalWithInput";
import InformationModal from "../InformationModal";
import Cookies from "js-cookie";

let socket;

const NavbarMenu = withRouter(({ history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [authContext, setAuthContext] = useAuth();

  const [reportProgress, setReportProgress] = useState();
  const [reportLoading, setReportLoading] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
    confirmText: null,
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const onGenerateReportSocketActions = (socketToken, callback) => {
    socket = socketIOClient(config.apiURL, { transports: ["websocket"] });

    socket.emit("signup", socketToken);

    socket.on("signupSuccess", () => {
      callback();
    });

    socket.on("newEntry", (data) => {
      setReportProgress(data);
      if (data.report) {
        setReportLoading(false);
        socket.disconnect();
        setReportProgress();
        const worksheet = XLSX.utils.aoa_to_sheet(data.report);
        const new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "Base de Datos");
        XLSX.writeFile(
          new_workbook,
          `db-iPasto-${utils.formatDate(null, "DD-MM-YYYY")}.xlsx`
        );
      }
    });

    socket.on("disconnect", (data) => {
      console.log("disconnected", data);
    });
  };

  const logout = () => {
    authApi.logout("admin");
    Cookies.remove("c_auth");
    setAuthContext({ currentUser: null, advisements: null });
    history.push("/auth/sign-in");
  };

  const onGenerateReport = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (reportLoading) {
      return;
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: (password) => {
        setConfirmationModal(initConfirmationModal);
        setReportLoading(true);
        setInformationModal({
          isOpen: true,
          title: "Exportar Base de Datos",
          body: "La base de datos se está exportando, puedes ver el progreso en la parte superior derecha.",
        });
        onGenerateReportSocketActions(authContext.currentUser.socketToken, () =>
          commonApi
            .exportDatabase({
              password,
              socketToken: authContext.currentUser.socketToken,
            })
            .then(() => {})
            .catch((err) => {
              if (socket) {
                socket.disconnect();
              }
              setReportLoading(false);
              setConfirmationModal(initConfirmationModal);
              if (err.response?.data?.error) {
                setInformationModal({
                  isOpen: true,
                  title: "Error",
                  rawBody: true,
                  body: `<p>${err.response?.data?.error}</p><span>Intenta nuevamente.</span>`,
                  onClose: () => {
                    onGenerateReport(event);
                    setInformationModal({
                      isOpen: false,
                      title: "",
                      body: "",
                      onClose: null,
                    });
                  },
                });
              }
              return;
            })
        );
      },
      confirmTextType: "password",
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Descargar Base`,
      confirmColor: "success",
      confirmTextPlaceholder: "Contraseña..",
      body: `Introduce la contraseña para descargar la base:`,
    });
  };

  return (
    <>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModalWithInput {...confirmationModal} />
      ) : null}
      {reportLoading ? (
        <div className="d-flex min-width-300 justify-content-end mr-4 align-items-center">
          <div className="min-width-50">
            <Loader size="sm" align="end" />
          </div>
          <div className="flex-shrink-0 text-muted d-flex">
            <span>Descargando base...</span>
            <div className="min-width-50 text-right ml-2">
              {reportProgress
                ? (
                    (reportProgress.processed / reportProgress.total) *
                    100
                  ).toFixed(2)
                : 0}
              %
            </div>
          </div>
        </div>
      ) : null}
      <Dropdown
        direction="left"
        className="sidebar-toggle d-flex mr-2"
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
        inNavbar={true}
      >
        <DropdownToggle tag="div" className="d-flex align-items-center">
          <i className="hamburger align-self-center" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => history.push("/back/departamentos")}>
            <FontAwesomeIcon className="mr-2" icon={faMapPin} />
            Departamentos
          </DropdownItem>
          <DropdownItem
            onClick={() => history.push("/back/secciones-policiales")}
          >
            <FontAwesomeIcon className="mr-2" icon={faMapPin} />
            Secciones Policiales
          </DropdownItem>
          <DropdownItem
            onClick={() => history.push("/back/zonas-geomorfologicas")}
          >
            <FontAwesomeIcon className="mr-2" icon={faMapPin} />
            Zonas Geomorfológicas
          </DropdownItem>
          <DropdownItem onClick={onGenerateReport}>
            <FontAwesomeIcon className="mr-2" icon={faDatabase} />
            Descargar Base
          </DropdownItem>
          <DropdownItem onClick={logout}>
            <FontAwesomeIcon className="mr-2" icon={faSignOutAlt} />
            Salir
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
});

const NavbarElements = withRouter(({ history }) => {
  return (
    <Nav className="flex-grow-1 mr-auto" navbar>
      <div className="rounded border-right border-bottom mr-4 sidebar-brand d-flex justify-content-center align-items-center p-2 bg-white">
        <img
          hidden
          src={brand}
          className="rounded p-0 max-width-100"
          alt={"iPasto"}
        />
        <span className="text-success">iPasto</span>
      </div>
      {adminRoutes
        .filter((r) => r.navbar)
        .map((route, i) => (
          <NavItem key={i} className="d-flex align-items-center">
            <NavLink
              className={`text-dark ${
                history.location.pathname.indexOf(route.path) > -1
                  ? "active text-underline"
                  : ""
              }`}
              href="#"
              onClick={() => history.push(route.path)}
            >
              {route.name}
            </NavLink>
          </NavItem>
        ))}
    </Nav>
  );
});

const NavbarComponent = () => {
  return (
    <Navbar expand className="navbar-theme pl-0">
      {
        <React.Fragment>
          <NavbarElements />
          <NavbarMenu />
        </React.Fragment>
      }
    </Navbar>
  );
};

export default NavbarComponent;
