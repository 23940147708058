import React, { useEffect, useState } from "react";

import { defaults, Line } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "chartjs-plugin-colorschemes";

import Loader from "../../Loader";
import moment from "moment";

import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import { establecimientosApi } from "../../../services/establecimientosServices";

import { Button } from "reactstrap";

import TCMensualTotalChartFiltersModal from "./TCMensualTotalChartFiltersModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { utils } from "../../../utils/utils";
import { commonApi } from "../../../services/commonServices";
import InformationModal from "../../InformationModal";

defaults.global.defaultFontFamily = "Rubik";

const DATASET_INDEX_ACTUAL = 0;
const DATASET_INDEX_MAX = 1;
const DATASET_INDEX_MIN = 2;

const getChartData = (tcMensualTotal) => ({
  labels: tcMensualTotal.map((tc) =>
    moment()
      .set("month", tc.month - 1)
      .set("year", tc.anio)
      .locale("es-es")
      .format("MMM YY")
  ),
  datasets: [
    {
      label: "TC Actual",
      backgroundColor: "transparent",
      borderColor: "green",
      data: tcMensualTotal.map((tc) => tc.tcActual),
    },
    {
      label: "TC Máximo histórico",
      backgroundColor: "transparent",
      borderColor: "blue",
      data: tcMensualTotal.map((tc) => tc.tcMax.value),
    },
    {
      label: "TC Mínimo histórico",
      backgroundColor: "transparent",
      borderColor: "red",
      data: tcMensualTotal.map((tc) => tc.tcMin.value),
    },
    {
      label: "TC Promedio histórico",
      backgroundColor: "transparent",
      borderColor: "yellow",
      data: tcMensualTotal.map((tc) => tc.tcPromedio),
    },
  ],
});

const getChartOptions = (tcMensualTotal, isPublic) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        title: function ([tooltipItem]) {
          const months = tcMensualTotal.map((e) => e.month - 1);
          if (tooltipItem.datasetIndex === DATASET_INDEX_ACTUAL) {
            const [, year] = tooltipItem.label.match(/.*\. (\d\d)/);
            return `${utils.meses[months[tooltipItem.index]]} ${year}`;
          } else if (tooltipItem.datasetIndex === DATASET_INDEX_MAX) {
            const entry = tcMensualTotal[months[tooltipItem.index]];
            return `${utils.meses[months[tooltipItem.index]]} ${
              entry?.tcMax.anio || ""
            }`;
          } else if (tooltipItem.datasetIndex === DATASET_INDEX_MIN) {
            const entry = tcMensualTotal[months[tooltipItem.index]];
            return `${utils.meses[months[tooltipItem.index]]} ${
              entry?.tcMin.anio || ""
            }`;
          } else {
            return `${utils.meses[months[tooltipItem.index]]}`;
          }
        },
      },
    },
    plugins: {
      datalabels: {
        align: "top",
        offset: 8,
        display: "auto",
        font: {
          weight: "bold",
          familiy: "Rubik",
          size: 14,
        },
      },
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "PPNA (kg/ha.dia)",
          },
        },
      ],
    },
  };
  return options;
};

const TCMensualTotalChart = ({
  isPublic,
  zonas,
  tipoUsoSuelos,
  potreros,
  departamentos,
  seccionesPoliciales,
}) => {
  const [establecimientosContext] = useEstablecimientos();

  const [loading, setLoading] = useState(false);
  const [filtrosModal, setFiltrosModal] = useState(false);
  const [error, setError] = useState(false);

  const [fechaComienzo, setFechaComienzo] = useState(
    moment().utc().startOf("year")
  );
  const [fechaFin, setFechaFin] = useState(moment().utc().endOf("year"));

  const [tcMensualTotal, setTcMensualTotal] = useState([]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  useEffect(() => {
    if (
      !isPublic &&
      establecimientosContext.selected &&
      (tipoUsoSuelos || []).length &&
      (potreros || []).length
    ) {
      setLoading(true);
      establecimientosApi
        .getSeguimientoForrajeroTcMensualTotal(
          establecimientosContext.selected,
          {
            fechaComienzo: utils.formatDate(fechaComienzo, "YYYY-MM-DD"),
            fechaFin: utils.formatDate(fechaFin, "YYYY-MM-DD"),
            potreroId: potreros,
            tipoUsoSueloId: tipoUsoSuelos,
            zonaId: zonas,
          }
        )
        .then((result) => {
          setFiltrosModal(false);
          setLoading(false);
          if (result.error) {
            setError(result.error);
          } else {
            setError();
            setTcMensualTotal(result);
          }
        })
        .catch((err) => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Error",
            rawBody: true,
            body: (
              <span>
                ${err?.response?.data?.message || "Ha habido un error"}
              </span>
            ),
          });
        });
    }
  }, [
    isPublic,
    establecimientosContext.selected,
    fechaComienzo,
    fechaFin,
    potreros,
    tipoUsoSuelos,
    zonas,
  ]);

  useEffect(() => {
    if (isPublic) {
      setLoading(true);
      commonApi
        .getSeguimientoForrajeroTcMensualTotal({
          fechaComienzo: utils.formatDate(fechaComienzo, "YYYY-MM-DD"),
          fechaFin: utils.formatDate(fechaFin, "YYYY-MM-DD"),
          tipoUsoSueloId: tipoUsoSuelos,
          departamentoId: departamentos,
          seccionPolicialId: seccionesPoliciales,
          zonaId: zonas,
        })
        .then((result) => {
          setFiltrosModal(false);
          setLoading(false);
          if (result.error) {
            setError(result.error);
          } else {
            setError();
            setTcMensualTotal(result);
          }
        });
    }
  }, [
    isPublic,
    fechaComienzo,
    fechaFin,
    zonas,
    tipoUsoSuelos,
    departamentos,
    seccionesPoliciales,
  ]);

  const data = getChartData(tcMensualTotal);
  const options = getChartOptions(tcMensualTotal, isPublic);

  return loading ? (
    <div className="min-width-50 mt-2">
      <Loader size="sm" />
    </div>
  ) : (
    <>
      <div className="d-flex justify-content-between align-items-baseline">
        <div>
          <h5 className="card-title mb-0">Productividad Mensual Total</h5>
          <small className="text-muted">
            desde {utils.formatDate(fechaComienzo, "MMM YY")} hasta{" "}
            {utils.formatDate(fechaFin, "MMM YY")}
          </small>
        </div>
        <Button
          className="rounded"
          color="success"
          onClick={() => setFiltrosModal(true)}
          size="sm"
        >
          <FontAwesomeIcon icon={faFilter} />
        </Button>
      </div>
      {error ? (
        <div className="text-center">{error}</div>
      ) : (
        <div
          className={`${isPublic ? "" : "h-100"} d-flex`}
          style={{
            height: isPublic
              ? document.getElementsByTagName("body")[0].offsetHeight - 50
              : "auto",
          }}
        >
          <Line data={data} options={options} />
        </div>
      )}
      {filtrosModal ? (
        <TCMensualTotalChartFiltersModal
          defaultFechaComienzo={fechaComienzo}
          defaultFechaFin={fechaFin}
          onSubmit={({ fechaComienzo, fechaFin }) => {
            setFechaComienzo(fechaComienzo);
            setFechaFin(fechaFin);
            setFiltrosModal(false);
          }}
          onClose={() => setFiltrosModal(false)}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          rawBody={informationModal.rawBody}
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  rawBody: false,
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : null}
    </>
  );
};

export default TCMensualTotalChart;
