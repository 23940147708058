import React, { useState } from "react";
import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Navbar from "../components/admin/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { Redirect } from "react-router-dom";
import { useAuth } from "../providers/authProvider";
import { authApi } from "../services/authServices";
import Loader from "../components/Loader";

const ROLE_ADMIN = "1";

const Admin = ({ children }) => {
  const [authContext, setAuthContext] = useAuth();
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  if (redirectToLogin) {
    return <Redirect to={`/auth/sign-in`} />;
  }

  if (!authContext.currentUser) {
    authApi.validate("admin").then((result) => {
      if (!result || result.rol !== ROLE_ADMIN) {
        return setRedirectToLogin(true);
      }
      setAuthContext({ currentUser: result });
    });
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Wrapper className="overflow-x-visible">
        <Main className="min-width-lg d-flex flex-column justify-content-between">
          <div>
            <Navbar />
            <Content>{children}</Content>
          </div>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Admin;
