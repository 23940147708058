import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sortBy: "fecha",
  direction: "asc",
  sizePerPage: 10,
  page: 1,
};

const AlimentosExtraContext = createContext(initialState);

const AlimentosExtraProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <AlimentosExtraContext.Provider value={stateAndDispatch}>
      {children}
    </AlimentosExtraContext.Provider>
  );
};

export const useAlimentosExtra = () => useContext(AlimentosExtraContext);

export { AlimentosExtraContext, AlimentosExtraProvider };
