import React, { useState } from "react";

import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { Redirect } from "react-router-dom";
import { useAuth } from "../providers/authProvider";
import { authApi } from "../services/authServices";
import Loader from "../components/Loader";
import Sidebar from "../components/customer/Sidebar";
import { useEstablecimientos } from "../providers/establecimientosProvider";

const ROLE_CUSTOMER = "2";

const Customer = ({ children }) => {
  const [authContext, setAuthContext] = useAuth();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [, setEstablecimientosContext] = useEstablecimientos();

  if (redirectToLogin) {
    return <Redirect to={`/auth/sign-in`} />;
  }

  if (!authContext.currentUser) {
    authApi.validate("customer").then((result) => {
      if (!result || result.rol !== ROLE_CUSTOMER) {
        setEstablecimientosContext({
          allEstablecimientos: null,
          selected: null,
        });
        return setRedirectToLogin(true);
      }
      setAuthContext({ currentUser: result });
      setEstablecimientosContext({ selected: result.defaultEstablecimiento });
    });
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Wrapper className="overflow-x-hidden">
        <Sidebar />
        <Main className="d-flex flex-column justify-content-between">
          <Content>{children}</Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Customer;
