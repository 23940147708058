import React, { useState } from "react";

import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
  InputGroup,
  Input,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MoreVertical, RefreshCw } from "react-feather";
import Loader from "../../../components/Loader";
import { usePotreros } from "../../../providers/potrerosProvider";
import { potrerosApi } from "../../../services/potrerosServices";
import PotrerosCardsView from "../../../components/customer/potreros/PotrerosCardsView";

import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import ConfirmationModalWithInput from "../../ConfirmationModalWithInput";
import { utils } from "../../../utils/utils";
import { useAuth } from "../../../providers/authProvider";

const PoterosListView = ({ loading }) => {
  const [authContext] = useAuth();
  const [establecimientosContext] = useEstablecimientos();
  const [potrerosContext, setPotrerosContext] = usePotreros();

  const [potrerosLoading, setPotrerosLoading] = useState({});

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onSort = (sortBy, direction) => {
    if (
      potrerosContext.sortBy === sortBy &&
      potrerosContext.direction === direction
    ) {
      return;
    }
    setPotrerosContext({ sortBy, direction, page: 1 });
  };

  const onConfirmDelete = (potrero) => {
    setPotrerosLoading({ [potrero.id]: true });
    potrerosApi
      .deletePotrero(potrero.establecimientoId, { id: potrero.id })
      .then(() => {
        potrerosContext.potreros.data.splice(
          potrerosContext.potreros.data.findIndex((e) => e.id === potrero.id),
          1
        );
        const potreros = {
          ...potrerosContext,
          potreros: {
            ...potrerosContext.potreros,
            data: [...potrerosContext.potreros.data],
            count: potrerosContext.potreros.count - 1,
          },
        };
        setPotrerosContext({ ...potreros });
        setPotrerosLoading({ [potrero.id]: false });
        setConfirmationModal(initConfirmationModal);
      });
  };

  const onDelete = (potrero) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => onConfirmDelete(potrero),
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Borrar ${potrero.nombre}`,
      confirmText: potrero.nombre,
      confirmColor: "danger",
      confirmTextPlaceholder: "Nombre..",
      body: `Para confirmar que quieres borrar el potrero seleccionado escribe su nombre en el campo debajo y presiona confirmar.`,
    });
  };

  const actions = (potrero) => {
    return potrerosLoading[potrero.id] ? (
      <Loader size="sm" align="end" />
    ) : authContext.currentUser.ingresoDatosHabilitado ? (
      <UncontrolledDropdown className="d-inline-block">
        <DropdownToggle tag="a">
          <MoreVertical className="text-primary" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            onClick={() => setPotrerosContext({ selectedPotrero: potrero })}
          >
            Editar
          </DropdownItem>
          <DropdownItem onClick={() => onDelete(potrero)}>Borrar</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    ) : null;
  };

  return (
    <>
      <Card className="d-none d-lg-flex">
        <CardHeader>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup className="mr-3 col-4 px-0" size="m">
              <Input
                maxLength="50"
                value={potrerosContext.search}
                onChange={(evt) =>
                  setPotrerosContext({ search: evt.target.value, page: 1 })
                }
                className="border-0"
                placeholder="Buscar.."
              />
            </InputGroup>
            <span className="mr-1 d-flex">
              <span className="cursor-pointer mx-2 d-flex align-items-center">
                <RefreshCw
                  hidden
                  onClick={() =>
                    setPotrerosContext({ refresh: !potrerosContext.refresh })
                  }
                />
              </span>
              <small className="text-muted d-flex align-items-center">
                Mostrando {potrerosContext.potreros?.count || 0} potreros
              </small>
            </span>
          </div>
        </CardHeader>
        <CardBody>
          {!establecimientosContext.selected ? (
            <div className="text-center col-12">
              Ningún establecimiento seleccionado
            </div>
          ) : loading ? (
            <Loader size="sm" />
          ) : potrerosContext.potreros.count ? (
            <>
              <BootstrapTable
                bootstrap4
                remote
                bordered={false}
                keyField="id"
                data={potrerosContext.potreros.data}
                columns={[
                  {
                    dataField: "id",
                    text: "Id",
                    headerClasses: "text-truncate text-muted font-size-75",
                    classes: "text-truncate",
                    sort: true,
                    onSort,
                  },
                  {
                    dataField: "nombre",
                    text: "Nombre",
                    headerClasses: "text-truncate text-muted font-size-75",
                    classes: "text-truncate",
                    sort: true,
                    onSort,
                  },
                  {
                    dataField: "departamento.nombre",
                    text: "Departamento",
                    headerClasses: "text-truncate text-muted font-size-75",
                    classes: "text-truncate",
                    sort: true,
                    onSort,
                  },
                  {
                    dataField: "seccionPolicial.seccion",
                    text: "Sección Policial",
                    headerClasses: "text-truncate text-muted font-size-75",
                    classes: "text-truncate",
                    sort: true,
                    formatter: (cell, potrero) =>
                      potrero.seccionPolicial.default
                        ? "Ninguna"
                        : `${utils.ordinalSuffix(
                            potrero.seccionPolicial.seccion
                          )}`,
                    onSort,
                  },
                  {
                    dataField: "zonaGeomorfologica.nombre",
                    text: "Zona Geomorfológica",
                    headerClasses: "text-truncate text-muted font-size-75",
                    classes: "text-truncate",
                    sort: true,
                    onSort,
                  },
                  {
                    dataField: "area",
                    text: "Area",
                    headerClasses: "text-truncate text-muted font-size-75",
                    classes: "text-truncate",
                    sort: true,
                    formatter: (cell, potrero) =>
                      `${utils.formatDecimal(potrero.area, 1)} ha.`,
                    onSort,
                  },
                  {
                    dataField: "",
                    text: "",
                    sort: false,
                    classes: "text-right",
                    headerStyle: () => {
                      return { width: "50px" };
                    },
                    formatter: (cell, potrero) => actions(potrero),
                    formatExtraData: { ...potrerosLoading },
                  },
                ]}
                defaultSorted={[
                  {
                    dataField: potrerosContext.sortBy,
                    order: potrerosContext.direction,
                  },
                ]}
                onTableChange={() => {}}
                pagination={paginationFactory({
                  page: potrerosContext.page,
                  totalSize: potrerosContext.potreros.count,
                  sizePerPage: potrerosContext.sizePerPage,
                  sizePerPageList: [
                    5,
                    10,
                    25,
                    50,
                    { text: "Todos", value: potrerosContext.potreros.count },
                  ],
                  onPageChange: (page) => setPotrerosContext({ page }),
                  onSizePerPageChange: (sizePerPage) =>
                    setPotrerosContext({ page: 1, sizePerPage }),
                })}
              />
            </>
          ) : (
            <div className="text-center font-weight-bold">
              No hay potreros para el establecimiento seleccionado
            </div>
          )}
          {confirmationModal.isOpen ? (
            <ConfirmationModalWithInput {...confirmationModal} />
          ) : null}
        </CardBody>
      </Card>
      <PotrerosCardsView
        loading={loading}
        potrerosLoading={potrerosLoading}
        onDelete={onDelete}
      />
    </>
  );
};

export default PoterosListView;
