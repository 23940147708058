import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sortBy: "potrero.nombre",
  direction: "asc",
  sizePerPage: 10,
  page: 1,
  search: "",
};

const EstadosSituacionContext = createContext(initialState);

const EstadosSituacionProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <EstadosSituacionContext.Provider value={stateAndDispatch}>
      {children}
    </EstadosSituacionContext.Provider>
  );
};

export const useEstadosSituacion = () => useContext(EstadosSituacionContext);

export { EstadosSituacionContext, EstadosSituacionProvider };
