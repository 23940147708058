import React, { useCallback, useEffect, useState } from "react";

import {
  UncontrolledTooltip,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  CustomInput,
} from "reactstrap";

import Loader from "../../../Loader";
import InformationModal from "../../../InformationModal";
import "react-datetime/css/react-datetime.css";
import { potrerosApi } from "../../../../services/potrerosServices";
import { utils } from "../../../../utils/utils";
import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Datetime from "react-datetime";
import {
  initialState,
  useInformacionPotreroModal,
} from "../../../../providers/informacionPotreroModalProvider";
import AnimalesList from "./AnimalesList";
import moment from "moment";
import { useEstadosSituacion } from "../../../../providers/estadosSituacionProvider";
import { useMovimiento } from "../../../../providers/movimientosProvider";
import { useMonitoreoPotreros } from "../../../../providers/monitoreoPotrerosProvider";

const SituacionPotreroModal = ({ onClose }) => {
  const [establecimientosContext] = useEstablecimientos();
  const [monitoreoPotrerosContext, setMonitoreoPotrerosContext] =
    useMonitoreoPotreros();
  const [informacionPotreroModalContext, setInformacionPotreroModalContext] =
    useInformacionPotreroModal();
  const [estadosSituacionContext, setEstadosSituacionContext] =
    useEstadosSituacion();
  const [movimientosContext, setMovimientosContext] = useMovimiento();

  const [loading, setLoading] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const setInformacionPotreroModalContextCb = useCallback(
    (data) => {
      setInformacionPotreroModalContext(data);
    },
    [setInformacionPotreroModalContext]
  );

  useEffect(() => {
    if (
      informacionPotreroModalContext.fecha &&
      informacionPotreroModalContext.potrero
    ) {
      setLoading(true);
      potrerosApi
        .getSituacionPotrero({
          establecimientoId: establecimientosContext.selected,
          fecha: utils.formatDate(informacionPotreroModalContext.fecha),
          potreroId: informacionPotreroModalContext.potrero,
        })
        .then((situacionPotreroExistente) => {
          if (
            !informacionPotreroModalContext.id &&
            situacionPotreroExistente?.length
          ) {
            return setInformationModal({
              rawBody: true,
              isOpen: true,
              title: "Crear Situación de Potrero",
              body: (
                <span>
                  Existe estado de situación para la fecha y potrero
                  seleccionado.
                </span>
              ),
              onClose: () => {
                setLoading(false);
                setInformacionPotreroModalContextCb({ fecha: null });
                setInformationModal({
                  rawBody: false,
                  isOpen: false,
                  title: "",
                  body: "",
                });
              },
            });
          }
          setLoading(false);
          setInformacionPotreroModalContextCb({
            tipoUsoSueloOrigen: {
              id: informacionPotreroModalContext.tipoUsoSueloOrigen?.id,
            },
          });
        })
        .catch((err) => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Error",
            rawBody: true,
            body: (
              <span>
                ${err?.response?.data?.message || "Ha habido un error"}
              </span>
            ),
          });
        });
    }
  }, [
    establecimientosContext.selected,
    informacionPotreroModalContext.fecha,
    informacionPotreroModalContext.potrero,
    informacionPotreroModalContext.tipoUsoSueloOrigen.id,
    informacionPotreroModalContext.id,
    setInformacionPotreroModalContextCb,
  ]);

  const onCreateSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    potrerosApi
      .createSituacionPotrero({
        fecha: moment(informacionPotreroModalContext.fecha).format(
          "YYYY-MM-DD"
        ),
        establecimientoId: establecimientosContext.selected,
        potreroId: informacionPotreroModalContext.potrero,
        tipoUsoSueloId: informacionPotreroModalContext.tipoUsoSueloOrigen?.id,
        calidadPasto: informacionPotreroModalContext.calidadPasto,
        alturaPasto: informacionPotreroModalContext.alturaPastoOrigen,
        efectivoPastoreo: informacionPotreroModalContext.efectivoPastoreoOrigen,
        observaciones: informacionPotreroModalContext.observaciones,
        animales: informacionPotreroModalContext.animales,
      })
      .then(() => {
        setLoading(false);
        setInformationModal({
          rawBody: true,
          isOpen: true,
          title: "Crear Situación de Potrero",
          body: <span>Estado de situación creado correctamente.</span>,
          onClose,
        });
        setMonitoreoPotrerosContext({
          refresh: !monitoreoPotrerosContext.refresh,
        });
        setEstadosSituacionContext({
          refresh: !estadosSituacionContext.refresh,
        });
        setMovimientosContext({ refresh: !movimientosContext.refresh });
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          rawBody: true,
          isOpen: true,
          title: "Error al crear la situación del potrero",
          body: <span>{err?.response?.data?.message}</span>,
        });
      });
  };

  const onEditSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    potrerosApi
      .updateSituacionPotrero({
        id: informacionPotreroModalContext.id,
        fecha: informacionPotreroModalContext.fecha,
        establecimientoId: establecimientosContext.selected,
        potreroId: informacionPotreroModalContext.potrero,
        tipoUsoSueloId: informacionPotreroModalContext.tipoUsoSueloOrigen?.id,
        calidadPasto: informacionPotreroModalContext.calidadPasto,
        alturaPasto: informacionPotreroModalContext.alturaPastoOrigen,
        efectivoPastoreo: informacionPotreroModalContext.efectivoPastoreoOrigen,
        observaciones: informacionPotreroModalContext.observaciones,
        animales: informacionPotreroModalContext.animales,
      })
      .then(() => {
        setLoading(false);
        setInformationModal({
          rawBody: true,
          isOpen: true,
          title: "Editar Situación de Potrero",
          body: <span>Estado de situación actualizado correctamente.</span>,
          onClose,
        });
        setMonitoreoPotrerosContext({
          refresh: !monitoreoPotrerosContext.refresh,
        });
        setEstadosSituacionContext({
          refresh: !estadosSituacionContext.refresh,
        });
        setMovimientosContext({ refresh: !movimientosContext.refresh });
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          rawBody: true,
          isOpen: true,
          title: "Error al editar la situación del potrero",
          body:
            typeof err?.response?.data === "string" ? (
              <>
                <p>{err?.response?.data}</p>
                <span>
                  Un Potrero puede tener un solo uso para una fecha dada.
                </span>
              </>
            ) : (
              <span>Ha habido un problema con tu solicitud.</span>
            ),
        });
      });
  };

  return informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            })
      }
    />
  ) : (
    <Modal size="md" isOpen={true}>
      <ModalHeader>{`${
        informacionPotreroModalContext.id ? "" : "Agregar "
      }Estado Situación de Potrero`}</ModalHeader>
      <Form
        onSubmit={
          informacionPotreroModalContext.id ? onEditSubmit : onCreateSubmit
        }
      >
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup row>
                <Col sm={4} className="text-left d-flex flex-column">
                  <div>
                    <span>Fecha</span>
                    <small className="ml-1 text-danger">*</small>
                  </div>
                  <span className="font-size-75 text-secondary">
                    selecciona o escribe una fecha
                  </span>
                </Col>
                <Col
                  sm={8}
                  className="d-flex align-items-center justify-content-between"
                >
                  <Datetime
                    renderInput={(props) => (
                      <input
                        disabled={
                          informacionPotreroModalContext.id ||
                          informacionPotreroModalContext.disabled
                        }
                        {...props}
                        required={true}
                        placeholder="Selecciona o escribe una fecha"
                      />
                    )}
                    closeOnSelect={true}
                    locale="es"
                    timeFormat={false}
                    className="flex-grow-1"
                    utc={true}
                    value={moment(informacionPotreroModalContext.fecha)}
                    onChange={(fecha) =>
                      setInformacionPotreroModalContext({ fecha })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-0">
                <Label sm={4} className="text-left">
                  Potrero
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={8}>
                  <CustomInput
                    disabled={
                      informacionPotreroModalContext.id ||
                      informacionPotreroModalContext.disabled
                    }
                    required={true}
                    id="potreroSelect"
                    className="text-truncate potreroSelect"
                    type="select"
                    name="potreroSelect"
                    onChange={(event) =>
                      setInformacionPotreroModalContext({
                        potrero: event.currentTarget.value,
                      })
                    }
                    value={informacionPotreroModalContext.potrero || ""}
                  >
                    <option value="">Selecciona un potrero</option>
                    {(informacionPotreroModalContext.potreros || []).map(
                      (potrero, index) => (
                        <option key={index} value={potrero.id}>
                          {potrero.nombre}
                        </option>
                      )
                    )}
                  </CustomInput>
                </Col>
              </FormGroup>
              {informacionPotreroModalContext.fecha &&
              informacionPotreroModalContext.potrero ? (
                <>
                  <FormGroup row className="mt-3">
                    <Col sm={4} className="text-left d-flex flex-column">
                      <div>
                        <span>Uso de Suelo</span>
                        <small className="ml-1 text-danger">*</small>
                      </div>
                      <span className="font-size-75 text-secondary">
                        en la fecha seleccionada
                      </span>
                    </Col>
                    <Col sm={8} className="d-flex align-items-center">
                      <Row className="flex-grow-1 mx-0 flex-nowrap align-items-center">
                        <CustomInput
                          disabled={
                            informacionPotreroModalContext.disabled ||
                            informacionPotreroModalContext.tipoUsoSueloOrigen
                              ?.disabled
                          }
                          required={true}
                          id="tipoUsoSueloSelect"
                          className="text-truncate tipoUsoSueloSelect"
                          type="select"
                          name="tipoUsoSueloSelect"
                          onChange={(event) =>
                            setInformacionPotreroModalContext({
                              tipoUsoSueloOrigen: {
                                id: parseInt(event.currentTarget.value),
                                disabled: false,
                              },
                            })
                          }
                          value={
                            informacionPotreroModalContext.tipoUsoSueloOrigen
                              ?.id || ""
                          }
                        >
                          <option value="">Selecciona un uso de suelo</option>
                          {(
                            informacionPotreroModalContext.tipoUsosSuelo || []
                          ).map((tipoUsoSuelo, index) => (
                            <option key={index} value={tipoUsoSuelo.id}>
                              {tipoUsoSuelo.nombre}
                            </option>
                          ))}
                        </CustomInput>
                        {informacionPotreroModalContext.tipoUsoSueloOrigen
                          ?.message ? (
                          <div>
                            <FontAwesomeIcon
                              id={"tipoUsoInfo"}
                              icon={faInfoCircle}
                              fixedWidth
                              className="ml-2 text-info cursor-pointer"
                            />
                            <UncontrolledTooltip
                              placement={"bottom"}
                              target={"tipoUsoInfo"}
                            >
                              {
                                informacionPotreroModalContext
                                  .tipoUsoSueloOrigen?.message
                              }
                            </UncontrolledTooltip>
                          </div>
                        ) : null}
                      </Row>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={4} className="text-left d-flex flex-column">
                      <div>
                        <span>Altura del Pasto</span>
                        <small className="ml-1 text-danger">*</small>
                      </div>
                      <span className="font-size-75 text-secondary">
                        en Cm.
                      </span>
                    </Col>
                    <Col sm={8} className="d-flex align-items-center">
                      <Row className="flex-grow-1 mx-0 flex-nowrap align-items-center">
                        <Input
                          disabled={informacionPotreroModalContext.disabled}
                          required={true}
                          type="number"
                          max="100"
                          min="1"
                          step="0.1"
                          lang="es"
                          placeholder="Ingresa la altura del pasto.."
                          onChange={(event) =>
                            setInformacionPotreroModalContext({
                              alturaPastoOrigen: event.currentTarget.value,
                            })
                          }
                          value={
                            informacionPotreroModalContext.alturaPastoOrigen ||
                            ""
                          }
                        />
                      </Row>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={4} className="text-left d-flex flex-column">
                      <div>
                        <span>Efectivo para Pastoreo</span>
                        <small className="ml-1 text-danger">*</small>
                      </div>
                      <span className="font-size-75 text-secondary">%</span>
                    </Col>
                    <Col sm={8} className="d-flex align-items-center">
                      <Row className="flex-grow-1 mx-0 flex-nowrap align-items-center">
                        <Input
                          disabled={informacionPotreroModalContext.disabled}
                          required={true}
                          type="number"
                          max="100"
                          min="0"
                          step="0.1"
                          lang="es"
                          placeholder="Ingresa un porcentaje.."
                          onChange={(event) =>
                            setInformacionPotreroModalContext({
                              efectivoPastoreoOrigen: event.currentTarget.value,
                            })
                          }
                          value={
                            informacionPotreroModalContext.efectivoPastoreoOrigen
                          }
                        />
                      </Row>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={4} className="text-left">
                      Calidad del Pasto
                      <small className="ml-1 text-danger">*</small>
                    </Label>
                    <Col sm={8}>
                      <Row className="mx-0 flex-nowrap align-items-center">
                        <CustomInput
                          disabled={informacionPotreroModalContext.disabled}
                          required={true}
                          id="calidadPastoSelect"
                          className="text-truncate calidadPastoSelect"
                          type="select"
                          name="calidadPastoSelect"
                          onChange={(event) =>
                            setInformacionPotreroModalContext({
                              calidadPasto: event.currentTarget.value,
                            })
                          }
                          value={
                            informacionPotreroModalContext.calidadPasto || ""
                          }
                        >
                          <option value="">
                            Selecciona la calidad del pasto
                          </option>
                          <option value="Excelente">Excelente</option>
                          <option value="Buena">Buena</option>
                          <option value="Mala">Mala</option>
                        </CustomInput>
                      </Row>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={4} className="text-left">
                      Observaciones
                    </Label>
                    <Col sm={8}>
                      <Input
                        disabled={informacionPotreroModalContext.disabled}
                        className="mt-1"
                        type="textarea"
                        maxLength={255}
                        name="observaciones"
                        placeholder="Observaciones.."
                        onChange={(event) =>
                          setInformacionPotreroModalContext({
                            observaciones: event.currentTarget.value,
                          })
                        }
                        value={
                          informacionPotreroModalContext.observaciones || ""
                        }
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              <FormGroup className="col px-0 text-right mb-0 mt-3">
                <div className="border rounded">
                  <AnimalesList />
                </div>
                <Button
                  hidden={
                    (informacionPotreroModalContext.id &&
                      !informacionPotreroModalContext.isLast) ||
                    informacionPotreroModalContext.disabled
                  }
                  className="mt-2 rounded"
                  size="sm"
                  onClick={() =>
                    setInformacionPotreroModalContext({
                      animalesSelector: true,
                    })
                  }
                >
                  Agregar Animales
                </Button>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button
                color={"secondary"}
                onClick={() =>
                  setInformacionPotreroModalContext({
                    ...initialState,
                    potreros: informacionPotreroModalContext.potreros,
                    motivosMovimiento:
                      informacionPotreroModalContext.motivosMovimiento,
                    tiposMovimiento:
                      informacionPotreroModalContext.tiposMovimiento,
                    tipoUsosSuelo: informacionPotreroModalContext.tipoUsosSuelo,
                    categoriasAnimal:
                      informacionPotreroModalContext.categoriasAnimal,
                    isOpen: !informacionPotreroModalContext.id,
                  })
                }
              >
                {informacionPotreroModalContext.disabled
                  ? "Cerrar"
                  : "Cancelar"}
              </Button>
              <Button
                hidden={informacionPotreroModalContext.disabled}
                color={"primary"}
                type="submit"
              >
                {informacionPotreroModalContext.id ? "Guardar" : "Confirmar"}
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SituacionPotreroModal;
