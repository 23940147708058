import axios from "axios";
import config from "../config/config";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}&reason=1`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const potrerosApi = {
  getPotreros: (establecimientoId, data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      data.id
        ? `/api/establecimientos/${establecimientoId}/potreros/${data.id}`
        : `/api/establecimientos/${establecimientoId}/potreros?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getIndicadoresUsoPotrero: (establecimientoId, data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/establecimientos/${establecimientoId}/potreros/${data.id}/indicadores-uso?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createPotrero: (establecimientoId, data) => {
    return axios(`/api/establecimientos/${establecimientoId}/potreros`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    }).then((result) => result.data);
  },
  updatePotrero: (establecimientoId, data) => {
    return axios(
      `/api/establecimientos/${establecimientoId}/potreros/${data.id}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "put",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deletePotrero: (establecimientoId, data) => {
    return axios(
      `/api/establecimientos/${establecimientoId}/potreros/${data.id}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "delete",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  //USOS POTRERO
  getUsosPotrero: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/usos?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createUsosPotrero: (data) => {
    return axios(
      `/api/establecimientos/${data.establecimientoId}/potreros/usos`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "post",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateUsoPotrero: (data) => {
    return axios(
      `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/usos/${data.id}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "put",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteUsoPotrero: (data) => {
    return axios(
      `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/usos/${data.id}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "delete",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  //SITUACION POTRERO
  getSituacionPotrero: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      data.id
        ? `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/situacion/${data.id}`
        : `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/situacion?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createSituacionPotrero: (data) => {
    return axios(
      `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/situacion`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "post",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateSituacionPotrero: (data) => {
    return axios(
      `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/situacion/${data.id}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "put",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteSituacionPotrero: (data) => {
    return axios(
      `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/situacion/${data.id}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "delete",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  //MOVIMIENTO POTRERO
  getMovimientoPotrero: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      data.id
        ? `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/movimientos/${data.id}`
        : `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/movimientos?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createMovimientoPotrero: (data) => {
    return axios(
      `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/movimientos`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "post",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateMovimientoPotrero: (data) => {
    return axios(
      `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/movimientos/${data.id}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "put",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteMovimientoPotrero: (data) => {
    return axios(
      `/api/establecimientos/${data.establecimientoId}/potreros/${data.potreroId}/movimientos/${data.id}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "delete",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};
