import axios from "axios";
import config from "../config/config";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}&reason=1`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const importacionesApi = {
  getCrecimientosPotrero: (importacionId, data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/common/importaciones/${importacionId}/crecimientosPotrero?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getImportaciones: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      data.id
        ? `/api/common/importaciones/${data.id}`
        : `/api/common/importaciones?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createImportacion: (data) => {
    const form_data = new FormData();
    form_data.append("tasasCrecimiento", data.file);
    if (data.force) {
      form_data.append("force", data.force);
    }
    if (data.filename) {
      form_data.append("filename", data.filename);
    }
    return axios(`/api/common/importaciones`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data: form_data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteImportacion: (data) => {
    return axios(`/api/common/importaciones/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};
