import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Form,
  Input,
  Row,
  FormText,
} from "reactstrap";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { establecimientosApi } from "../../../services/establecimientosServices";
import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import { kml } from "@tmcw/togeojson";
import { usePotreros } from "../../../providers/potrerosProvider";
import JSZip from "jszip";
import socketIOClient from "socket.io-client";
import config from "../../../config/config";
import { useAuth } from "../../../providers/authProvider";

const MAX_FILE_SIZE = 1000000;

const PotrerosImportModal = ({ onClose }) => {
  const [authContext] = useAuth();
  const [potrerosContext, setPotrerosContext] = usePotreros();
  const [loading, setLoading] = useState();
  const [establecimientosContext] = useEstablecimientos();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    body: null,
  });
  const [kmlData, setKmlData] = useState();
  const [importProgress, setImportProgress] = useState();
  const [socket, setSocket] = useState(
    socketIOClient(config.apiURL, { transports: ["websocket"] })
  );

  const importarPotrerosSocketActions = (socketToken, callback) => {
    socket.emit("signup", socketToken);

    socket.on("signupSuccess", () => {
      callback();
    });

    socket.on("potreroImported", (data) => {
      console.log(data);
      setImportProgress(data);
      if (data.remaining === 0) {
        setLoading(false);
        setPotrerosContext({ refresh: !potrerosContext.refresh });
        socket.disconnect();
        setImportProgress();
        setSocket();
        if (!data?.creados) {
          return;
        }
        setInformationModal({
          isOpen: true,
          title: "Importar Potreros",
          body: `<h5>Resultado</h5>
                    <ul class="mb-0 font-size-75">
                        ${data.creados
                          .map((message) => `<li class="my-1">${message}</li>`)
                          .join("")}
                        ${data.errores
                          .map((message) => `<li class="my-1">${message}</li>`)
                          .join("")}
                    </ul>`,
          onClose,
        });
      }
    });

    socket.on("disconnect", (data) => {
      console.log("disconnected", data);
    });
  };

  const onFileUpload = (event) => {
    const extensionRegExp = /(.+)\.(kml|kmz)/;
    const file = event.currentTarget.files[0];
    if (!file) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Archivo inválido.",
        onClose: () => setInformationModal({ isOpen: false, body: "" }),
      });
    }
    if (!extensionRegExp.test(file.name)) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Extensión de archivo inválida.",
        onClose: () => setInformationModal({ isOpen: false, body: "" }),
      });
    }
    const [, , type] = file.name.match(extensionRegExp);
    if (type !== "kmz" && type !== "kml") {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Extensión de archivo inválida.",
        onClose: () => setInformationModal({ isOpen: false, body: "" }),
      });
    }
    if (file && file.size > MAX_FILE_SIZE) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "El archivo excede el máximo tamaño de 1mb.",
        onClose: () => setInformationModal({ isOpen: false, body: "" }),
      });
    }
    const kmlRegExp = /(.+)\.kml/;
    if (kmlRegExp.test(file.name)) {
      const reader = new FileReader();
      reader.onload = function () {
        const DOMParser = require("xmldom").DOMParser;
        const kmlData = new DOMParser().parseFromString(reader.result);
        const converted = kml(kmlData);
        if (converted.features.length > 50) {
          return setInformationModal({
            isOpen: true,
            title: "Error",
            body: "El archivo contiene más del máximo permitido de 50 potreros para una misma importación.",
            onClose: () => setInformationModal({ isOpen: false, body: "" }),
          });
        }
        setKmlData(converted);
      };
      reader.readAsText(file);
    } else {
      JSZip.loadAsync(file).then(function (zip) {
        const XMLFile = zip.filter((file) => kmlRegExp.test(file));
        if (XMLFile.length !== 1) {
          return setInformationModal({
            isOpen: true,
            title: "Error",
            body: `Archivo inválido. El archivo KMZ debe contener exactmente un fichero XML dentro, y contiene ${
              XMLFile.length === 0 ? "ninguno" : "más de uno"
            }.`,
            onClose: () => setInformationModal({ isOpen: false, body: "" }),
          });
        }
        XMLFile[0].async("blob").then(function (blob) {
          const reader = new FileReader();
          reader.onload = function () {
            const DOMParser = require("xmldom").DOMParser;
            const kmlData = new DOMParser().parseFromString(
              reader.result,
              "text/xml"
            );
            const converted = kml(kmlData);
            if (converted.features.length > 50) {
              return setInformationModal({
                isOpen: true,
                title: "Error",
                body: "El archivo contiene más del máximo permitido de 50 potreros para una misma importación.",
                onClose: () => setInformationModal({ isOpen: false, body: "" }),
              });
            }
            setKmlData(converted);
          };
          reader.readAsText(blob);
        });
      });
    }
  };

  const doSubmit = (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }
    const validFeatures = kmlData.features.filter(
      (potrero) =>
        potrero.geometry.type === "Polygon" &&
        potrero.geometry.coordinates[0][0][0] != null
    );
    if (!validFeatures.length) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "El archivo no contiene potreros.",
        onClose: () => setInformationModal({ isOpen: false, body: "" }),
      });
    }
    setLoading(true);
    importarPotrerosSocketActions(authContext.currentUser.socketToken, () => {
      establecimientosApi
        .importarPotreros({
          socketToken: authContext.currentUser.socketToken,
          kmlData,
          establecimientoId: establecimientosContext.selected,
        })
        .catch(() => setLoading(false));
    });
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() => informationModal.onClose()}
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader>Importar Potreros</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading && importProgress ? (
            <div className="d-flex flex-column justify-content-center mb-n1">
              <Loader size={"sm"} />
              <small className="mt-2 text-muted">
                importando potreros {(importProgress.creados || []).length} de{" "}
                {importProgress.total}...
              </small>
              <small className="text-muted">
                {(importProgress.errores || []).length} errores...
              </small>
              <small className="text-muted">
                puede tardar varios minutos, no cierres ni actualices la página.
              </small>
            </div>
          ) : (
            <>
              {!loading ? (
                <FormGroup row className="mb-1">
                  <Label sm={4} className="text-sm-left">
                    <div>
                      Subir Archivo
                      <small className="ml-1 text-danger">*</small>
                    </div>
                    <small className="text-muted">KML o KMZ</small>
                  </Label>
                  <Col sm={8}>
                    <Input
                      required={true}
                      type="file"
                      accept=".kml, .kmz"
                      className="mb-1 text-truncate"
                      onChange={onFileUpload}
                    />
                    <FormText className="text-muted text-left">
                      Selecciona un archivo
                    </FormText>
                  </Col>
                </FormGroup>
              ) : (
                <Loader size={"sm"} />
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between d-flex align-items-center">
              <Button
                color={"secondary"}
                onClick={() => {
                  if (socket) {
                    socket.disconnect();
                  }
                  onClose();
                }}
              >
                Cancelar
              </Button>{" "}
              {!loading ? (
                <Button color={"primary"} type="submit">
                  Importar
                </Button>
              ) : null}
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default PotrerosImportModal;
