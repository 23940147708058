import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import CustomerLayout from "../layouts/Customer";
import AdminLayout from "../layouts/Admin";
import AuthLayout from "../layouts/Auth";
import DefaultLayout from "../layouts/Default";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";
import { authRoutes, adminRoutes, customerRoutes } from ".";
import Page500 from "../pages/auth/Page500";
import SeguimientoForrajeroPublic from "../pages/customer/SeguimientoForrajeroPublic";

const Routes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {authRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact
              render={(props) => (
                <AuthLayout>
                  <route.component {...props} />
                </AuthLayout>
              )}
            />
          ))}
          {adminRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact
              render={(props) => (
                <AdminLayout>
                  <route.component {...props} />
                </AdminLayout>
              )}
            />
          ))}
          {customerRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact
              render={(props) => (
                <CustomerLayout>
                  <route.component {...props} />
                </CustomerLayout>
              )}
            />
          ))}
          <Route
            path={"/"}
            exact
            render={() => <Redirect to="/portal/establecimientos" />}
          />
          <Route
            path={"/error/500"}
            exact
            render={() => (
              <DefaultLayout>
                <Page500 />
              </DefaultLayout>
            )}
          />
          <Route
            path={"/public/seguimiento"}
            exact
            render={() => (
              <DefaultLayout>
                <SeguimientoForrajeroPublic />
              </DefaultLayout>
            )}
          />
          <Route
            path={"/error/404"}
            exact
            render={() => (
              <DefaultLayout>
                <Page404 />
              </DefaultLayout>
            )}
          />
          <Route
            render={() => (
              <DefaultLayout>
                <Page404 />
              </DefaultLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
