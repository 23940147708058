import React, { useCallback, useEffect, useState } from "react";

import { CustomInput } from "reactstrap";
import { useAuth } from "../../../providers/authProvider";
import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import { useSidebar } from "../../../providers/navbarProvider";
import { usePotreros } from "../../../providers/potrerosProvider";
import { establecimientosApi } from "../../../services/establecimientosServices";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

const EstablecimientoSelector = () => {
  const [authContext, setAuthContext] = useAuth();
  const [establecimientosContext, setEstablecimientosContext] =
    useEstablecimientos();
  const [, setSidebarContext] = useSidebar();
  const [, setPotrerosContext] = usePotreros();

  const [loading, setLoading] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const setEstablecimientosContextCB = useCallback(
    (data) => {
      setEstablecimientosContext(data);
    },
    [setEstablecimientosContext]
  );

  useEffect(() => {
    if (
      !establecimientosContext.allEstablecimientos ||
      establecimientosContext.refreshSelector
    ) {
      setLoading(true);
      establecimientosApi
        .getEstablecimientos({
          page: 0,
          pageSize: 999,
          sortBy: "nombre",
          direction: "ASC",
        })
        .then((result) => {
          let selected = null;
          if (result.count === 1) {
            selected = result.data[0].id;
          } else if (authContext.currentUser.defaultEstablecimiento) {
            selected = authContext.currentUser.defaultEstablecimiento;
          }
          setEstablecimientosContextCB({
            selected,
            allEstablecimientos: result.data,
            refreshSelector: false,
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Error",
            rawBody: true,
            body: `<span>Error cargando establecimientos.</span>`,
          });
        });
    }
  }, [
    setEstablecimientosContextCB,
    establecimientosContext.refreshSelector,
    establecimientosContext.allEstablecimientos,
    authContext.currentUser.defaultEstablecimiento,
  ]);

  const onChange = (event) => {
    const establecimientoId = isNaN(event.currentTarget.value)
      ? null
      : parseInt(event.currentTarget.value);
    setSidebarContext({ open: false });
    setAuthContext({
      currentUser: {
        ...authContext.currentUser,
        defaultEstablecimiento: establecimientoId,
      },
    });
    setEstablecimientosContext({
      selected: establecimientoId,
    });
    if (establecimientoId) {
      establecimientosApi.selectEstablecimiento({ id: establecimientoId });
    }
    setPotrerosContext({ potreros: {} });
  };

  return (
    <div className="col-12 mt-4" style={{ maxHeight: "35px" }}>
      {loading ? (
        <Loader size="sm" />
      ) : (
        <CustomInput
          id="establecimientoSelect"
          className="text-truncate bg-lightgreen"
          type="select"
          name="establecimientoSelect"
          onChange={onChange}
          value={establecimientosContext.selected || ""}
        >
          <option value="">Selecciona un establecimiento</option>
          {(establecimientosContext.allEstablecimientos || []).map(
            (establecimiento, index) => (
              <option key={index} value={establecimiento.id}>
                {establecimiento.nombre}
              </option>
            )
          )}
        </CustomInput>
      )}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          size={informationModal.size}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </div>
  );
};

export default EstablecimientoSelector;
