import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sizePerPage: 10,
  page: 1,
  search: "",
  sortBy: "createdAt",
  direction: "desc",
  importaciones: {},
};

const ImportacionesContext = createContext(initialState);

const ImportacionesProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <ImportacionesContext.Provider value={stateAndDispatch}>
      {children}
    </ImportacionesContext.Provider>
  );
};

export const useImportaciones = () => useContext(ImportacionesContext);

export { ImportacionesContext, ImportacionesProvider };
