import React, { useCallback, useEffect, useState } from "react";

import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import { useInformacionPotreroModal } from "../../../../providers/informacionPotreroModalProvider";
import { commonApi } from "../../../../services/commonServices";
import { potrerosApi } from "../../../../services/potrerosServices";
import { tipoUsoSuelosApi } from "../../../../services/tipoUsoSueloServices";
import AlimentosExtraModal from "./AlimentosExtraModal";
import AnimalesSelector from "./AnimalesSelector";
import MovimientoPotrerosModal from "./MovimientoPotrerosModal";
import SituacionPotreroModal from "./SituacionPotreroModal";
import TipoInformacionPotreroModal from "./TipoInformacionPotreroModal";

const TYPE_SITUACION = "1";
const TYPE_MOVIMIENTO = "2";
const ALIMENTOS_EXTRA = "3";
const MAX_PAGE_SIZE = 999;

const InformacionPotreroModal = ({ onClose }) => {
  const [establecimientosContext] = useEstablecimientos();
  const [informacionPotreroModalContext, setInformacionPotreroModalContext] =
    useInformacionPotreroModal();

  const [loading, setLoading] = useState();

  const setInformacionPotreroModalContextCb = useCallback(
    (data) => {
      setInformacionPotreroModalContext(data);
    },
    [setInformacionPotreroModalContext]
  );

  useEffect(() => {
    if (establecimientosContext.selected) {
      setLoading(true);
      Promise.all([
        commonApi.getMotivosMovimiento(),
        commonApi.getTiposMovimiento(),
        tipoUsoSuelosApi.getTipoUsoSuelos({ pageSize: MAX_PAGE_SIZE }),
        commonApi.getCategoriasAnimales(),
        potrerosApi.getPotreros(establecimientosContext.selected, {
          page: 0,
          pageSize: 999,
          sortBy: "nombre",
        }),
      ]).then(
        ([
          motivosMovimiento,
          tiposMovimiento,
          tipoUsosSuelo,
          categoriasAnimal,
          potrerosResult,
        ]) => {
          setInformacionPotreroModalContextCb({
            potreros: potrerosResult.data,
            motivosMovimiento,
            tiposMovimiento,
            tipoUsosSuelo: tipoUsosSuelo.data.filter(
              (tu) => tu.enabledMonitoreo
            ),
            categoriasAnimal,
          });
          setLoading(false);
        }
      );
    }
  }, [setInformacionPotreroModalContextCb, establecimientosContext.selected]);

  if (informacionPotreroModalContext.animalesSelector) {
    return <AnimalesSelector />;
  }

  switch (informacionPotreroModalContext.mode) {
    case TYPE_SITUACION:
      return <SituacionPotreroModal onClose={onClose} />;
    case TYPE_MOVIMIENTO:
      return <MovimientoPotrerosModal onClose={onClose} />;
    case ALIMENTOS_EXTRA:
      return <AlimentosExtraModal onClose={onClose} />;
    default:
      return (
        <TipoInformacionPotreroModal onClose={onClose} loading={loading} />
      );
  }
};

export default InformacionPotreroModal;
