import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import CustomCheckbox from "../../components/CustomCheckbox";
import { commonApi } from "../../services/commonServices";
import Loader from "../../components/Loader";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const ZONA_GEO = "ZONA_GEO";
const DEPARTAMENTO_SECCION = "DEPARTAMENTO_SECCION";

const TIPO_USO_SUELO = "TIPO_USO_SUELO";
const DEPARTAMENTOS = "DEPARTAMENTOS";
const SECCIONES_POLICIALES = "SECCIONES_POLICIALES";
const ZONAS = "ZONAS";

const SeguimientoForrajeroFilters = ({
  onApply,
  tipoUsoSuelosId,
  departamentosId,
  seccionesPolicialesId,
  zonasId,
  filtrosMode,
}) => {
  const [dataLoading, setDataLoading] = useState({});

  const [localTipoUsoSuelosId, setLocalTipoUsoSueloId] =
    useState(tipoUsoSuelosId);
  const [localDepartamentosId, setLocalDepartamentosId] =
    useState(departamentosId);
  const [localSeccionesPolicialesId, setLocalSeccionesPolicialesId] = useState(
    seccionesPolicialesId
  );
  const [localZonasId, setLocalZonasId] = useState(zonasId);
  const [localFiltrosMode, setLocalFiltrosMode] = useState(filtrosMode);

  const [tipoUsosSuelo, setTipoUsosSuelo] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [zonasGeo, setZonasGeo] = useState([]);
  const [seccionesPoliciales, setSeccionesPoliciales] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setDataLoading((prev) => ({
        ...prev,
        [TIPO_USO_SUELO]: true,
      }));
      const tipoUsosSuelo = await commonApi.getTipoUsoSuelos({});
      setTipoUsosSuelo(tipoUsosSuelo.filter((tu) => tu.enabledSF));
      setDataLoading((prev) => ({
        ...prev,
        [TIPO_USO_SUELO]: false,
      }));
    };
    fetchData();
  }, [setDataLoading]);

  useEffect(() => {
    const fetchData = async () => {
      setDataLoading((prev) => ({
        ...prev,
        [DEPARTAMENTOS]: true,
      }));
      const departamentos = await commonApi.getDepartamentosPublic({});
      setDepartamentos(departamentos);
      setDataLoading((prev) => ({
        ...prev,
        [DEPARTAMENTOS]: false,
      }));
    };
    fetchData();
  }, [setDataLoading]);

  useEffect(() => {
    const fetchData = async () => {
      setDataLoading((prev) => ({
        ...prev,
        [ZONAS]: true,
      }));
      const zonasGeo = await commonApi.getZonasGeomorfologicas({});
      setZonasGeo(zonasGeo);
      setDataLoading((prev) => ({
        ...prev,
        [ZONAS]: false,
      }));
    };
    fetchData();
  }, [setDataLoading]);

  useEffect(() => {
    const fetchData = async () => {
      setDataLoading((prev) => ({
        ...prev,
        [SECCIONES_POLICIALES]: true,
      }));
      const seccionesPoliciales = await commonApi.getSeccionesPoliciales({
        departamentoId: localDepartamentosId.toString(),
      });
      setSeccionesPoliciales(seccionesPoliciales);
      setDataLoading((prev) => ({
        ...prev,
        [SECCIONES_POLICIALES]: false,
      }));
    };
    if (localDepartamentosId.length) {
      fetchData();
    }
  }, [localDepartamentosId, setDataLoading]);

  return (
    <div className="d-flex flex-column mb-n2 col-3">
      <h3 className="text-center mb-3">Filtros</h3>
      <Card className="border">
        <CardHeader className="pb-0">
          <CardTitle
            tag="h5"
            className="mb-0 d-flex align-items-center justify-content-between"
          >
            <span>Usos de Suelo</span>
            <CustomCheckbox
              isLoading={dataLoading[TIPO_USO_SUELO]}
              label="Todos"
              checked={
                (localTipoUsoSuelosId || []).length ===
                (tipoUsosSuelo || []).length
              }
              onClick={() => {
                (localTipoUsoSuelosId || []).length !==
                (tipoUsosSuelo || []).length
                  ? setLocalTipoUsoSueloId(
                      (tipoUsosSuelo || []).map((t) => t.id)
                    )
                  : setLocalTipoUsoSueloId([]);
              }}
            />
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-wrap justify-content-between pb-2">
            <Select
              placeholder={<span className="text-muted">Selecciona..</span>}
              noOptionsMessage={() => "No hay más tipos de uso de suelo"}
              styles={style}
              className="col-12 px-0 border rounded my-2 small"
              options={(tipoUsosSuelo || []).map((tipoUsoSuelo) => {
                return {
                  label: tipoUsoSuelo.nombre,
                  value: tipoUsoSuelo.id,
                };
              })}
              isLoading={dataLoading[TIPO_USO_SUELO]}
              closeMenuOnSelect={true}
              components={animatedComponents}
              value={(localTipoUsoSuelosId || []).map((tipoUsoSuelo) => {
                const tipoUsoSueloObject = (tipoUsosSuelo || []).find(
                  (item) => item.id === tipoUsoSuelo
                );
                return tipoUsoSueloObject
                  ? {
                      label: tipoUsoSueloObject?.nombre || "-",
                      value: tipoUsoSuelo,
                    }
                  : null;
              })}
              isMulti
              onChange={(selected) =>
                setLocalTipoUsoSueloId((selected || []).map((t) => t.value))
              }
            />
          </div>
        </CardBody>
      </Card>
      <Card className="border">
        <CardHeader className="pb-0">
          <CardTitle
            tag="h5"
            className="mb-0 d-flex align-items-center justify-content-between"
          >
            <span>Filtros Habilitados</span>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <ListGroup className="col-12 px-0">
            <ListGroupItem
              className="d-flex justify-content-between align-items-center cursor-pointer"
              onClick={() => {
                setLocalFiltrosMode(DEPARTAMENTO_SECCION);
                setLocalDepartamentosId([]);
                setLocalZonasId([]);
                setLocalSeccionesPolicialesId([]);
              }}
            >
              <small>Departamento y Sección Policial</small>
              <div className="min-width-50 d-flex justify-content-end">
                <CustomCheckbox
                  checked={localFiltrosMode === DEPARTAMENTO_SECCION}
                />
              </div>
            </ListGroupItem>
            <ListGroupItem
              className="d-flex justify-content-between align-items-center cursor-pointer"
              onClick={() => {
                setLocalFiltrosMode(ZONA_GEO);
                setLocalDepartamentosId([]);
                setLocalZonasId([]);
                setLocalSeccionesPolicialesId([]);
              }}
            >
              <small>Zona Geomorfológica</small>
              <div className="min-width-50 d-flex justify-content-end">
                <CustomCheckbox checked={localFiltrosMode === ZONA_GEO} />
              </div>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
      {localFiltrosMode === DEPARTAMENTO_SECCION ? (
        <>
          <Card className="border">
            <CardHeader className="pb-0">
              <CardTitle
                tag="h5"
                className="mb-0 d-flex align-items-center justify-content-between"
              >
                <span>Departamentos</span>
                <CustomCheckbox
                  isLoading={dataLoading[DEPARTAMENTOS]}
                  label="Todos"
                  checked={
                    (localDepartamentosId || []).length ===
                    (departamentos || []).length
                  }
                  onClick={() => {
                    if (
                      (localDepartamentosId || []).length !==
                      (departamentos || []).length
                    ) {
                      setLocalDepartamentosId(
                        (departamentos || []).map((t) => t.id)
                      );
                      setLocalSeccionesPolicialesId([]);
                    } else {
                      setLocalDepartamentosId([]);
                      setLocalSeccionesPolicialesId([]);
                    }
                  }}
                />
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="d-flex flex-wrap justify-content-between pb-2">
                <Select
                  isLoading={dataLoading[DEPARTAMENTOS]}
                  placeholder={<span className="text-muted">Selecciona..</span>}
                  noOptionsMessage={() => "No hay más departamentos"}
                  styles={style}
                  className="col-12 px-0 border rounded my-2 small"
                  options={(departamentos || []).map((departamento) => {
                    return {
                      label: departamento.nombre,
                      value: departamento.id,
                    };
                  })}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={(localDepartamentosId || []).map((departamento) => {
                    const departamentoObject = (departamentos || []).find(
                      (item) => item.id === departamento
                    );
                    return departamentoObject
                      ? {
                          label: departamentoObject?.nombre || "-",
                          value: departamento,
                        }
                      : null;
                  })}
                  isMulti
                  onChange={(selected) => {
                    setLocalDepartamentosId(
                      (selected || []).map((t) => t.value)
                    );
                    setLocalSeccionesPolicialesId([]);
                  }}
                />
              </div>
            </CardBody>
          </Card>
          <Card className="border">
            <CardHeader className="pb-0">
              <CardTitle
                tag="h5"
                className="mb-0 d-flex align-items-center justify-content-between"
              >
                <span>Secciones Policiales</span>
                <CustomCheckbox
                  isLoading={dataLoading[SECCIONES_POLICIALES]}
                  label="Todos"
                  checked={
                    localSeccionesPolicialesId.length &&
                    (localSeccionesPolicialesId || []).length ===
                      (seccionesPoliciales || []).length
                  }
                  onClick={() => {
                    (localSeccionesPolicialesId || []).length !==
                    (seccionesPoliciales || []).length
                      ? setLocalSeccionesPolicialesId(
                          (seccionesPoliciales || []).map((t) => t.id)
                        )
                      : setLocalSeccionesPolicialesId([]);
                  }}
                />
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="d-flex flex-wrap justify-content-between pb-2">
                <Select
                  isLoading={dataLoading[SECCIONES_POLICIALES]}
                  placeholder={<span className="text-muted">Selecciona..</span>}
                  noOptionsMessage={() => "No hay más secciones policiales"}
                  styles={style}
                  className="col-12 px-0 border rounded my-2 small"
                  options={(seccionesPoliciales || []).map(
                    (seccionPolicial) => {
                      return {
                        label: `${seccionPolicial.nombreDepartamento} - Sección ${seccionPolicial.seccion}`,
                        value: seccionPolicial.id,
                      };
                    }
                  )}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={(localSeccionesPolicialesId || []).map(
                    (seccionPolicial) => {
                      const seccionPolicialObject = (
                        seccionesPoliciales || []
                      ).find((item) => item.id === seccionPolicial);
                      return seccionPolicialObject
                        ? {
                            label: `${seccionPolicialObject.nombreDepartamento} - Sección ${seccionPolicialObject.seccion}`,
                            value: seccionPolicial,
                          }
                        : null;
                    }
                  )}
                  isMulti
                  onChange={(selected) =>
                    setLocalSeccionesPolicialesId(
                      (selected || []).map((t) => t.value)
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </>
      ) : (
        <Card className="border">
          <CardHeader className="pb-0">
            <CardTitle
              tag="h5"
              className="mb-0 d-flex align-items-center justify-content-between"
            >
              <span>Zonas Geomorfologicas</span>
              <CustomCheckbox
                isLoading={dataLoading[ZONAS]}
                label="Todos"
                checked={
                  (localZonasId || []).length === (zonasGeo || []).length
                }
                onClick={() => {
                  (localZonasId || []).length !== (zonasGeo || []).length
                    ? setLocalZonasId((zonasGeo || []).map((t) => t.id))
                    : setLocalZonasId([]);
                }}
              />
            </CardTitle>
          </CardHeader>
          <CardBody>
            <div className="d-flex flex-wrap justify-content-between pb-2">
              <Select
                isLoading={dataLoading[ZONAS]}
                placeholder={<span className="text-muted">Selecciona..</span>}
                noOptionsMessage={() => "No hay más zonas geomorfológicas"}
                styles={style}
                className="col-12 px-0 border rounded my-2 small"
                options={(zonasGeo || []).map((zona) => {
                  return {
                    label: zona.nombre,
                    value: zona.id,
                  };
                })}
                closeMenuOnSelect={true}
                components={animatedComponents}
                value={(localZonasId || []).map((zona) => {
                  const zonaObject = (zonasGeo || []).find(
                    (item) => item.id === zona
                  );
                  return zonaObject
                    ? {
                        label: zonaObject?.nombre || "-",
                        value: zona,
                      }
                    : null;
                })}
                isMulti
                onChange={(selected) =>
                  setLocalZonasId((selected || []).map((t) => t.value))
                }
              />
            </div>
          </CardBody>
        </Card>
      )}
      <div>
        {Object.values(dataLoading).find((value) => Boolean(value)) ? (
          <div className="min-width-50">
            <Loader size="sm" />
          </div>
        ) : (
          <Button
            className="col-12"
            color={"success"}
            onClick={() =>
              onApply({
                localDepartamentosId,
                localFiltrosMode,
                localSeccionesPolicialesId,
                localTipoUsoSuelosId,
                localZonasId,
              })
            }
          >
            Aplicar
          </Button>
        )}
      </div>
    </div>
  );
};

export default SeguimientoForrajeroFilters;
