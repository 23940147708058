import axios from "axios";
import config from "../config/config";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}&reason=1`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const usuariosApi = {
  getUsuarios: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      data.id ? `/api/usuarios/${data.id}` : `/api/usuarios?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createUsuario: (data) => {
    return axios(`/api/usuarios`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    }).then((result) => result.data);
  },
  updateUsuario: (data) => {
    return axios(`/api/usuarios/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteUsuario: (data) => {
    return axios(`/api/usuarios/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};
