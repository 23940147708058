import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  InputGroup,
  Col,
  Row,
} from "reactstrap";

import Loader from "../../../components/Loader";
import { usePotreros } from "../../../providers/potrerosProvider";
import PotrerosCard from "./PotreroCard";

import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import Pagination from "react-js-pagination";
import { RefreshCw } from "react-feather";

const PoterosCardsView = ({ loading, potrerosLoading, onDelete }) => {
  const [establecimientosContext] = useEstablecimientos();
  const [potrerosContext, setPotrerosContext] = usePotreros();

  return (
    <div className="d-md-flex d-lg-none">
      {!establecimientosContext.selected ? (
        <Card className="col-12">
          <CardHeader>
            <CardTitle tag="h5" className="text-center my-1">
              Ningún establecimiento seleccionado
            </CardTitle>
          </CardHeader>
        </Card>
      ) : (
        <Col className="px-0">
          <Card className="col-12 px-0">
            <CardBody className="py-2">
              <div className="card-actions d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3 col-4 px-0" size="m">
                  <Input
                    maxLength="50"
                    value={potrerosContext.search}
                    onChange={(evt) =>
                      setPotrerosContext({ search: evt.target.value, page: 1 })
                    }
                    className="border-0"
                    placeholder="Buscar.."
                  />
                </InputGroup>
                <span className="mr-1 d-flex">
                  <span className="cursor-pointer mx-2 d-flex align-items-center">
                    <RefreshCw
                      hidden
                      onClick={() =>
                        setPotrerosContext({
                          refresh: !establecimientosContext.refresh,
                        })
                      }
                    />
                  </span>
                  <small className="text-truncate text-muted d-flex align-items-center">
                    Mostrando {potrerosContext.potreros?.count || 0} potreros
                  </small>
                </span>
              </div>
            </CardBody>
          </Card>
          {loading ? (
            <Loader />
          ) : !potrerosContext.potreros.count ? (
            <Card className="col-12">
              <CardHeader>
                <CardTitle tag="h5" className="text-center my-1">
                  No hay resultados
                </CardTitle>
              </CardHeader>
            </Card>
          ) : (
            <Col className="flex-wrap px-0">
              <Row>
                {(potrerosContext.potreros.data || []).map((potrero, index) => (
                  <PotrerosCard
                    key={index}
                    potrero={potrero}
                    potrerosLoading={potrerosLoading}
                    onDelete={onDelete}
                  ></PotrerosCard>
                ))}
              </Row>
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={potrerosContext.page}
                itemsCountPerPage={potrerosContext.sizePerPage}
                totalItemsCount={potrerosContext.potreros?.count || 0}
                pageRangeDisplayed={5}
                onChange={(page) => setPotrerosContext({ page })}
              />
            </Col>
          )}
        </Col>
      )}
    </div>
  );
};

export default PoterosCardsView;
