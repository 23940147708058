import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Row,
  Form,
  CustomInput,
} from "reactstrap";
import Loader from "../../../Loader";
import InformationModal from "../../../InformationModal";
import DateRangePicker from "../../../DateRangePicker";
import { potrerosApi } from "../../../../services/potrerosServices";
import moment from "moment";
import { utils } from "../../../../utils/utils";
import { useUsosPotreros } from "../../../../providers/usosPotrerosProvider";
import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import { tipoUsoSuelosApi } from "../../../../services/tipoUsoSueloServices";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const MAX_PAGE_SIZE = 999;

const UsoPotreroModal = ({ selectedUsoPotrero, onClose }) => {
  const [establecimientosContext] = useEstablecimientos();
  const [usosPotrerosContext, setUsosPotrerosContext] = useUsosPotreros();

  const [loading, setLoading] = useState();
  const [potreros, setPotreros] = useState([]);
  const [potrero, setPotrero] = useState(selectedUsoPotrero?.potreroId);
  const [tiposUsoSuelo, setTiposUsoSuelo] = useState([]);

  const [usoPotrero, setUsoPotrero] = useState(
    selectedUsoPotrero || {
      tipoUsoSueloId: "",
      fechaComienzo: moment(),
      fechaFin: moment().add(1, "day"),
    }
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    setLoading(true);
    Promise.all([
      potrerosApi.getPotreros(establecimientosContext.selected, {
        page: 0,
        pageSize: 999,
        sortBy: "nombre",
      }),
      tipoUsoSuelosApi.getTipoUsoSuelos({ pageSize: MAX_PAGE_SIZE }),
    ]).then(([potrerosResult, tipoUsoSuelosResult]) => {
      setPotreros(potrerosResult.data);
      setTiposUsoSuelo(tipoUsoSuelosResult.data.filter((tu) => tu.enabledSF));
      setLoading(false);
    });
  }, [establecimientosContext.selected]);

  const onSubmit = (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }
    if (
      usoPotrero.fechaComienzo &&
      usoPotrero.fechaFin &&
      moment(usoPotrero.fechaFin).isSameOrBefore(
        moment(usoPotrero.fechaComienzo),
        "date"
      )
    ) {
      return setInformationModal({
        isOpen: true,
        title: "Actualizar uso de Potrero",
        body: "Fecha fin debe ser posterior a fecha comienzo.",
      });
    }
    setLoading(true);
    if (usoPotrero.id) {
      potrerosApi
        .updateUsoPotrero({
          ...usoPotrero,
          establecimientoId: establecimientosContext.selected,
          fechaComienzo: utils.formatDate(
            usoPotrero.fechaComienzo,
            "YYYY-MM-DD"
          ),
          fechaFin: usoPotrero.fechaFin
            ? utils.formatDate(usoPotrero.fechaFin, "YYYY-MM-DD")
            : null,
        })
        .then(() => {
          setLoading(false);
          setUsosPotrerosContext({ refresh: !usosPotrerosContext.refresh });
          setInformationModal({
            isOpen: true,
            title: "Actualizar uso de Potrero",
            body: "Uso de Potrero actualizado correctamente.",
            onClose: () => {
              setInformationModal({
                rawBody: false,
                isOpen: false,
                title: "",
                body: "",
              });
              onClose();
            },
          });
        })
        .catch((err) => {
          setLoading(false);
          setInformationModal({
            rawBody: true,
            isOpen: true,
            title: "Error al actualizar el uso del potrero",
            body: (
              <>
                <p>
                  {typeof err.response.data === "string"
                    ? err.response.data
                    : ""}
                </p>
                <span>
                  Un Potrero puede tener un solo uso para una fecha dada.
                </span>
              </>
            ),
          });
        });
    } else {
      potrerosApi
        .createUsosPotrero({
          ...usoPotrero,
          potreroId: potrero.map((p) => p.value),
          establecimientoId: establecimientosContext.selected,
          fechaComienzo: utils.formatDate(
            usoPotrero.fechaComienzo,
            "YYYY-MM-DD"
          ),
          fechaFin: usoPotrero.fechaFin
            ? utils.formatDate(usoPotrero.fechaFin, "YYYY-MM-DD")
            : null,
        })
        .then(() => {
          setLoading(false);
          setUsosPotrerosContext({ refresh: !usosPotrerosContext.refresh });
          setInformationModal({
            isOpen: true,
            title: "Crear uso de Potrero",
            body: "Uso de Potrero creado correctamente.",
            onClose: () => {
              setInformationModal({
                rawBody: false,
                isOpen: false,
                title: "",
                body: "",
              });
              onClose();
            },
          });
        })
        .catch((err) => {
          setLoading(false);
          setInformationModal({
            rawBody: true,
            isOpen: true,
            title: "Error al crear el Uso del Potrero",
            body: (
              <span>
                {err.response.data.message ||
                  "Un Potrero puede tener un solo uso para una fecha dada."}
              </span>
            ),
          });
        });
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            })
      }
    />
  ) : (
    <Modal size="md" isOpen={true}>
      <ModalHeader>
        {usoPotrero.id ? "Editar" : "Nuevo "} Uso de Potrero
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup row>
                <Label sm={4} className="text-left">
                  Potrero
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={8}>
                  {usoPotrero.id ? (
                    <CustomInput
                      disabled={usoPotrero.id}
                      required={true}
                      id="potreroSelect"
                      className="text-truncate potreroSelect"
                      type="select"
                      name="potreroSelect"
                      onChange={(event) =>
                        setPotrero(parseInt(event.currentTarget.value))
                      }
                      value={potrero || ""}
                    >
                      <option value="">Selecciona un potrero</option>
                      {(potreros || []).map((potrero, index) => (
                        <option key={index} value={potrero.id}>
                          {potrero.nombre}
                        </option>
                      ))}
                    </CustomInput>
                  ) : (
                    <Select
                      placeholder={
                        <span className="text-muted">
                          Selecciona uno o mas potreros..
                        </span>
                      }
                      noOptionsMessage={() => "No work orders found"}
                      styles={style}
                      className="flex-grow-1 border rounded"
                      options={(potreros || []).map((potrero) => ({
                        value: potrero.id,
                        label: potrero.nombre,
                      }))}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      value={potrero || ""}
                      isMulti
                      isClearable
                      onChange={(selected) => setPotrero(selected)}
                    />
                  )}
                </Col>
              </FormGroup>
              <DateRangePicker
                enabledOpenEnd={true}
                fechaComienzo={moment(usoPotrero.fechaComienzo).format(
                  "DD/MM/YYYY"
                )}
                fechaFin={
                  moment(usoPotrero.fechaFin).isValid()
                    ? moment(usoPotrero.fechaFin).format("DD/MM/YYYY")
                    : null
                }
                onChangeFechaComienzo={(fechaComienzo) => {
                  setUsoPotrero({
                    ...usoPotrero,
                    fechaComienzo: utils.formatDate(
                      fechaComienzo,
                      "YYYY-MM-DD"
                    ),
                  });
                }}
                onChangeFechaFin={(fechaFin) => {
                  setUsoPotrero({
                    ...usoPotrero,
                    fechaFin: fechaFin
                      ? utils.formatDate(fechaFin, "YYYY-MM-DD")
                      : null,
                  });
                }}
              />
              <FormGroup row className="mb-0">
                <Label sm={4} className="text-left">
                  Tipo Uso de Suelo
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={8}>
                  <CustomInput
                    required={true}
                    id="tipoUsoSueloSelect"
                    className="text-truncate tipoUsoSueloSelect"
                    type="select"
                    name="tipoUsoSueloSelect"
                    onChange={(event) =>
                      setUsoPotrero({
                        ...usoPotrero,
                        tipoUsoSueloId: event.currentTarget.value,
                      })
                    }
                    value={usoPotrero.tipoUsoSueloId}
                  >
                    <option value="">Selecciona un uso de suelo</option>
                    {(tiposUsoSuelo || []).map((tipoUsoSuelo, index) => (
                      <option key={index} value={tipoUsoSuelo.id}>
                        {tipoUsoSuelo.nombre}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={() => onClose()}>
                Cancelar
              </Button>{" "}
              <Button disabled={false} color={"primary"} type="submit">
                Confirmar
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UsoPotreroModal;
