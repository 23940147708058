import axios from "axios";
import config from "../config/config";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}&reason=1`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const commonApi = {
  getSeguimientoForrajeroTcMensualTotal: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(
      `/api/common/seguimiento-forrajero-tc-mensual-total?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getIndicadoresSeguimientoForrajero: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(`/api/common/indicadores-seguimiento-forrajero?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  regroupEstablecimientos: () => {
    return axios(`/api/common/fix-groups`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getMotivosMovimiento: () => {
    return axios(`/api/common/motivos-movimiento`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getCategoriasAnimales: () => {
    return axios(`/api/common/categorias-animales`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getTiposMovimiento: () => {
    return axios(`/api/common/tipos-movimiento`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getTipoUsoSuelos: () => {
    return axios(`/api/common/tipo-uso-suelos`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getDepartamentos: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(`/api/common/departamentos?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getDepartamentosPublic: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(`/api/common/departamentos-public?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getSeccionesPoliciales: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(`/api/common/secciones-policiales?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getZonasGeomorfologicas: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(`/api/common/zonas-geomorfologicas?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  exportedShapefiles: (data) => {
    return axios(`/api/common/exported-shapefile`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  exportDatabase: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(`/api/common/export-database?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    }).then((result) => result.data);
  },
  exportEstablecimientoDatabase: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return axios(`/api/establecimientos/${data.id}/export-database?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    }).then((result) => result.data);
  },
  generateShapefile: (geojson) => {
    return fetch("https://ogre.adc4gis.com/convertJson", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: `json=${geojson}`,
    })
      .then((result) => result)
      .catch((err) => err);
  },
};
