import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroupItem,
  Col,
  Row,
  ListGroup,
} from "reactstrap";
import Loader from "../../../Loader";
import DateRangePicker from "../../../DateRangePicker";
import { potrerosApi } from "../../../../services/potrerosServices";
import moment from "moment";
import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import { utils } from "../../../../utils/utils";
import InformationModal from "../../../InformationModal";

const IndicadoresUsoPotreroModal = ({ potreroId, onClose }) => {
  const [establecimientosContext] = useEstablecimientos();

  const [loading, setLoading] = useState();
  const [fechaComienzo, setFechaComienzo] = useState();
  const [fechaFin, setFechaFin] = useState();
  const [indicadoresUsoPotrero, setIndicadoresUsoPotrero] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    if (establecimientosContext.selected) {
      setLoading(true);
      potrerosApi
        .getIndicadoresUsoPotrero(establecimientosContext.selected, {
          id: potreroId,
          fechaComienzo: fechaComienzo ? utils.formatDate(fechaComienzo) : null,
          fechaFin: fechaFin ? utils.formatDate(fechaFin) : null,
        })
        .then((result) => {
          setLoading(false);
          setIndicadoresUsoPotrero(result);
        })
        .catch((err) => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Error",
            rawBody: true,
            body: (
              <span>
                ${err?.response?.data?.message || "Ha habido un error"}
              </span>
            ),
          });
        });
    }
  }, [establecimientosContext.selected, potreroId, fechaComienzo, fechaFin]);

  return informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            })
      }
    />
  ) : (
    <Modal size="md" isOpen={true}>
      <ModalHeader>Indicadores de Uso de Potrero</ModalHeader>
      <ModalBody className="text-center">
        {loading || !indicadoresUsoPotrero ? (
          <Loader size="sm" />
        ) : (
          <>
            <DateRangePicker
              enabledOpenEnd={true}
              fechaComienzo={fechaComienzo}
              fechaFin={fechaFin}
              onChangeFechaComienzo={(fc) => {
                let fechaComienzo = fc;
                if (moment().isBefore(fechaComienzo)) {
                  fechaComienzo = moment();
                }
                const nuevaFechaFin = moment(fechaFin).isBefore(fechaComienzo)
                  ? fechaComienzo
                  : fechaFin;
                setFechaComienzo(fechaComienzo);
                setFechaFin(nuevaFechaFin);
              }}
              onChangeFechaFin={(fechaFin) => {
                if (fechaFin) {
                  const nuevaFechaComienzo = moment(fechaFin).isBefore(
                    fechaComienzo
                  )
                    ? fechaFin
                    : fechaComienzo;
                  setFechaComienzo(nuevaFechaComienzo);
                  setFechaFin(fechaFin);
                } else {
                  setFechaFin(fechaFin);
                }
              }}
            />
            <ListGroup>
              <ListGroupItem className="d-flex justify-content-between">
                <span className="text-left text-muted">En uso</span>
                <span className="text-right">{`${(
                  indicadoresUsoPotrero.enUso || 0
                ).toFixed(2)}%`}</span>
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                <span className="text-left text-muted">En descanso</span>
                <span className="text-right">{`${(
                  indicadoresUsoPotrero.enDescanso || 0
                ).toFixed(2)}%`}</span>
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                <span className="text-left text-muted">
                  Alturas de inicio del pastoreo{" "}
                </span>
                <div className="d-flex flex-column">
                  {indicadoresUsoPotrero.alturaPastoInicioPastoreo.length
                    ? indicadoresUsoPotrero.alturaPastoInicioPastoreo.map(
                        (ap, i) => (
                          <div
                            key={i}
                            className="d-flex justify-content-around"
                          >
                            <span>{ap.fecha}</span>
                            <span className="mx-2">-</span>
                            <span>{ap.alturaPasto} cm.</span>
                          </div>
                        )
                      )
                    : "No hay registros"}
                </div>
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                <span className="text-left text-muted">
                  Alturas de fin del pastoreo{" "}
                </span>
                <div className="d-flex flex-column">
                  {indicadoresUsoPotrero.alturaPastoFinPastoreo.length
                    ? indicadoresUsoPotrero.alturaPastoFinPastoreo.map(
                        (ap, i) => (
                          <div
                            key={i}
                            className="d-flex justify-content-around"
                          >
                            <span>{ap.fecha}</span>
                            <span className="mx-2">-</span>
                            <span>{ap.alturaPasto} cm.</span>
                          </div>
                        )
                      )
                    : "No hay registros"}
                </div>
              </ListGroupItem>
            </ListGroup>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color={"secondary"} onClick={() => onClose()}>
              Cerrar
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default IndicadoresUsoPotreroModal;
