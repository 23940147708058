import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  GoogleMap,
  DrawingManager,
  Polygon,
  Autocomplete,
} from "@react-google-maps/api";

import Button from "reactstrap/lib/Button";
import { useEstablecimientos } from "../../../providers/establecimientosProvider";
import { potrerosApi } from "../../../services/potrerosServices";
import { usePotreros } from "../../../providers/potrerosProvider";
import Loader from "../../Loader";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const URUGUAY_COORDINATES = {
  lat: -32.522779,
  lng: -55.765835,
};

const ARTIGAS_COORDINATES = {
  lat: -30.4076582,
  lng: -56.4715432,
};

const MONTEVIDEO_COORDINATES = {
  lat: -34.8342882,
  lng: -56.2675113,
};

const INITIAL_ZOOM = 7;

const EDIT_TEXT =
  "Haz click y arrastra los puntos para editar la zona dibujada.";
const CREATE_TEXT =
  "Haz click en el mapa para dibujar, luego doble click para terminar.";

const mapToPaths = (data) =>
  (data || []).map((c) => {
    return { lat: c[1], lng: c[0] };
  });

const PotreroMap = ({ current, onChange, mode }) => {
  const [potrerosContext] = usePotreros();
  const [establecimientosContext] = useEstablecimientos();
  const [potreros, setPotreros] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (establecimientosContext.selected) {
      setLoading(true);
      potrerosApi
        .getPotreros(establecimientosContext.selected, {
          pageSize: 999,
          includeGeoInfo: true,
        })
        .then((result) => {
          setLoading(false);
          setPotreros(result?.data);
        });
    } else {
      setPotreros([]);
    }
  }, [establecimientosContext.selected]);

  const [map, setMap] = useState(null);

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const [autocomplete, setAutocomplete] = useState();

  const onPlaceChanged = () => {
    if (!autocomplete) {
      return;
    }
    map.fitBounds(autocomplete.getPlace().geometry.viewport);
  };

  const onRemove = () => {
    if (polygonRef.current) {
      polygonRef.current.setMap(null);
      polygonRef.current = null;
      onChange(null);
    }
  };

  // Call setPath with new edited path
  const onEdit = () => {
    if (!polygonRef.current) {
      return;
    }
    let bounds = new window.google.maps.LatLngBounds();
    const nextPath = polygonRef.current
      .getPath()
      .getArray()
      .map((latLng) => {
        bounds.extend(latLng);
        return { lat: latLng.lat(), lng: latLng.lng() };
      });
    map.fitBounds(bounds);
    onChange(nextPath);
  };

  // Bind refs to current Polygon and listeners
  const onLoad = (polygon) => {
    let bounds = new window.google.maps.LatLngBounds();
    polygonRef.current = polygon;
    const path = polygon.getPath();
    path.getArray().map((latLng) => bounds.extend(latLng));
    map.fitBounds(bounds);
    listenersRef.current.push(
      path.addListener("set_at", onEdit),
      path.addListener("insert_at", onEdit),
      path.addListener("remove_at", onEdit)
    );
  };

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const onDraw = (data) => {
    let bounds = new window.google.maps.LatLngBounds();
    polygonRef.current = data;
    const nextPath = data
      .getPath()
      .getArray()
      .map((latLng) => {
        bounds.extend(latLng);
        return { lat: latLng.lat(), lng: latLng.lng() };
      });
    map.fitBounds(bounds);
    onChange(nextPath);
    data.setMap(null);
  };

  const onMapLoad = (newMap) => {
    let bounds = new window.google.maps.LatLngBounds();
    if (current) {
      potreros
        .filter((potrero) =>
          potrerosContext.selectedPotrero
            ? potrero.id === potrerosContext.selectedPotrero.id
            : true
        )
        .forEach((potrero) => {
          if (potrero.infoGeo?.coordinates?.length) {
            potrero.infoGeo.coordinates[0].forEach((coordinate) => {
              bounds.extend(
                new window.google.maps.LatLng(coordinate[1], coordinate[0])
              );
            });
          }
        });
    } else {
      bounds.extend({
        lat: MONTEVIDEO_COORDINATES.lat,
        lng: MONTEVIDEO_COORDINATES.lng,
      });
      bounds.extend({
        lat: ARTIGAS_COORDINATES.lat,
        lng: ARTIGAS_COORDINATES.lng,
      });
    }
    newMap.fitBounds(bounds);
    setMap(newMap);
  };

  return loading ? (
    <Loader size="sm" />
  ) : (
    <div className="col-12 px-0 d-flex flex-column rounded border">
      <div className="col-12 d-flex justify-content-between align-items-center">
        <small className="my-2 font-weight-bold text-warning">
          {current ? EDIT_TEXT : CREATE_TEXT}
        </small>
        <Autocomplete
          onLoad={(autocomplete) => setAutocomplete(autocomplete)}
          onPlaceChanged={onPlaceChanged}
        >
          <input type="text" placeholder="Busca un lugar.." />
        </Autocomplete>
        {polygonRef.current ? (
          <Button
            size="sm"
            className="rounded"
            color={"danger"}
            onClick={onRemove}
          >
            Borrar Selección
          </Button>
        ) : null}
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={onMapLoad}
        options={{
          mapTypeId: map?.mapTypeId || "hybrid",
          zoom: map?.zoom || INITIAL_ZOOM,
          streetViewControl: false,
          center: map?.center || URUGUAY_COORDINATES,
        }}
      >
        {potreros.map((potrero, i) => {
          if (!potrero.infoGeo) {
            return null;
          }
          const path = mapToPaths(potrero.infoGeo?.coordinates[0]);
          return <Polygon key={i} path={path} />;
        })}
        {current && current.length ? (
          <Polygon
            // Make the Polygon editable / draggable
            editable
            draggable
            path={current}
            onMouseUp={onEdit}
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
        ) : (
          <DrawingManager
            drawingMode="polygon"
            onPolygonComplete={onDraw}
            onUnmount={onUnmount}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default PotreroMap;
