import React from "react";

import {
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle,
  CardHeader,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { MoreVertical } from "react-feather";
import Loader from "../../Loader";
import { usePotreros } from "../../../providers/potrerosProvider";
import { utils } from "../../../utils/utils";
import { useAuth } from "../../../providers/authProvider";

const PotreroCard = ({ potrero, potrerosLoading, onDelete, children }) => {
  const [authContext] = useAuth();
  const [, setPotrerosContext] = usePotreros();

  const MoreOptions = () => {
    return potrerosLoading && potrerosLoading[potrero.id] ? (
      <Loader size="sm" align="end" />
    ) : authContext.currentUser.ingresoDatosHabilitado ? (
      <UncontrolledDropdown className="d-inline-block">
        <DropdownToggle tag="a">
          <MoreVertical className="text-primary" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            onClick={() => setPotrerosContext({ selectedPotrero: potrero })}
          >
            Editar
          </DropdownItem>
          <DropdownItem onClick={() => onDelete(potrero)}>Borrar</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    ) : null;
  };

  return (
    <div className="col-12 col-md-6">
      <Card className="col-12 px-0">
        <CardHeader>
          <CardTitle
            tag="h5"
            className={`mb-0 d-flex justify-content-${
              onDelete ? "between" : "center"
            }`}
          >
            <span>{potrero.nombre}</span>
            {onDelete ? <MoreOptions /> : null}
          </CardTitle>
        </CardHeader>
        <ListGroup flush className="small">
          <ListGroupItem className="d-flex justify-content-between">
            <span className="text-left text-muted">Departamento:</span>
            <span className="text-right">{potrero.departamento.nombre}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between">
            <span className="text-left text-muted">Sección Policial:</span>
            <span className="text-right">
              {potrero.seccionPolicial.default
                ? "Ninguna"
                : `${utils.ordinalSuffix(potrero.seccionPolicial.seccion)}`}
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between">
            <span className="text-left text-muted">Zona Geomorfológica:</span>
            <span className="text-right">
              {potrero.zonaGeomorfologica?.nombre}
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between">
            <span className="text-left text-muted">Area:</span>
            <span className="text-right">{`${utils.formatDecimal(
              potrero.area
            )} ha.`}</span>
          </ListGroupItem>
          {children ? (
            <ListGroupItem className="d-flex justify-content-between">
              {children}
            </ListGroupItem>
          ) : null}
        </ListGroup>
      </Card>
    </div>
  );
};

export default PotreroCard;
