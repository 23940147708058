import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sizePerPage: 10,
  page: 1,
  search: "",
  sortBy: "nombre",
  direction: "asc",
  potreros: {},
};

const PotrerosContext = createContext(initialState);

const PotrerosProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <PotrerosContext.Provider value={stateAndDispatch}>
      {children}
    </PotrerosContext.Provider>
  );
};

export const usePotreros = () => useContext(PotrerosContext);

export { PotrerosContext, PotrerosProvider };
