import React, { useEffect, useState } from "react";

import Papa from "papaparse";

import {
  Container,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  Input,
  CustomInput,
  Button,
  InputGroupText,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { RefreshCw } from "react-feather";
import Loader from "../../components/Loader";
import { commonApi } from "../../services/commonServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faInfoCircle,
  faSearch,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import InformationModal from "../../components/InformationModal";
import { utils } from "../../utils/utils";

import download from "downloadjs";
import ConfirmationModal from "../../components/ConfirmationModal";
import TooltipItem from "../../components/TooltipItem";

const ALL_MODE = 1;
const CHANGED_MODE = 2;
const PAGE_SIZE = 10;

const Shapefiles = () => {
  const [data, setData] = useState([]);

  const [sortBy, setSortBy] = useState("nombre");
  const [direction, setDirection] = useState("asc");
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(PAGE_SIZE);

  const [refresh, setRefresh] = useState();

  const [loading, setLoading] = useState(true);
  const [regroupLoading, setRegroupLoading] = useState(false);

  const [selected, setSelected] = useState({});
  const [search, setSearch] = useState("");
  const [viewMode, setViewMode] = useState(CHANGED_MODE);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
    confirmText: null,
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const getPageData = () => {
    return data.slice((page - 1) * sizePerPage, page * sizePerPage);
  };

  useEffect(() => {
    setLoading(true);
    commonApi
      .getDepartamentos({
        search,
        includeEstablecimientos: true,
        includeGeoInfo: true,
        sortBy,
        direction,
        hasChangedOnly: viewMode === CHANGED_MODE,
      })
      .then((departamentos) => {
        const rows = {};
        (departamentos || []).forEach((departamento) => {
          departamento.establecimientos.forEach((establecimiento) => {
            const key = `${departamento.id}-${establecimiento.grupo}`;
            if (rows[key]) {
              rows[key].establecimientos.push(establecimiento);
            } else {
              rows[key] = {
                id: key,
                nombre: `${departamento.nombre}_${establecimiento.grupo}`,
                establecimientos: [establecimiento],
              };
            }
          });
        });
        setData(
          Object.values(rows).sort((x, y) => (x.nombre < y.nombre ? -1 : 1))
        );
        setSelected({});
        setLoading(false);
      });
  }, [search, refresh, sortBy, direction, viewMode]);

  const onSort = (newSortBy, newDirection) => {
    if (sortBy === newSortBy && direction === newDirection) {
      return;
    }
    setDirection(newDirection);
    setSortBy(newSortBy);
    setPage(1);
  };

  const downloadPlanillaUsosByGroup = (selectedRows) => {
    selectedRows.forEach((row) => {
      let CSVdata = [];
      row.establecimientos.forEach((establecimiento) => {
        CSVdata = [
          ...CSVdata,
          ...establecimiento.potreros
            .filter((p) => p.usosPotrero.length)
            .reduce(
              (p, c) => [
                ...p,
                ...c.usosPotrero.map((usoPotrero) => ({
                  GRUPO: utils.sanitizeString(c.zonaGeomorfologica.nombre),
                  CAMPO: utils.sanitizeString(establecimiento.nombre),
                  LOTE: utils.sanitizeString(c.nombre),
                  FECHA: utils.formatDate(usoPotrero.updatedAt, "DD/MM/YYYY"),
                  USODESDE: utils.formatDate(
                    usoPotrero.fechaComienzo,
                    "DD/MM/YYYY"
                  ),
                  USOHASTA: usoPotrero.fechaFin
                    ? utils.formatDate(usoPotrero.fechaFin, "DD/MM/YYYY")
                    : "",
                  USO: utils.sanitizeString(usoPotrero.tipoUsoSuelo.nombre),
                })),
              ],
              []
            ),
        ];
      });
      const CSVDownloadData = Papa.unparse(CSVdata, { delimiter: ";" });
      const filename = `${utils.sanitizeString(row.nombre)}-${utils.formatDate(
        {},
        "DD/MM/YYYY HH.mm.ss"
      )}`;
      const hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv; charset=utf-8,\ufeff" +
        encodeURIComponent(CSVDownloadData);
      hiddenElement.target = "_self";
      hiddenElement.download = `IPA_usos-${filename}.csv`;
      hiddenElement.click();
    });
  };

  const downloadShapefilesByGroup = (selectedRows) => {
    selectedRows.forEach((row) => {
      const establecimientos = row.establecimientos;
      const filename = `IPA_shapefiles-${utils.sanitizeString(
        row.nombre
      )}-${utils.formatDate({}, "DD/MM/YYYY HH.mm.ss")}`;
      const features = establecimientos
        .flatMap((establecimiento) => establecimiento.potreros)
        .map((potrero) => {
          const establecimiento = establecimientos.find(
            (e) => e.id === potrero.establecimientoId
          );
          return {
            type: "Feature",
            geometry: potrero.infoGeo,
            properties: {
              ID: potrero.id,
              GRUPO: utils.sanitizeString(potrero.zonaGeomorfologica.nombre),
              CAMPO: utils.sanitizeString(establecimiento.nombre),
              LOTE: utils.sanitizeString(potrero.nombre),
              FECHA: utils.formatDate(null, "YYYY-MM-DD"),
              FORMADESDE: utils.formatDate("01-01-2000", "YYYY-MM-DD"),
              FORMAHASTA: null,
            },
          };
        });

      const geojson = {
        type: "FeatureCollection",
        features,
      };

      commonApi
        .generateShapefile(JSON.stringify(geojson))
        .then(async (result) => {
          const blob = await result.blob();
          const newBlob = new Blob([blob]);
          download(newBlob, `${filename}.zip`, "application/zip");
        });
    });
  };

  const onRegroup = () => {
    if (regroupLoading) {
      return;
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setConfirmationModal(initConfirmationModal);
        setRegroupLoading(true);
        commonApi
          .regroupEstablecimientos()
          .then(() => {
            setRegroupLoading(false);
            setInformationModal({
              isOpen: true,
              title: "Recalcular Grupos",
              rawBody: true,
              body: `<span>Establecimientos reagrupados exitosamente.</span>`,
            });
            setRefresh(!refresh);
          })
          .catch((err) => {
            setRegroupLoading(false);
            setConfirmationModal(initConfirmationModal);
            if (err.response?.data?.error) {
              setInformationModal({
                isOpen: true,
                title: "Error",
                rawBody: true,
                body: `<p>${err.response?.data?.error}</p><span>Intenta nuevamente.</span>`,
                onClose: () => {
                  setInformationModal({
                    isOpen: false,
                    title: "",
                    body: "",
                    onClose: null,
                  });
                },
              });
            }
            return;
          });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Recalcular Grupos`,
      confirmColor: "danger",
      body: `<p>Estás seguro que deseas recalcular los grupos para los establecimientos?</p><span>Esta acción no se puede deshacer.</span>`,
    });
  };

  const onDownload = () => {
    const selectedRows = data.filter((row) => selected[row.id]);

    if (!selectedRows.length) {
      return setInformationModal({
        isOpen: true,
        title: "Descargar Shapefiles",
        body: `
        <span>Selecciona al menos un grupo.</span>
      `,
      });
    }

    const establecimientos = selectedRows.flatMap(
      (row) => row.establecimientos
    );

    if (!establecimientos.length) {
      return setInformationModal({
        isOpen: true,
        title: "Descargar Shapefiles",
        body: `
        <span>No se encontraron establecimientos para los grupos seleccionados.</span>
      `,
      });
    }

    downloadPlanillaUsosByGroup(selectedRows);
    downloadShapefilesByGroup(selectedRows);
  };

  const headerSelector = () => (
    <CustomInput
      type="checkbox"
      id={`all-checkbox`}
      label=""
      checked={data.reduce((p, c) => p && selected[c.id], true) || false}
      onChange={(event) => {
        setSelected({
          ...data.reduce((p, c) => {
            p[c.id] = event.currentTarget.checked;
            return p;
          }, {}),
        });
      }}
      className="cursor-pointer"
    />
  );

  const selector = (row, state) => (
    <CustomInput
      type="checkbox"
      id={`${row.id}-checkbox`}
      label=""
      checked={state[row.id] || false}
      onChange={(event) =>
        setSelected({ ...state, [row.id]: event.currentTarget.checked })
      }
      className="cursor-pointer"
    />
  );

  return (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Card className="d-flex">
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Grupos</h3>
            <small className="text-muted ml-2 pt-1">({data.length})</small>
          </div>
          <div className="card-actions d-flex justify-content-between">
            <InputGroup size="m" className="mr-3">
              <Input
                className="rounded-left rounded-right-0"
                maxLength="50"
                placeholder="Buscar.."
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(1);
                }}
              />
              <InputGroupText className="rounded-right rounded-left-0 search-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <Button
              type="button"
              size="sm"
              className="rounded view-selector btn-light font-weight-bold text-primary flex-shrink-0 border mr-3"
            >
              <CustomInput
                defaultChecked={viewMode === CHANGED_MODE}
                onClick={(event) =>
                  setViewMode(event.target.checked ? CHANGED_MODE : ALL_MODE)
                }
                type="switch"
                id="viewModeShp"
                name="viewModeShp"
                label={
                  <div className="d-flex">
                    <span>Solo con cambios</span>
                    <TooltipItem
                      id="solo-cambios-tooltip"
                      title={
                        <span>
                          Un departamento se considera con cambio si se le
                          agrega/actualiza/elimina un establecimiento o potrero
                          comprendido en él.
                          <br />
                          También si se agrega/actualiza/elimina usos de
                          potreros dentro del departamento.
                        </span>
                      }
                    >
                      <FontAwesomeIcon
                        size="lg"
                        icon={faInfoCircle}
                        className="ml-2"
                        style={{ paddingBottom: "1px" }}
                      />
                    </TooltipItem>
                  </div>
                }
                className="cursor-pointer d-flex align-items-center font-weight-bold text-primary"
              />
            </Button>
            <Button
              size="sm"
              className="mr-3 rounded-circle d-flex custom-rounded-button-plus"
              color="primary"
              onClick={onDownload}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh"
              color="primary"
              onClick={() => setRefresh(!refresh)}
            >
              <RefreshCw />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-none custom-rounded-button-refresh"
              color="primary"
              onClick={onRegroup}
            >
              <FontAwesomeIcon icon={faTools} />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader size="sm" />
          ) : data?.length ? (
            <>
              <BootstrapTable
                bootstrap4
                remote
                bordered={false}
                keyField="id"
                data={getPageData(data)}
                columns={[
                  {
                    dataField: "selector",
                    text: "",
                    sort: false,
                    headerStyle: () => {
                      return { width: "50px" };
                    },
                    classes: "text-left",
                    headerFormatter: (cell) => headerSelector(),
                    formatter: (cell, row) => selector(row, selected),
                    formatExtraData: { ...selected },
                  },
                  {
                    dataField: "nombre",
                    text: "Departamento y Grupo",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    sort: true,
                    onSort,
                  },
                  {
                    dataField: "establecimientos",
                    text: "#Establecimientos",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    sort: false,
                    formatter: (cell, row) => row.establecimientos.length,
                  },
                  {
                    dataField: "potreros",
                    text: "#Potreros",
                    classes: "text-truncate",
                    headerClasses: "text-truncate text-muted font-size-75",
                    sort: false,
                    formatter: (cell, row) =>
                      row.establecimientos.flatMap(
                        (establecimiento) => establecimiento.potreros
                      ).length,
                  },
                ]}
                defaultSorted={[{ dataField: sortBy, order: direction }]}
                onTableChange={() => {}}
                pagination={paginationFactory({
                  page,
                  totalSize: data.length,
                  sizePerPage,
                  onSizePerPageChange: (sizePerPage) =>
                    setSizePerPage(sizePerPage),
                  sizePerPageList: [
                    5,
                    10,
                    25,
                    50,
                    { text: "Todos", value: data.length },
                  ],
                  onPageChange: (page) => setPage(page),
                })}
              />
            </>
          ) : (
            <div className="text-center">No hay resultados</div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default Shapefiles;
