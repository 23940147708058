import React, { useReducer, createContext, useContext } from "react";
import moment from "moment";

let reducer = (state, data) => {
  return { ...state, ...data };
};

export const initialState = {
  isOpen: false,
  potrero: null,
  mode: null,
  animalesSelector: null,
  tipoMovimiento: null,
  potreroDestino: null,
  motivoMovimiento: null,
  animales: [],
  fecha: moment(),
  calidadPasto: null,
  alturaPastoOrigen: null,
  alturaPastoDestino: null,
  efectivoPastoreoOrigen: 100,
  efectivoPastoreoDestino: 100,
  tipoUsoSueloOrigen: {},
  tipoUsoSueloDestino: {},
  observaciones: null,
  id: null,
  disabled: false,
  potreros: [],
  motivosMovimiento: [],
  tiposMovimiento: [],
  tipoUsosSuelo: [],
  categoriasAnimal: [],
};

const InformacionPotreroModalContext = createContext(initialState);

const InformacionPotreroModalProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <InformacionPotreroModalContext.Provider value={stateAndDispatch}>
      {children}
    </InformacionPotreroModalContext.Provider>
  );
};

export const useInformacionPotreroModal = () =>
  useContext(InformacionPotreroModalContext);

export { InformacionPotreroModalContext, InformacionPotreroModalProvider };
