import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import HeaderSubtitle from "../../components/HeaderSubtitle";
//import { useAuth } from "../../providers/authProvider";

const Configuracion = () => {
  //const [authContext] = useAuth();
  //const user = authContext.currentUser;
  return (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Header>
        <HeaderTitle className="text-dark">Configuración</HeaderTitle>
        <HeaderSubtitle></HeaderSubtitle>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0"></CardTitle>
            </CardHeader>
            <CardBody>
              <div className="my-5">&nbsp;</div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Configuracion;
