import React from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Row,
  CustomInput,
} from "reactstrap";

import { useInformacionPotreroModal } from "../../../../providers/informacionPotreroModalProvider";
import Loader from "../../../Loader";

const TYPE_SITUACION = "1";
const TYPE_MOVIMIENTO = "2";
const TYPE_ALIMENTOS_EXTRA = "3";

const TipoInformacionPotreroModal = ({ onClose, loading }) => {
  const [informacionPotreroModalContext, setInformacionPotreroModalContext] =
    useInformacionPotreroModal();

  return (
    <Modal size="md" isOpen={true} onClosed={onClose}>
      <ModalHeader>Ingreso de datos de monitoreo</ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <FormGroup row className="mb-0">
            <Label sm={4} className="text-left">
              Información a agregar
            </Label>
            <Col sm={8}>
              <CustomInput
                id="modeSelect"
                className="text-truncate modeSelect"
                type="select"
                name="modeSelect"
                onChange={(event) =>
                  setInformacionPotreroModalContext({
                    mode: event.currentTarget.value,
                  })
                }
                value={informacionPotreroModalContext.mode || ""}
              >
                <option value="">Selecciona una opción</option>
                <option value={TYPE_SITUACION}>
                  Situación del potrero (Pasto y Animales)
                </option>
                <option value={TYPE_MOVIMIENTO}>
                  Movimientos de animales (Entradas y Salidas)
                </option>
                <option value={TYPE_ALIMENTOS_EXTRA}>Alimentos Extra</option>
              </CustomInput>
            </Col>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color={"secondary"} onClick={onClose}>
              Cancelar
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default TipoInformacionPotreroModal;
