import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap";
import Loader from "../../components/Loader";
import { GoogleMap, Polygon, InfoBox } from "@react-google-maps/api";
import { commonApi } from "../../services/commonServices";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const URUGUAY_COORDINATES = {
  lat: -32.522779,
  lng: -55.765835,
};

const INITIAL_ZOOM = 7;

const mapToPaths = (data) =>
  (data || []).map((c) => {
    return { lat: c[1], lng: c[0] };
  });

const ZonasGeomorfologicas = () => {
  const [loading, setLoading] = useState(true);
  const [zonasGeomorfologicas, setZonasGeomorfologicas] = useState([]);
  const [tooltips, setTooltips] = useState({});

  const [map, setMap] = useState(null);

  const toolTip = (item) => (
    <div
      id={`tooltip-${item.id}`}
      className={`${
        item.nombre ? "" : "d-none"
      } font-size-1-1 px-2 py-3 border rounded bg-success text-white font-weight-bold text-center`}
    >
      {item.nombre}
    </div>
  );

  useEffect(() => {
    commonApi
      .getZonasGeomorfologicas({ includeGeoInfo: true })
      .then((result) => {
        setZonasGeomorfologicas(result);
        setLoading(false);
      });
  }, []);

  const onMapLoad = (map) => {
    map.setCenter(URUGUAY_COORDINATES);
    map.setZoom(INITIAL_ZOOM);
    setMap(map);
  };

  return (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0">
                Mapa de Zonas Geomorfologicas
              </CardTitle>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader />
              ) : (
                <div className="border rounded">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    onLoad={onMapLoad}
                    options={{
                      mapTypeId: map?.mapTypeId || "hybrid",
                      zoom: map?.zoom || INITIAL_ZOOM,
                      streetViewControl: false,
                      center: map?.center || URUGUAY_COORDINATES,
                    }}
                  >
                    {(zonasGeomorfologicas || []).map(
                      (zonasGeomorfologica, i) =>
                        zonasGeomorfologica.infoGeo?.type === "Polygon" ? (
                          zonasGeomorfologica.infoGeo?.coordinates ? (
                            <div key={i}>
                              <Polygon
                                onMouseOver={() =>
                                  setTooltips({
                                    ...tooltips,
                                    [zonasGeomorfologica.id]: true,
                                  })
                                }
                                onMouseOut={() =>
                                  setTooltips({
                                    ...tooltips,
                                    [zonasGeomorfologica.id]: false,
                                  })
                                }
                                path={mapToPaths(
                                  zonasGeomorfologica.infoGeo?.coordinates[0]
                                )}
                              />
                              <InfoBox
                                options={{
                                  closeBoxURL: "",
                                  enableEventPropagation: true,
                                  disableAutoPan: false,
                                  maxWidth: 150,
                                  alignBottom: false,
                                  zIndex: null,
                                  boxStyle: {
                                    opacity: tooltips[zonasGeomorfologica.id]
                                      ? 0.8
                                      : 0,
                                    width: "auto",
                                  },
                                }}
                                position={{
                                  lat: parseFloat(
                                    zonasGeomorfologica.centerLat
                                  ),
                                  lng: parseFloat(
                                    zonasGeomorfologica.centerLng
                                  ),
                                }}
                              >
                                {toolTip(zonasGeomorfologica)}
                              </InfoBox>
                            </div>
                          ) : null
                        ) : (
                          zonasGeomorfologica.infoGeo?.geometries.map(
                            (geometry, j) => (
                              <div key={`${i}-${j}`}>
                                <Polygon
                                  onMouseOver={() =>
                                    setTooltips({
                                      ...tooltips,
                                      [zonasGeomorfologica.id]: true,
                                    })
                                  }
                                  onMouseOut={() =>
                                    setTooltips({
                                      ...tooltips,
                                      [zonasGeomorfologica.id]: false,
                                    })
                                  }
                                  path={mapToPaths(geometry.coordinates[0])}
                                />
                                <InfoBox
                                  options={{
                                    closeBoxURL: "",
                                    enableEventPropagation: true,
                                    disableAutoPan: false,
                                    maxWidth: 150,
                                    alignBottom: false,
                                    zIndex: null,
                                    boxStyle: {
                                      opacity: tooltips[zonasGeomorfologica.id]
                                        ? 0.8
                                        : 0,
                                      width: "auto",
                                    },
                                  }}
                                  position={{
                                    lat: parseFloat(
                                      zonasGeomorfologica.centerLat
                                    ),
                                    lng: parseFloat(
                                      zonasGeomorfologica.centerLng
                                    ),
                                  }}
                                >
                                  {toolTip(zonasGeomorfologica)}
                                </InfoBox>
                              </div>
                            )
                          )
                        )
                    )}
                  </GoogleMap>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ZonasGeomorfologicas;
