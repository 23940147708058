import React, { useState } from "react";

import {
  faBell,
  faDatabase,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../providers/authProvider";
import SidebarItem from "./SidebarItem";
import { withRouter } from "react-router-dom";
import { authApi } from "../../services/authServices";
import { utils } from "../../utils/utils";
import { customerRoutes } from "../../routes";
import { useSidebar } from "../../providers/navbarProvider";
import EstablecimientoSelector from "./establecimientos/EstablecimientoSelector";
import { useEstablecimientos } from "../../providers/establecimientosProvider";
import { commonApi } from "../../services/commonServices";
import socketIOClient from "socket.io-client";
import config from "../../config/config";
import XLSX from "xlsx";
import InformationModal from "../InformationModal";
import Loader from "../Loader";
import Cookies from "js-cookie";
import { Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let socket;

const Sidebar = ({ history }) => {
  const [authContext, setAuthContext] = useAuth();
  const [sidebarContext, setSidebarContext] = useSidebar();
  const [establecimientosContext, setEstablecimientosContext] =
    useEstablecimientos();

  const [reportProgress, setReportProgress] = useState();
  const [reportLoading, setReportLoading] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const logout = () => {
    authApi.logout("customer");
    Cookies.remove("a_auth");
    setEstablecimientosContext({ allEstablecimientos: null });
    setAuthContext({ currentUser: null });
    history.push("/auth/sign-in");
  };

  const onGenerateReportSocketActions = (socketToken, callback) => {
    socket = socketIOClient(config.apiURL, { transports: ["websocket"] });

    socket.emit("signup", socketToken);

    socket.on("signupSuccess", () => {
      callback();
    });

    socket.on("newEntry", (data) => {
      setReportProgress(data);
      if (data.report) {
        setReportLoading(false);
        socket.disconnect();
        setReportProgress();
        const worksheet = XLSX.utils.aoa_to_sheet(data.report);
        const new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "Base de Datos");
        XLSX.writeFile(
          new_workbook,
          `db-iPasto-${establecimientosContext.selected}-${utils.formatDate(
            null,
            "DD-MM-YYYY"
          )}.xlsx`
        );
      }
    });

    socket.on("disconnect", (data) => {
      console.log("disconnected", data);
    });
  };

  const onGenerateReport = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (reportLoading) {
      return;
    }
    setReportLoading(true);
    setInformationModal({
      isOpen: true,
      title: "Exportar Base de Datos",
      body: "La base de datos del establecimiento seleccionado se está exportando, puedes ver el progreso en la parte inferior de la barra lateral.",
    });
    onGenerateReportSocketActions(
      authContext.currentUser.socketToken,
      async () => {
        try {
          await commonApi.exportEstablecimientoDatabase({
            id: establecimientosContext.selected,
            socketToken: authContext.currentUser.socketToken,
          });
        } catch (err) {
          if (socket) {
            socket.disconnect();
          }
          setReportLoading(false);
          if (err.response?.data?.error) {
            setInformationModal({
              isOpen: true,
              title: "Error",
              rawBody: true,
              body: `<p>${err.response?.data?.error}</p><span>Intenta nuevamente.</span>`,
              onClose: () => {
                onGenerateReport(event);
                setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                });
              },
            });
          }
          return;
        }
      }
    );
  };

  return (
    <nav className={`sidebar ml-lg-0 ${sidebarContext.open ? "toggled" : ""}`}>
      <div className="sidebar-content">
        <div className="sidebar-brand text-center bg-white text-dark border-bottom">
          iPasto
        </div>
        <div className="sidebar-user p-3 d-flex flex-column align-items-center">
          <div className="user-name rounded-circle img-thumbnail text-muted d-flex align-items-center justify-content-center">
            <span>{utils.nameInitials(authContext.currentUser.nombre)}</span>
          </div>
          <div className="font-weight-bold text-custom-dark mt-2">
            {authContext.currentUser.nombre}
          </div>
          <EstablecimientoSelector />
        </div>
        <ul className="sidebar-nav d-flex flex-column justify-content-between">
          <div>
            {customerRoutes
              .filter(
                (r) =>
                  r.sidebar &&
                  (r.condition ? authContext.currentUser[r.condition] : true)
              )
              .map((route, key) => (
                <SidebarItem
                  key={key}
                  name={route.name}
                  isActive={window.location.pathname === route.path}
                  action={() => {
                    setSidebarContext({ open: false });
                    history.push(route.path);
                  }}
                  icon={route.icon}
                />
              ))}
            {!reportLoading ? (
              <SidebarItem
                name={"Descargar Base"}
                action={onGenerateReport}
                icon={faDatabase}
              />
            ) : null}
            <SidebarItem name={"Salir"} action={logout} icon={faSignOutAlt} />
          </div>
          {reportLoading ? (
            <div className="d-flex min-width-300 justify-content-start align-items-center mt-2 mb-4">
              <div className="min-width-50">
                <Loader size="sm" align="end" />
              </div>
              <div className="flex-shrink-0 text-muted d-flex">
                <small>Descargando base...</small>
                <small className="min-width-50 text-right ml-2">
                  {reportProgress
                    ? (
                        (reportProgress.processed / reportProgress.total) *
                        100
                      ).toFixed(2)
                    : 0}
                  %
                </small>
              </div>
            </div>
          ) : null}
          <div className="flex-grow-1 d-flex justify-content-start flex-column px-3 my-3">
            <Alert
              color={
                authContext.currentUser.ingresoDatosHabilitado
                  ? "success"
                  : "danger"
              }
              className="p-2 align-items-center"
            >
              <FontAwesomeIcon icon={faBell} className="ml-1" />
              <small className="ml-2">
                {authContext.currentUser.ingresoDatosHabilitado
                  ? "Ingreso de datos habilitado"
                  : "Ingreso de datos deshabiitado"}
              </small>
            </Alert>
          </div>
        </ul>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : null}
    </nav>
  );
};

export default withRouter(Sidebar);
