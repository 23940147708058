import React, { useEffect, useState } from "react";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  Input,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MoreVertical, RefreshCw } from "react-feather";
import Loader from "../../../Loader";
import { establecimientosApi } from "../../../../services/establecimientosServices";

import InformationModal from "../../../InformationModal";
import { useMovimiento } from "../../../../providers/movimientosProvider";
import { useEstablecimientos } from "../../../../providers/establecimientosProvider";
import ConfirmationModal from "../../../ConfirmationModal";
import { potrerosApi } from "../../../../services/potrerosServices";
import { utils } from "../../../../utils/utils";
import {
  useInformacionPotreroModal,
  initialState,
} from "../../../../providers/informacionPotreroModalProvider";

const TYPE_MOVIMIENTO = "2";
const TIPO_SALIDA_ENTRADA = 1;

const Movimientos = ({ loading: defaultLoading }) => {
  const [establecimientosContext] = useEstablecimientos();
  const [movimientosContext, setMovimientosContext] = useMovimiento();
  const [, setInformacionPotreroModalContext] = useInformacionPotreroModal();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [movimientos, setMovimientos] = useState({});
  const [loading, setLoading] = useState(defaultLoading);
  const [movimientosLoading, setMovimientosLoading] = useState({});

  const onSort = (sortBy, direction) => {
    if (
      movimientosContext.sortBy === sortBy &&
      movimientosContext.direction === direction
    ) {
      return;
    }
    setMovimientosContext({ sortBy, direction, page: 1 });
  };

  useEffect(() => {
    if (establecimientosContext.selected) {
      setLoading(true);
      establecimientosApi
        .getMovimientosPotreros({
          establecimientoId: establecimientosContext.selected,
          search: movimientosContext.search,
          page: movimientosContext.page - 1,
          pageSize: movimientosContext.sizePerPage,
          sortBy: movimientosContext.sortBy,
          direction: movimientosContext.direction,
        })
        .then((result) => {
          setMovimientos(result);
          setLoading(false);
        });
    }
  }, [
    movimientosContext.refresh,
    movimientosContext.selected,
    movimientosContext.search,
    movimientosContext.page,
    movimientosContext.sizePerPage,
    movimientosContext.sortBy,
    movimientosContext.direction,
    establecimientosContext.selected,
  ]);

  const onDetalleMovimientoPotrero = (movimiento) => {
    const situacionPotreroOrigen = movimiento.situacionesPotrero.find(
      (sp) => sp.movimientoPotreroId === movimiento.id
    );
    const situacionPotreroDestino = movimiento.situacionesPotrero.find(
      (sp) => sp.movimientoPotreroId === movimiento.id
    );
    setInformacionPotreroModalContext({
      ...initialState,
      id: movimiento.id,
      isOpen: true,
      potrero: movimiento.potreroId,
      mode: TYPE_MOVIMIENTO,
      tipoMovimiento: movimiento.tipoMovId,
      potreroDestino: movimiento.potDestinoId,
      motivoMovimiento: movimiento.motivoMovimientoId,
      fecha: movimiento.fecha,
      calidadPasto: movimiento.calidadPasto,
      alturaPastoOrigen: movimiento.alturaPastoOrigen,
      alturaPastoDestino: movimiento.alturaPastoDestino,
      efectivoPastoreoOrigen: movimiento.efectivoPastoreoOrigen,
      efectivoPastoreoDestino: movimiento.efectivoPastoreoDestino,
      tipoUsoSueloOrigen: { id: situacionPotreroOrigen?.tipoUsoSueloId },
      tipoUsoSueloDestino: { id: situacionPotreroDestino?.tipoUsoSueloId },
      animales: movimiento.animales,
    });
  };

  const onDeleteMovimientoPotrero = (movimiento) => {
    if (movimiento.situacionesPotrero.length) {
      const situacionesNoUltimas = movimiento.situacionesPotrero.find(
        (sp) => !sp.isLast
      );
      if (situacionesNoUltimas?.length) {
        return setInformationModal({
          isOpen: true,
          title: "Error",
          rawBody: true,
          body: (
            <>
              <p>
                Existen estados de situación posteriores al movimiento
                seleccionado, por lo que borrarlo generaría inconsistencias.
              </p>
              <span>
                Borra todos los estados de situación posteriores para poder
                borrar el estado de movimiento seleccionado.
              </span>
            </>
          ),
        });
      }
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setMovimientosLoading({ [movimiento.id]: true });
        potrerosApi
          .deleteMovimientoPotrero({
            establecimientoId: establecimientosContext.selected,
            potreroId: movimiento.potreroId,
            id: movimiento.id,
          })
          .then(() => {
            setConfirmationModal(initConfirmationModal);
            setMovimientosContext({ refresh: !movimientosContext.refresh });
            setMovimientosLoading({ [movimiento.id]: false });
          })
          .catch((err) => {
            setConfirmationModal(initConfirmationModal);
            setMovimientosLoading({ [movimiento.id]: false });
            setLoading(false);
            setInformationModal({
              isOpen: true,
              title: "Error",
              body:
                err.response?.data?.message ||
                err.response?.data[0]?.msg ||
                "Ha habido un error con tu solicitud.",
            });
          });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: `Borrar movimiento de potrero`,
      body: `Confirmas que quieres borrar el movimiento seleccionado?`,
    });
  };

  const actions = (movimiento, movimientosLoading) => {
    return movimientosLoading[movimiento.id] ? (
      <Loader size="sm" align="end" />
    ) : (
      <UncontrolledDropdown className="d-inline-block">
        <DropdownToggle tag="a">
          <MoreVertical className="text-primary" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onDetalleMovimientoPotrero(movimiento)}>
            Ver más
          </DropdownItem>
          <DropdownItem onClick={() => onDeleteMovimientoPotrero(movimiento)}>
            Borrar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <>
      {!establecimientosContext.selected ? (
        <Card className="col-12">
          <CardHeader className="text-center">
            Ningún establecimiento seleccionado
          </CardHeader>
        </Card>
      ) : (
        <Card className="col-12">
          <CardHeader className="text-center">
            <div className="card-actions d-flex align-items-center justify-content-between">
              <InputGroup className="mr-3 col-4 px-0" size="m">
                <Input
                  maxLength="50"
                  value={movimientosContext.search}
                  onChange={(evt) =>
                    setMovimientosContext({ search: evt.target.value, page: 1 })
                  }
                  className="border-0"
                  placeholder="Buscar por potrero.."
                />
              </InputGroup>
              <span className="mr-1 d-flex">
                <span className="cursor-pointer mx-2 d-flex align-items-center">
                  <RefreshCw
                    hidden
                    onClick={() =>
                      setMovimientosContext({
                        refresh: !movimientosContext.refresh,
                      })
                    }
                  />
                </span>
                <small className="text-muted d-flex align-items-center">
                  Mostrando {movimientos.count || 0} movimientos
                </small>
              </span>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Loader size="sm" />
            ) : movimientos.count ? (
              <>
                <BootstrapTable
                  bootstrap4
                  remote
                  bordered={false}
                  keyField="id"
                  data={movimientos.data}
                  columns={[
                    {
                      dataField: "potreroOrigen.nombre",
                      text: "Potrero",
                      headerClasses: "text-muted font-size-75 align-middle",
                      onSort,
                    },
                    {
                      dataField: "potreroDestino.nombre",
                      text: "Potrero Destino",
                      headerClasses: "text-muted font-size-75 align-middle",
                      formatter: (cell, movimiento) =>
                        parseInt(movimiento.tipoMovId) === TIPO_SALIDA_ENTRADA
                          ? movimiento.potreroDestino?.nombre || "-"
                          : "No aplica",
                    },
                    {
                      dataField: "fecha",
                      text: "Fecha",
                      headerClasses: "text-muted font-size-75 align-middle",
                      formatter: (cell) => utils.formatDate(cell, "DD-MM-YYYY"),
                      onSort,
                    },
                    {
                      dataField: "tipoMovId",
                      text: "Tipo",
                      headerClasses: "text-muted font-size-75 align-middle",
                      formatter: (cell) => utils.tipoMovimientoPotrero(cell),
                      onSort,
                    },
                    {
                      dataField: "",
                      text: "",
                      classes: "text-right",
                      formatter: (cell, movimiento) =>
                        actions(movimiento, movimientosLoading),
                      formatExtraData: { ...movimientosLoading },
                    },
                  ]}
                  onTableChange={() => {}}
                  pagination={paginationFactory({
                    page: movimientosContext.page,
                    totalSize: movimientos.count,
                    sizePerPage: movimientosContext.sizePerPage,
                    sizePerPageList: [
                      5,
                      10,
                      25,
                      50,
                      { text: "Todos", value: movimientos.count },
                    ],
                    onPageChange: (page) => setMovimientosContext({ page }),
                    onSizePerPageChange: (sizePerPage) =>
                      setMovimientosContext({ page: 1, sizePerPage }),
                  })}
                />
              </>
            ) : (
              <div className="text-center font-weight-bold">
                No hay resultados
              </div>
            )}
          </CardBody>
        </Card>
      )}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={true}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

export default Movimientos;
