import React, { useCallback, useEffect, useState } from "react";
import { faSearch, faUpload } from "@fortawesome/free-solid-svg-icons";

import {
  Container,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  Input,
  Button,
  Badge,
  InputGroupText,
} from "reactstrap";

import Papa from "papaparse";

import { useImportaciones } from "../../providers/importacionesProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MoreVertical, RefreshCw } from "react-feather";
import Loader from "../../components/Loader";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import InformationModal from "../../components/InformationModal";
import { importacionesApi } from "../../services/importacionesServices";
import CreateImportacionModal from "../../components/admin/CreateImportacionModal";
import { utils } from "../../utils/utils";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import DetalleImportacionModal from "../../components/admin/DetalleImportacionModal";
import ConfirmationModal from "../../components/ConfirmationModal";

const STATUS_PENDING = 1;
const STATUS_ERROR = 2;
const STATUS_IN_PROGRESS = 4;

const getStatusData = (status) => {
  switch (parseInt(status)) {
    case STATUS_PENDING:
      return {
        color: "warning",
        text: "Pendiente",
      };
    case STATUS_ERROR:
      return {
        color: "danger",
        text: "Error",
      };
    case STATUS_IN_PROGRESS:
      return {
        color: "info",
        text: "En Ejecución",
      };
    default:
      return {
        color: "success",
        text: "Realizada",
      };
  }
};

const ImportacionesCrecimientos = () => {
  const [importacionesContext, setImportacionesContext] = useImportaciones();
  const [loading, setLoading] = useState(false);
  const [detalleModal, setDetalleModal] = useState(false);

  const [importacionModal, setImportacionModal] = useState(false);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
    confirmText: null,
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const setImportacionesContextCB = useCallback(
    (data) => {
      setImportacionesContext(data);
    },
    [setImportacionesContext]
  );

  useEffect(() => {
    setLoading(true);
    importacionesApi
      .getImportaciones({
        search: importacionesContext.search,
        page: importacionesContext.page - 1,
        pageSize: importacionesContext.sizePerPage,
        sortBy: importacionesContext.sortBy,
        direction: importacionesContext.direction,
      })
      .then((importaciones) => {
        setImportacionesContextCB({ importaciones });
        setLoading(false);
      });
  }, [
    importacionesContext.search,
    importacionesContext.page,
    importacionesContext.sizePerPage,
    importacionesContext.sortBy,
    importacionesContext.direction,
    importacionesContext.refresh,
    setImportacionesContextCB,
  ]);

  const onConfirmDelete = (id) => {
    setLoading(true);
    importacionesApi
      .deleteImportacion({ id })
      .then(() => {
        setLoading(false);
        setImportacionesContext({ refresh: !importacionesContext.refresh });
        setConfirmationModal(initConfirmationModal);
      })
      .catch((err) => {
        setLoading(false);
        setConfirmationModal(initConfirmationModal);
        if (err.response?.data?.length) {
          setInformationModal({
            isOpen: true,
            title: "Borrar importación - Error",
            rawBody: true,
            body: <span>{err.response?.data[0].msg}</span>,
          });
        }
      });
  };

  const onDelete = (importacion) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => onConfirmDelete(importacion.id),
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Borrar importación`,
      body: `Confirmas que quieres eliminar la importación seleccionada junto con toda su informacion asociada?`,
    });
  };

  const onDetail = (importacion) => {
    const data = JSON.parse(importacion.messages);
    const CSVdata = Papa.unparse({ data });
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/plan;charset=utf-8," + encodeURI(CSVdata);
    hiddenElement.target = "_self";
    hiddenElement.download = `IPA-errores-importacion.txt`;
    hiddenElement.click();
  };

  const onErrors = (importacion) => {
    const data = JSON.parse(importacion.errors);
    if (typeof data !== "object") {
      return;
    }
    const CSVdata = Papa.unparse({
      fields: ["Error"],
      data: Object.values(data).map((d) => [d]),
    });
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/plan;charset=utf-8," + encodeURI(CSVdata);
    hiddenElement.target = "_self";
    hiddenElement.download = `IPA-resultados-importacion.txt`;
    hiddenElement.click();
  };

  const moreOptions = (importacion) => (
    <UncontrolledDropdown className="d-inline-block">
      <DropdownToggle tag="a">
        <MoreVertical className="text-primary" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem hidden={true} onClick={() => onDetail(importacion)}>
          Resultado
        </DropdownItem>
        <DropdownItem hidden={true} onClick={() => onErrors(importacion)}>
          Errores
        </DropdownItem>
        <DropdownItem onClick={() => onDelete(importacion)}>
          Borrar
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  return (
    <Container fluid className="d-flex flex-column flex-grow-1 mx-0 w-100">
      <Card className="d-flex">
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Importaciones de Crecimientos</h3>
            <small className="text-muted ml-2 pt-1">
              ({importacionesContext.importaciones.count})
            </small>
          </div>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup size="m" className="mr-3">
              <Input
                className="rounded-left rounded-right-0"
                maxLength="50"
                placeholder="Buscar.."
                value={importacionesContext.search}
                onChange={(evt) =>
                  setImportacionesContext({ search: evt.target.value, page: 1 })
                }
              />
              <InputGroupText className="rounded-right rounded-left-0 search-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-plus mr-3"
              color="primary"
              onClick={() => setImportacionModal(true)}
            >
              <FontAwesomeIcon icon={faUpload} />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh"
              color="primary"
              onClick={() =>
                setImportacionesContext({
                  refresh: !importacionesContext.refresh,
                })
              }
            >
              <RefreshCw />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader size="sm" />
          ) : importacionesContext.importaciones.count ? (
            <BootstrapTable
              bootstrap4
              remote
              bordered={false}
              keyField="id"
              data={importacionesContext.importaciones.data}
              columns={[
                {
                  dataField: "filename",
                  text: "Nombre",
                  headerClasses: "text-truncate text-muted font-size-75",
                  classes: "text-truncate",
                },
                {
                  dataField: "createdAt",
                  text: "Fecha",
                  formatter: (value) =>
                    utils.formatDate(value, "DD/MM/YYYY, HH:mm:ss"),
                  headerClasses: "text-truncate text-muted font-size-75",
                  classes: "text-truncate",
                },
                {
                  dataField: "estado",
                  text: "Estado",
                  headerClasses: "text-truncate text-muted font-size-75",
                  classes: "text-truncate",
                  formatter: (cell) => (
                    <Badge color={getStatusData(cell).color}>
                      {getStatusData(cell).text}
                    </Badge>
                  ),
                },
                {
                  dataField: "id",
                  text: "",
                  classes: "text-right",
                  formatter: (cell, importacion) => moreOptions(importacion),
                },
              ]}
              defaultSorted={[
                {
                  dataField: importacionesContext.sortBy,
                  order: importacionesContext.direction,
                },
              ]}
              onTableChange={() => {}}
              pagination={paginationFactory({
                page: importacionesContext.page,
                totalSize: importacionesContext.importaciones.count,
                sizePerPage: importacionesContext.sizePerPage,
                sizePerPageList: [
                  5,
                  10,
                  25,
                  50,
                  {
                    text: "Todos",
                    value: importacionesContext.importaciones.count,
                  },
                ],
                onPageChange: (page) => setImportacionesContext({ page }),
                onSizePerPageChange: (sizePerPage) =>
                  setImportacionesContext({ page: 1, sizePerPage }),
              })}
            />
          ) : (
            <div className="text-center">No hay resultados</div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : importacionModal ? (
        <CreateImportacionModal
          onSubmit={(force, single) => {
            let body;
            if (force) {
              if (single) {
                body =
                  "Importación creada correctamente y su ejecución está en curso, una vez terminada se verá su resultado en la lista, la ejecución puede tardar hasta una hora.";
              } else {
                body =
                  "Importaciónes creadas correctamente y sus ejecuciónes están en curso, una vez terminadas se verá su resultado en la lista, las ejecuciónes pueden tardar hasta una hora.";
              }
            } else {
              if (single) {
                body =
                  "Importación creada correctamente. Se ejecutará a lo largo de la noche de hoy y se podrán ver los resultados mañana a primera hora.";
              } else {
                body =
                  "Importaciónes creadas correctamente. Se ejecutarán a lo largo de la noche de hoy y se podrán ver los resultados mañana a primera hora.";
              }
            }
            setImportacionModal(false);
            setInformationModal({
              isOpen: true,
              title: "Importación de Tasa de Crecimiento",
              body,
            });
            setImportacionesContext({
              refresh: !importacionesContext.refresh,
            });
          }}
          onClose={() => setImportacionModal(false)}
        />
      ) : detalleModal ? (
        <DetalleImportacionModal
          id={detalleModal}
          onClose={() => setDetalleModal(false)}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default ImportacionesCrecimientos;
