import React, { useEffect, useState } from "react";
import { Button, Col, CustomInput, FormGroup } from "reactstrap";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import "moment/locale/es";
import moment from "moment";

const DateRangePicker = ({
  fechaComienzo,
  fechaFin,
  onChangeFechaComienzo,
  onChangeFechaFin,
  enabledOpenEnd,
  maxRangeInMonths,
  dateFormat = "DD/MM/YYYY",
}) => {
  const [openEnd, setOpenEnd] = useState();

  useEffect(() => {
    setOpenEnd(!Boolean(fechaFin));
  }, [fechaFin]);

  return (
    <>
      <FormGroup row>
        <Col sm={4} className="text-left d-flex flex-column">
          <div>
            <span>Fecha Comienzo</span>
            <small className="ml-1 text-danger">*</small>
          </div>
          <span className="font-size-65 text-warning">
            Selecciona o escribe una fecha
          </span>
        </Col>
        <Col
          sm={8}
          className="d-flex align-items-center justify-content-between"
        >
          <Datetime
            renderInput={(props) => (
              <input
                {...props}
                required={true}
                placeholder="Fecha de comienzo"
              />
            )}
            closeOnSelect={true}
            locale="es"
            utc={true}
            dateFormat={dateFormat}
            timeFormat={false}
            className="flex-grow-1"
            value={fechaComienzo || moment().format("DD/MM/YYYY")}
            onChange={onChangeFechaComienzo}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={4} className="text-left d-flex flex-column">
          <div>
            <span>Fecha Fin</span>
            <small className="ml-1 text-danger">*</small>
          </div>
          <span className="font-size-65 text-warning">
            Selecciona o escribe una fecha
          </span>
        </Col>
        {!enabledOpenEnd || !openEnd ? (
          <Col
            sm={enabledOpenEnd ? 5 : 8}
            className="d-flex align-items-center"
          >
            <Datetime
              renderInput={(props) => (
                <input {...props} required={true} placeholder="Fecha de fin" />
              )}
              isValidDate={(currentDate) =>
                !fechaComienzo
                  ? true
                  : maxRangeInMonths
                  ? currentDate.diff(fechaComienzo, "months") <
                      maxRangeInMonths &&
                    currentDate.diff(fechaComienzo, "months") > 0
                  : true
              }
              closeOnSelect={true}
              locale="es"
              utc={true}
              dateFormat={dateFormat}
              timeFormat={false}
              className="flex-grow-1"
              value={fechaFin || moment().format("DD/MM/YYYY")}
              onChange={onChangeFechaFin}
            />
          </Col>
        ) : null}
        {enabledOpenEnd ? (
          <Col sm={openEnd ? 5 : 3} className="d-flex align-items-center">
            <Button
              type="button"
              size="sm"
              className="rounded view-selector btn-light font-weight-bold text-primary"
            >
              <CustomInput
                defaultChecked={openEnd}
                onChange={(event) => {
                  if (event.currentTarget.checked) {
                    onChangeFechaFin(null);
                  }
                  setOpenEnd(event.currentTarget.checked);
                }}
                type="switch"
                id="viewModeFechaFin"
                name="viewModeFechaFin"
                label="Actualidad"
                className="cursor-pointer d-flex align-items-center font-weight-bold text-primary"
              />
            </Button>
          </Col>
        ) : null}
      </FormGroup>
    </>
  );
};

export default DateRangePicker;
